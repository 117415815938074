import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  listApplicationDetails,
  updateApplication,
} from "../actions/applicationActions";
import { APPLICATION_UPDATE_RESET } from "../constants/applicationConstants";

function ApplicationEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: applicationId } = useParams();
  const [name, setName] = useState("");
  const [urlslug, setUrlslug] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);

  const applicationDetails = useSelector((state) => state.applicationDetails);
  const { error, loading, application } = applicationDetails;

  const applicationUpdate = useSelector((state) => state.applicationUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = applicationUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: APPLICATION_UPDATE_RESET });
      navigate("/frontend-admin/applicationlist");
    } else {
      if (!application.name || application._id !== Number(applicationId)) {
        dispatch(listApplicationDetails(applicationId));
      } else {
        setName(application.name);
        setUrlslug(application.urlslug);
        setImage(application.image);
        setCategory(application.category);
        setDescription(application.description);
      }
    }
  }, [application, applicationId, dispatch, successUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateApplication({
        _id: applicationId,
        name,
        urlslug,
        image,
        category,
        description,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("application_id", applicationId);

    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/applications/upload/",
        formData,
        config
      );
      setImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <div>
      <h5 className="my-4">
        <Link
          className="productTitle text-white"
          to="/frontend-admin/applicationlist"
        >
          Applications
        </Link>{" "}
        / Edit
      </h5>
      <Col md={6}>
        <h2>Edit Application</h2>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className="mt-1">URL Slug</Form.Label>
              <Form.Control
                type="urlslug"
                placeholder="Enter URL Slug"
                value={urlslug}
                onChange={(e) => setUrlslug(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className="mt-1">Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>

              <Form.Control
                className="my-1"
                id="image-file"
                label="Choose File"
                type="file"
                onChange={uploadFileHandler}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className="mt-1">Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-3">
              Update
            </Button>
          </Form>
        )}
      </Col>
    </div>
  );
}

export default ApplicationEditScreen;

import React, { useEffect } from 'react'
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getEtlActions, getEtlActionsSuccess } from '../actions/etlActions';
import { PieChart } from 'react-minimal-pie-chart';
import Loader from './Loader';
import Message from './Message';

function EtlOverview() {

  const dispatch = useDispatch();

  const etlActionsList = useSelector((state) => state.etlActionsList);
  const { etlActions, loading, error } = etlActionsList;

  const etlActionsSuccessList = useSelector((state) => state.etlActionsSuccessList);
  const { etlActionsSuccess } = etlActionsSuccessList;

  useEffect(() => {
    dispatch(getEtlActions())
    dispatch(getEtlActionsSuccess())
  }, [dispatch])

  const convertTime = (e) => {
    if (e === "" || e === null) {
      return "";
    }
    e = new Date(e);
    return e.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  };

  const getPieData = () => {
    var pieData = []
    if (successCalc(etlActionsSuccess).success > 0) {
      pieData.push({ title: "Successful runs: " + String(successCalc(etlActionsSuccess).success), value: successCalc(etlActionsSuccess).success, color: '#008000' })
    }
    if (successCalc(etlActionsSuccess).fail > 0) {
      pieData.push({ title: "Failed runs: " + String(successCalc(etlActionsSuccess).fail), value: successCalc(etlActionsSuccess).fail, color: '#FF0000' })
    }
    if (successCalc(etlActionsSuccess).amended > 0) {
      pieData.push({ title: "Amended runs: " + String(successCalc(etlActionsSuccess).amended), value: successCalc(etlActionsSuccess).amended, color: '#F09000' })
    }
    return pieData
  }

  const successCalc = (etlActionsSuccess) => {
    var success = 0
    var total = 0
    var fail = 0
    var amended = 0
    for (let i = 0; i < etlActionsSuccess.length; i++) {
      if (etlActionsSuccess[i].isSuccessful === true) {
        success += 1
      } else if (etlActionsSuccess[i].isFixed === true) {
        amended += 1
      } else {
        fail += 1
      }
      total += 1
    }

    var successRate = (100 * success) / total;
    successRate = successRate.toPrecision(3);

    return { "success": success, "total": total, "fail": fail, "amended": amended, "successRate": successRate }
  };

  return (
    <div>
      {
        loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div>
            <h2>ETL Overview</h2>
            <h3>Past Week's Success Rate:</h3>
            {
              etlActionsSuccess &&
              <div className="my-4 d-flex">
                <div style={{ width: "200px", position: "relative" }} >
                  <h3 className="position-absolute top-50 start-50 translate-middle pb-3" style={{ fontWeight: "bold" }}>{successCalc(etlActionsSuccess).successRate}%</h3>
                  <PieChart
                    data={getPieData()}
                    animate={true}
                    paddingAngle={5}
                    lineWidth={30}
                  />
                </div>
                <div className='d-flex align-self-center flex-column px-4'>
                  <p>Total runs: {String(successCalc(etlActionsSuccess).total)}</p>
                  <p><i style={{ color: "#008000" }} className="fas fa-square me-2"></i> Successful runs: {String(successCalc(etlActionsSuccess).success)}</p>
                  <p><i style={{ color: "#FF0000" }} className="fas fa-square me-2"></i> Failed runs: {String(successCalc(etlActionsSuccess).fail)}</p>
                  <p><i style={{ color: "#F09000" }} className="fas fa-square me-2"></i> Amended failures: {String(successCalc(etlActionsSuccess).amended)}</p>
                </div>
              </div>
            }
            <h3>Recently ran ETL scripts:</h3>
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th
                    style={{ textAlign: "center" }}
                  >
                    Status
                  </th>
                  <th>
                    Data Source
                  </th>
                  <th>
                    Table Name
                  </th>
                  <th>
                    Task Name
                  </th>
                  <th>
                    Run Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {etlActions &&
                  etlActions
                    .map((etlAction) => (
                      <tr key={etlAction._id}>
                        {etlAction.isSuccessful ? (
                          <td style={{ color: "green", textAlign: "center" }}>
                            <i className="fas fa-circle"></i>
                          </td>
                        ) : etlAction.isFixed ? (
                          <td style={{ color: "#F09000", textAlign: "center" }}>
                            <i className="fas fa-circle"></i>
                          </td>
                        )
                          : (
                            <td style={{ color: "red", textAlign: "center" }}>
                              <i className="fas fa-circle"></i>
                            </td>
                          )}

                        <td>{etlAction.dataSource}</td>
                        <td>{etlAction.tableName}</td>
                        <td>{etlAction.taskName}</td>
                        {
                          etlAction.createdAt === "" || etlAction.createdAt === null ?
                            <td></td> :
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 650, hide: 200 }}
                              style={{ display: "none" }}
                              overlay={<Tooltip id="button-tooltip">
                                {etlAction.createdAt.split(" ")[0]}
                              </Tooltip>}
                            >
                              <td>{convertTime(etlAction.createdAt)}</td>
                            </OverlayTrigger>
                        }
                      </tr>
                    ))}
              </tbody>
            </Table>
            {/* 
            <h3>Overview of ETL Schedules:</h3>
            <p>
              screenshot of however were going to do this will be placed here or I can just statically type it out.
            </p>
            <p>Possibly add bars for each data source next to pie chart on home page</p>
            <p>On overview add a progress bar to show how many of the fails that have been fixed</p> */}
          </div>
        )
      }
    </div>
  )
}

export default EtlOverview
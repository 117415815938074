import React from "react";
import Message from "../components/Message";

function DefaultAppScreen() {
  return (
    <div className="my-3">
      <Message variant="danger">
        An application wasn't found at the current url, it is either currently
        getting developed or does not exist.
      </Message>
    </div>
  );
}

export default DefaultAppScreen;

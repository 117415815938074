import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getOneChecklistTodo,
  putChecklistTodo,
  listEmployeeTypes,
} from "../actions/checklistActions";
import { CHECKLIST_TODO_PUT_RESET } from "../constants/checklistConstants";

function ChecklistTodoEditScreen() {
  const [dept, setDept] = useState("");
  const [newtodo, setNewTodo] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const { id: checklistTodoID } = useParams();

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes, loading: empLoading } = empTypeGetAll;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const todoGet = useSelector((state) => state.todoGet);
  const { todo, error, loading } = todoGet;

  const todoPut = useSelector((state) => state.todoPut);
  const { success: successPut } = todoPut;

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.isAdmin !== true) {
      navigate("/");
    } else {
      dispatch(listEmployeeTypes());
      dispatch(getOneChecklistTodo(checklistTodoID));
      if (successPut) {
        navigate("/app/onboarding/todos");
        dispatch({ type: CHECKLIST_TODO_PUT_RESET });
      }
    }
  }, [dispatch, navigate, userInfo.isAdmin, successPut, checklistTodoID]);

  const putTodoHandler = (e) => {
    e.preventDefault();
    dispatch(
      putChecklistTodo(checklistTodoID, {
        empType: dept,
        customMessage: newtodo,
        moreInfo: moreInfo,
      })
    );
  };

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Onboarding
        </Link>{" "}
        /{" "}
        <Link className="productTitle text-white" to="/app/onboarding/todos">
          Todos
        </Link>{" "}
        / Edit
      </h5>
      <Row>
        <Col md={5}>
          {loading ? (
            <Loader />
          ) : empLoading ? (
            <Loader />
          ): error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={putTodoHandler}>
              <Form.Group controlId="rating">
                <Form.Label>Employee Type</Form.Label>
                <Form.Control
                  as="select"
                  placeholder={dept}
                  onChange={(e) => setDept(e.target.value)}
                >
                  <option key={todo._id} value={todo._id}>
                    {todo.empType}
                  </option>
                  {empTypes && empTypes
                    .sort((a, b) => a.empType.localeCompare(b.empType))
                    .filter((a) => a.empType !== todo.empType)
                    .map((empType) => (
                      <option key={empType._id} value={empType._id}>
                        {empType.empType}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="todo">
                <Form.Label>Todo: </Form.Label>
                <Form.Control
                  row="5"
                  defaultValue={todo && todo.customMessage}
                  onChange={(e) => setNewTodo(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="moreInfo">
                <Form.Label>Todo: </Form.Label>
                <Form.Control
                  as="textarea"
                  row="5"
                  defaultValue={todo && todo.moreInfo}
                  onChange={(e) => setMoreInfo(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button type="submit" variant="primary" className="mt-3">
                Submit
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ChecklistTodoEditScreen;

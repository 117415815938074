import axios from "axios";
import { MOOD_DATES_FAIL, MOOD_DATES_REQUEST, MOOD_DATES_SUCCESS, MOOD_GET_REPORTS_FAIL, MOOD_GET_REPORTS_REQUEST, MOOD_GET_REPORTS_SUCCESS, MOOD_POST_REPORT_FAIL, MOOD_POST_REPORT_REQUEST, MOOD_POST_REPORT_SUCCESS, MOOD_SELECT_DATES_FAIL, MOOD_SELECT_DATES_REQUEST, MOOD_SELECT_DATES_SUCCESS } from "../constants/moodConstants";

export const getMoodDates = (incompletedates = true) => async (dispatch, getState) => {
    try {
      if (incompletedates) {
        
      dispatch({ type: MOOD_DATES_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/mood/incompletedates/`, config);
  
      dispatch({
        type: MOOD_DATES_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: MOOD_SELECT_DATES_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/mood/alldates/`, config);
  
      dispatch({
        type: MOOD_SELECT_DATES_SUCCESS,
        payload: data,
      });
    }
    } catch (error) {
      if (incompletedates) {
      dispatch({
        type: MOOD_SELECT_DATES_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    } else {
      dispatch({
        type: MOOD_DATES_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }}
  };
  

  export const createMoodPost = (moostPostData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MOOD_POST_REPORT_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.post(
        `/api/mood/moodreport/`,
        moostPostData,
        config
      );
  
      dispatch({
        type: MOOD_POST_REPORT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MOOD_POST_REPORT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  
export const getMoodReports = (id, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: MOOD_GET_REPORTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    if (type === "byUser") {
      const { data } = await axios.get(`/api/mood/moodreport/user/${id}/`, config);
      dispatch({
        type: MOOD_GET_REPORTS_SUCCESS,
        payload: data,
      });
    } else if(type === "byDate") {
      const { data } = await axios.get(`/api/mood/moodreport/date/${id}/`, config);
      dispatch({
        type: MOOD_GET_REPORTS_SUCCESS,
        payload: data,
      });
    }

  } catch (error) {
    dispatch({
      type: MOOD_GET_REPORTS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout, setMySettings, updateUserProfile } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";

function ChangePasswordScreen() {
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateMyPermissions = useSelector((state) => state.userUpdateMyPermissions);
  const { loading } = userUpdateMyPermissions;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
    } else {
      dispatch(
        updateUserProfile({
          name: userInfo.name,
          email: userInfo.email,
          id: userInfo.id,
          password: password,
        })
      );
      dispatch(setMySettings({changed_password: true}))
    }
  };

  const signOutHandler = (e) => {
    dispatch(logout())
  };

  return (
    <FormContainer>
      <h1>Set Password</h1>
      {loading && <Loader />}
      {message && <Message variant="danger">{message}</Message>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="passwordConfirm">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-3">
          Register
        </Button>
      </Form>
      <Row className="py-3">
        <span>
          Wrong Account?{" "}
          <Link
            to="/"
            onClick={(e) => signOutHandler()}
            className="productTitle"
          >
            Sign Out
          </Link>
        </span>
      </Row>
    </FormContainer>
  );
}

export default ChangePasswordScreen;

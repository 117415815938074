import {
  VERSION_LIST_REQUEST,
  VERSION_LIST_SUCCESS,
  VERSION_LIST_FAIL,
  VERSION_LIST_RESET,
  VERSION_GET_REQUEST,
  VERSION_GET_SUCCESS,
  VERSION_GET_FAIL,
  VERSION_GET_RESET,
  VERSION_PUT_REQUEST,
  VERSION_PUT_SUCCESS,
  VERSION_PUT_FAIL,
  VERSION_PUT_RESET,
} from "../constants/versionConstants";

export const versionListReducer = (state = { versions: [] }, action) => {
  switch (action.type) {
    case VERSION_LIST_REQUEST:
      return { loading: true };
    case VERSION_LIST_SUCCESS:
      return { loading: false, versions: action.payload };
    case VERSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case VERSION_LIST_RESET:
      return { versions: [] };

    default:
      return state;
  }
};

export const getVersionReducer = (state = { version: {} }, action) => {
  switch (action.type) {
    case VERSION_GET_REQUEST:
      return { ...state, loading: true };
    case VERSION_GET_SUCCESS:
      return { loading: false, version: action.payload };
    case VERSION_GET_FAIL:
      return { loading: false, error: action.payload };
    case VERSION_GET_RESET:
      return { version: {} };

    default:
      return state;
  }
};

export const versionPutReducer = (state = { version: {} }, action) => {
  switch (action.type) {
    case VERSION_PUT_REQUEST:
      return { loading: true };
    case VERSION_PUT_SUCCESS:
      return { loading: false, success: true };
    case VERSION_PUT_FAIL:
      return { loading: false, error: action.payload };
    case VERSION_PUT_RESET:
      return { version: {} };

    default:
      return state;
  }
};
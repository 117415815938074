export const ETL_DATA_SOURCES_REQUEST = 'ETL_DATA_SOURCES_REQUEST'
export const ETL_DATA_SOURCES_SUCCESS = 'ETL_DATA_SOURCES_SUCCESS'
export const ETL_DATA_SOURCES_FAIL = 'ETL_DATA_SOURCES_FAIL'
export const ETL_DATA_SOURCES_RESET = 'ETL_DATA_SOURCES_RESET'

export const ETL_TABLES_REQUEST = 'ETL_TABLES_REQUEST'
export const ETL_TABLES_SUCCESS = 'ETL_TABLES_SUCCESS'
export const ETL_TABLES_FAIL = 'ETL_TABLES_FAIL'
export const ETL_TABLES_RESET = 'ETL_TABLES_RESET'

export const ETL_STEPS_REQUEST = 'ETL_STEPS_REQUEST'
export const ETL_STEPS_SUCCESS = 'ETL_STEPS_SUCCESS'
export const ETL_STEPS_FAIL = 'ETL_STEPS_FAIL'
export const ETL_STEPS_RESET = 'ETL_STEPS_RESET'

export const ETL_ACTIONS_REQUEST = 'ETL_ACTIONS_REQUEST'
export const ETL_ACTIONS_SUCCESS = 'ETL_ACTIONS_SUCCESS'
export const ETL_ACTIONS_FAIL = 'ETL_ACTIONS_FAIL'
export const ETL_ACTIONS_RESET = 'ETL_ACTIONS_RESET'

export const ETL_ACTIONS_SUCCESS_REQUEST = 'ETL_ACTIONS_SUCCESS_REQUEST'
export const ETL_ACTIONS_SUCCESS_SUCCESS = 'ETL_ACTIONS_SUCCESS_SUCCESS'
export const ETL_ACTIONS_SUCCESS_FAIL = 'ETL_ACTIONS_SUCCESS_FAIL'
export const ETL_ACTIONS_SUCCESS_RESET = 'ETL_ACTIONS_SUCCESS_RESET'

export const ETL_ACTIONS_FAIL_REQUEST = 'ETL_ACTIONS_FAIL_REQUEST'
export const ETL_ACTIONS_FAIL_SUCCESS = 'ETL_ACTIONS_FAIL_SUCCESS'
export const ETL_ACTIONS_FAIL_FAIL = 'ETL_ACTIONS_FAIL_FAIL'
export const ETL_ACTIONS_FAIL_RESET = 'ETL_ACTIONS_FAIL_RESET'

export const ETL_ACTIONS_UPDATE_REQUEST = 'ETL_ACTIONS_UPDATE_REQUEST'
export const ETL_ACTIONS_UPDATE_SUCCESS = 'ETL_ACTIONS_UPDATE_SUCCESS'
export const ETL_ACTIONS_UPDATE_FAIL = 'ETL_ACTIONS_UPDATE_FAIL'
export const ETL_ACTIONS_UPDATE_RESET = 'ETL_ACTIONS_UPDATE_RESET'

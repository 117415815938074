import {
  CHECKLIST_DEPT_GET_ALL_REQUEST,
  CHECKLIST_DEPT_GET_ALL_SUCCESS,
  CHECKLIST_DEPT_GET_ALL_FAIL,
  CHECKLIST_DEPT_GET_ALL_RESET,
  CHECKLIST_DEPT_POST_REQUEST,
  CHECKLIST_DEPT_POST_SUCCESS,
  CHECKLIST_DEPT_POST_FAIL,
  CHECKLIST_DEPT_POST_RESET,
  CHECKLIST_DEPT_DELETE_REQUEST,
  CHECKLIST_DEPT_DELETE_SUCCESS,
  CHECKLIST_DEPT_DELETE_FAIL,
  CHECKLIST_DEPT_PUT_REQUEST,
  CHECKLIST_DEPT_PUT_SUCCESS,
  CHECKLIST_DEPT_PUT_FAIL,
  CHECKLIST_DEPT_PUT_RESET,
  CHECKLIST_DEPT_GET_REQUEST,
  CHECKLIST_DEPT_GET_SUCCESS,
  CHECKLIST_DEPT_GET_FAIL,
  CHECKLIST_DEPT_GET_RESET,
  CHECKLIST_TODO_GET_ALL_REQUEST,
  CHECKLIST_TODO_GET_ALL_SUCCESS,
  CHECKLIST_TODO_GET_ALL_FAIL,
  CHECKLIST_TODO_GET_ALL_RESET,
  CHECKLIST_TODO_POST_REQUEST,
  CHECKLIST_TODO_POST_SUCCESS,
  CHECKLIST_TODO_POST_FAIL,
  CHECKLIST_TODO_POST_RESET,
  CHECKLIST_TODO_DELETE_REQUEST,
  CHECKLIST_TODO_DELETE_SUCCESS,
  CHECKLIST_TODO_DELETE_FAIL,
  CHECKLIST_TODO_PUT_REQUEST,
  CHECKLIST_TODO_PUT_SUCCESS,
  CHECKLIST_TODO_PUT_FAIL,
  CHECKLIST_TODO_PUT_RESET,
  CHECKLIST_TODO_GET_REQUEST,
  CHECKLIST_TODO_GET_SUCCESS,
  CHECKLIST_TODO_GET_FAIL,
  CHECKLIST_TODO_GET_RESET,
  CHECKLIST_NEW_USER_REQUEST,
  CHECKLIST_NEW_USER_SUCCESS,
  CHECKLIST_NEW_USER_FAIL,
  CHECKLIST_NEW_USER_RESET,
  CHECKLIST_GET_USERS_REQUEST,
  CHECKLIST_GET_USERS_SUCCESS,
  CHECKLIST_GET_USERS_FAIL,
  CHECKLIST_GET_USERS_RESET,
  CHECKLIST_GET_USER_TASKS_REQUEST,
  CHECKLIST_GET_USER_TASKS_SUCCESS,
  CHECKLIST_GET_USER_TASKS_FAIL,
  CHECKLIST_GET_USER_TASKS_RESET,
  CHECKLIST_COMPLETE_TASK_REQUEST,
  CHECKLIST_COMPLETE_TASK_SUCCESS,
  CHECKLIST_COMPLETE_TASK_FAIL,
  CHECKLIST_COMPLETE_TASK_RESET,
  CHECKLIST_COMPLETE_ALL_TASKS_REQUEST,
  CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS,
  CHECKLIST_COMPLETE_ALL_TASKS_FAIL,
  CHECKLIST_COMPLETE_ALL_TASKS_RESET,
  CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST,
  CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS,
  CHECKLIST_TASK_WEIGHT_UPDATE_FAIL,
  CHECKLIST_TASK_WEIGHT_UPDATE_RESET,
  CHECKLIST_OFFBOARD_REQUEST,
  CHECKLIST_OFFBOARD_SUCCESS,
  CHECKLIST_OFFBOARD_FAIL,
  CHECKLIST_OFFBOARD_RESET,
} from "../constants/checklistConstants";

export const empTypeGetAllReducer = (state = { empTypes: [] }, action) => {
  switch (action.type) {
    case CHECKLIST_DEPT_GET_ALL_REQUEST:
      return { loading: true };
    case CHECKLIST_DEPT_GET_ALL_SUCCESS:
      return { loading: false, empTypes: action.payload };
    case CHECKLIST_DEPT_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DEPT_GET_ALL_RESET:
      return { empTypes: [] };

    default:
      return state;
  }
};

export const empTypeGetReducer = (state = { empType: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_DEPT_GET_REQUEST:
      return { loading: true };
    case CHECKLIST_DEPT_GET_SUCCESS:
      return { loading: false, empType: action.payload };
    case CHECKLIST_DEPT_GET_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DEPT_GET_RESET:
      return { empType: {} };

    default:
      return state;
  }
};

export const empTypePostReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_DEPT_POST_REQUEST:
      return { loading: true };
    case CHECKLIST_DEPT_POST_SUCCESS:
      return { loading: false, success: true, empType: action.payload };
    case CHECKLIST_DEPT_POST_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DEPT_POST_RESET:
      return {};

    default:
      return state;
  }
};

export const empTypePutReducer = (state = { empType: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_DEPT_PUT_REQUEST:
      return { loading: true };
    case CHECKLIST_DEPT_PUT_SUCCESS:
      return { loading: false, success: true, empType: action.payload };
    case CHECKLIST_DEPT_PUT_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_DEPT_PUT_RESET:
      return { empType: {} };

    default:
      return state;
  }
};

export const empTypeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_DEPT_DELETE_REQUEST:
      return { loading: true };
    case CHECKLIST_DEPT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CHECKLIST_DEPT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const todoGetAllReducer = (state = { todos: [] }, action) => {
  switch (action.type) {
    case CHECKLIST_TODO_GET_ALL_REQUEST:
      return { loading: true };
    case CHECKLIST_TODO_GET_ALL_SUCCESS:
      return { loading: false, todos: action.payload };
    case CHECKLIST_TODO_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_TODO_GET_ALL_RESET:
      return { todos: [] };

    default:
      return state;
  }
};

export const todoGetReducer = (state = { todo: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_TODO_GET_REQUEST:
      return { loading: true };
    case CHECKLIST_TODO_GET_SUCCESS:
      return { loading: false, todo: action.payload };
    case CHECKLIST_TODO_GET_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_TODO_GET_RESET:
      return { todo: {} };

    default:
      return state;
  }
};

export const todoPostReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_TODO_POST_REQUEST:
      return { loading: true };
    case CHECKLIST_TODO_POST_SUCCESS:
      return { loading: false, success: true, todo: action.payload };
    case CHECKLIST_TODO_POST_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_TODO_POST_RESET:
      return {};

    default:
      return state;
  }
};

export const offboardReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_OFFBOARD_REQUEST:
      return { loading: true };
    case CHECKLIST_OFFBOARD_SUCCESS:
      return { loading: false, success: true, todo: action.payload };
    case CHECKLIST_OFFBOARD_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_OFFBOARD_RESET:
      return {};

    default:
      return state;
  }
};

export const todoPutReducer = (state = { todo: {} }, action) => {
  switch (action.type) {
    case CHECKLIST_TODO_PUT_REQUEST:
      return { loading: true };
    case CHECKLIST_TODO_PUT_SUCCESS:
      return { loading: false, success: true, todo: action.payload };
    case CHECKLIST_TODO_PUT_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_TODO_PUT_RESET:
      return { todo: {} };

    default:
      return state;
  }
};

export const todoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_TODO_DELETE_REQUEST:
      return { loading: true };
    case CHECKLIST_TODO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CHECKLIST_TODO_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const newUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_NEW_USER_REQUEST:
      return { loading: true };
    case CHECKLIST_NEW_USER_SUCCESS:
      return { loading: false, success: true, newUser: action.payload };
    case CHECKLIST_NEW_USER_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_NEW_USER_RESET:
      return {};

    default:
      return state;
  }
};

export const getIncompleteUsersReducer = (
  state = { incompleteUsers: [] },
  action
) => {
  switch (action.type) {
    case CHECKLIST_GET_USERS_REQUEST:
      return { loading: true };
    case CHECKLIST_GET_USERS_SUCCESS:
      return { loading: false, incompleteUsers: action.payload };
    case CHECKLIST_GET_USERS_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_GET_USERS_RESET:
      return { incompleteUsers: [] };

    default:
      return state;
  }
};

export const getUserTasksReducer = (state = { tasks: [] }, action) => {
  switch (action.type) {
    case CHECKLIST_GET_USER_TASKS_REQUEST:
      return { loading: true };
    case CHECKLIST_GET_USER_TASKS_SUCCESS:
      return { loading: false, tasks: action.payload };
    case CHECKLIST_GET_USER_TASKS_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_GET_USER_TASKS_RESET:
      return { tasks: [] };

    default:
      return state;
  }
};

export const checkTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_COMPLETE_TASK_REQUEST:
      return { loading: true };
    case CHECKLIST_COMPLETE_TASK_SUCCESS:
      return { loading: false, success: true };
    case CHECKLIST_COMPLETE_TASK_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_COMPLETE_TASK_RESET:
      return {};

    default:
      return state;
  }
};

export const checkAllTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_COMPLETE_ALL_TASKS_REQUEST:
      return { loading: true };
    case CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS:
      return { loading: false, success: true };
    case CHECKLIST_COMPLETE_ALL_TASKS_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_COMPLETE_ALL_TASKS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateTaskWeightReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST:
      return { loading: true };
    case CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS:
      return { loading: false, tasks: action.payload };
    case CHECKLIST_TASK_WEIGHT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CHECKLIST_TASK_WEIGHT_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

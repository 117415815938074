import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import {
  createEmployeeType,
  deleteEmpType,
  listEmployeeTypes,
} from "../actions/checklistActions";

function DeptListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dept, setDept] = useState("");

  const applicationList = useSelector((state) => state.applicationList);
  const { loading, error, pages, page } = applicationList;

  const empTypeDelete = useSelector((state) => state.empTypeDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = empTypeDelete;

  const applicationCreate = useSelector((state) => state.applicationCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = applicationCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    }
    dispatch(listEmployeeTypes());
  }, [dispatch, navigate, userInfo, successDelete, successCreate]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this application?")) {
      dispatch(deleteEmpType(id));
    }
  };

  const newDeptHandler = () => {
    dispatch(createEmployeeType({ empType: dept }));
  };

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Onboarding
        </Link>{" "}
        / Departments
      </h5>

      <Row>
        <Col md={4} className="pb-4">
          <h2>Advanced Options</h2>
          <h6 className="my-2">Want to add a new Dept?</h6>
          <Form onSubmit={newDeptHandler}>
            <div className="d-flex justify-content-start">
              <div style={{ width: "100%" }}>
                <Form.Label htmlFor="inlineFormInput" visuallyHidden>
                  Name
                </Form.Label>
                <Form.Control
                  id="inlineFormInput"
                  placeholder="Department Type"
                  style={{ width: "100%", height: "38px" }}
                  onChange={(e) => setDept(e.target.value)}
                  required
                />
              </div>
              <div>
                <Button type="submit">Submit</Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col md={7}>
          <h2>Departments</h2>
          {loadingDelete && <Loader />}
          {errorDelete && <Message variant="danger">{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant="danger">{errorCreate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <div>
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Employee Type</th>
                    <th width="10%"></th>
                  </tr>
                </thead>

                <tbody>
                  {empTypes &&
                    empTypes
                      .sort((a, b) => a.empType.localeCompare(b.empType))
                      .map((empType) => (
                        <tr key={empType._id}>
                          <td>{empType._id}</td>
                          <td>{empType.empType}</td>
                          <td className="d-flex justify-content-end">
                            <LinkContainer
                              to={`/app/onboarding/departments/${empType._id}/edit`}
                            >
                              <Button variant="light" className="btn-sm">
                                <i className="fas fa-edit"></i>
                              </Button>
                            </LinkContainer>

                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => deleteHandler(empType._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Paginate page={page} pages={pages} isAdmin={true} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default DeptListScreen;

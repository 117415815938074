import React from "react";

function ReportScreen() {

  return <div>
    <div className='tableauPlaceholder'>
      <object className='tableauViz' width='1200' height='900' style={{display: "none"}}>
        <param name='host_url' value='https%3A%2F%2Fprod-useast-a.online.tableau.com%2F' />
        <param name='embed_code_version' value='3' />
        <param name='site_root' value='&#47;t&#47;conequipparts' />
        <param name='name' value='cjmDashboredTestPublish&#47;Dashboard1' />
        <param name='tabs' value='yes' />
        <param name='toolbar' value='yes' />
        <param name='showAppBanner' value='false' />
      </object>
    </div>
  </div>;
}

export default ReportScreen;

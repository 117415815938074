import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col, Form, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  createChecklistTodo,
  deleteChecklistTodo,
  listChecklistTodos,
  listEmployeeTypes,
  updateWeights,
} from "../actions/checklistActions";

function DeptListScreen() {
  const dispatch = useDispatch();
  const [dept, setDept] = useState("");
  const [todo, setTodo] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [dropDownTitle, setDropDownTitle] = useState("Everybody");
  const [onboarding, setOnboarding] = useState(true);

  const navigate = useNavigate();

  const todoDelete = useSelector((state) => state.todoDelete);
  const {
    error: errorDelete,
    success: successDelete,
  } = todoDelete;

  const todoPost = useSelector((state) => state.todoPost);
  const {
    error: errorPost,
    success: successPost,
  } = todoPost;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  const todoGetAll = useSelector((state) => state.todoGetAll);
  const { todos, loading, error } = todoGetAll;

  const updateTaskWeight = useSelector((state) => state.updateTaskWeight);
  const { error: errorWeight } = updateTaskWeight;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    } else {
      dispatch(listEmployeeTypes());
      dispatch(listChecklistTodos());
    }
  }, [dispatch, successDelete, navigate, userInfo, successPost]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this application?")) {
      dispatch(deleteChecklistTodo(id));
    }
  };

  const orderHandler = (direction, index, list) => {
    var temp = list[index];
    if (direction === "up") {
      list[index] = list[index - 1];
      list[index - 1] = temp;
    } else if (direction === "down") {
      list[index] = list[index + 1];
      list[index + 1] = temp;
    } else {
      return;
    }
    list.forEach(function (element, index) {
      element.weight = index;
    });
    dispatch(updateWeights(list));
  };

  const newTodoHandler = (e) => {
    e.preventDefault();
    dispatch(
      createChecklistTodo({
        empType: dept,
        customMessage: todo,
        moreInfo: moreInfo,
        isOffboarding: !onboarding
      })
    );
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <h5 className="my-4">
            <Link className="productTitle text-white" to="/app/onboarding">
              Onboarding
            </Link>{" "}
            / Todos
          </h5>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <Form.Check
            className="mt-2"
            type="switch"
            id="custom-switch1"
            label={onboarding ? "Onboarding" : "Offboarding"}
            defaultChecked={onboarding}
            onChange={(e) => setOnboarding(e.target.checked)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="pb-4" md={4}>
          <h2>Add Todo</h2>
          {errorPost && <Message variant="danger">{errorPost}</Message>}
          <Form onSubmit={(e) => newTodoHandler(e)}>
            <Form.Group controlId="Employee Type">
              <Form.Label>Employee Type</Form.Label>
              <Form.Control
                as="select"
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                required
              >
                <option>Select... </option>
                {empTypes &&
                  empTypes
                    .sort((a, b) => a.empType.localeCompare(b.empType))
                    .map((empType) => (
                      <option key={empType._id} value={empType._id}>
                        {empType.empType}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="Todo">
              <Form.Label>Todo: </Form.Label>
              <Form.Control
                row="5"
                value={todo}
                onChange={(e) => setTodo(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="More Info">
              <Form.Label>Details: </Form.Label>
              <Form.Control
                as="textarea"
                row="5"
                value={moreInfo}
                onChange={(e) => setMoreInfo(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Button type="submit" variant="primary" className="mt-3">
              Submit
            </Button>
          </Form>
        </Col>
        <Col md={1}></Col>
        <Col md={7}>
          <Row className="align-items-center">
            <Col>
              <h2>Todos</h2>
            </Col>
            <Col className="text-end">
              <Dropdown>
                <Dropdown.Toggle>{dropDownTitle}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {empTypes &&
                    empTypes
                      .sort((a, b) => a.empType.localeCompare(b.empType))
                      .map((empType) => (
                        <Dropdown.Item
                          key={empType._id}
                          onClick={(e) => setDropDownTitle(empType.empType)}
                        >
                          {empType.empType}
                        </Dropdown.Item>
                      ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          {errorWeight && <Message variant="danger">{errorWeight}</Message>}
          {errorDelete && <Message variant="danger">{errorDelete}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <div>
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th width="10px" className="text-center px-3">
                      Order
                    </th>
                    <th>Todo</th>
                    <th width="10%"></th>
                  </tr>
                </thead>

                <tbody>
                  {todos &&
                    todos
                      .sort(function (a, b) {
                        return a.weight - b.weight;
                      })
                      .filter(function(a) {return a.empType === dropDownTitle})
                      .filter(function(a) {return a.isOffboarding !== onboarding})
                      //ADD FILTER HERE TO SHOW OFF BOARDING TODOS AND OFFBOARDING TODOS
                      .map((todo, index, dropDownTitle) => (
                        <tr key={index}>
                          <td className="text-center pt-2">
                            {index === 0 &&
                              index === dropDownTitle.length - 1 ? (
                              <div>
                                <i
                                  style={{ color: "grey" }}
                                  className="fa-solid fa-arrow-up px-1 text-center"
                                ></i>
                                <i
                                  style={{ color: "grey" }}
                                  className="fa-solid fa-arrow-down px-1"
                                ></i>
                              </div>
                            ) : index === 0 ? (
                              <div>
                                <i
                                  style={{ color: "grey" }}
                                  className="fa-solid fa-arrow-up px-1 text-center"
                                ></i>
                                <i
                                  role="button"
                                  onClick={(e) =>
                                    orderHandler("down", index, dropDownTitle)
                                  }
                                  className="fa-solid fa-arrow-down px-1"
                                ></i>
                              </div>
                            ) : index === dropDownTitle.length - 1 ? (
                              <div>
                                <i
                                  role="button"
                                  onClick={(e) =>
                                    orderHandler("up", index, dropDownTitle)
                                  }
                                  className="fa-solid fa-arrow-up px-1 text-center"
                                ></i>
                                <i
                                  style={{ color: "grey" }}
                                  className="fa-solid fa-arrow-down px-1"
                                ></i>
                              </div>
                            ) : (
                              <div>
                                <i
                                  role="button"
                                  onClick={(e) =>
                                    orderHandler("up", index, dropDownTitle)
                                  }
                                  className="fa-solid fa-arrow-up px-1 text-center"
                                ></i>
                                <i
                                  role="button"
                                  onClick={(e) =>
                                    orderHandler("down", index, dropDownTitle)
                                  }
                                  className="fa-solid fa-arrow-down px-1"
                                ></i>
                              </div>
                            )}
                          </td>
                          <td>{todo.customMessage}</td>
                          <td className="d-flex justify-content-end">
                            <LinkContainer
                              to={`/app/onboarding/todos/${todo._id}/edit`}
                            >
                              <Button variant="light" className="btn-sm">
                                <i className="fas fa-edit"></i>
                              </Button>
                            </LinkContainer>

                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => deleteHandler(todo._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default DeptListScreen;

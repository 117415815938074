import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Table, ProgressBar, Form, Button } from "react-bootstrap";
import { completeTask, getUsersTasks } from "../actions/checklistActions";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../actions/userActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { LinkContainer } from "react-router-bootstrap";

let checklistDifference = 0;

function OnboardingUserScreen() {
  const { id: userId } = useParams();
  const dispatch = useDispatch();

  const [info, setInfo] = useState("");

  const getUserTasks = useSelector((state) => state.getUserTasks);
  const { loading, error, tasks } = getUserTasks;

  const checkTask = useSelector((state) => state.checkTask);
  const { error: errorTask } = checkTask;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingDetails, error: errorDetails, user } = userDetails;

  const [offboarding, setOffboarding] = useState(false);

  useEffect(() => {
    dispatch(getUsersTasks(userId, user.name, userId));

    if (user.id !== userId) {
      dispatch(getUserDetails(userId));
    }
    checklistDifference = 0;
  }, [dispatch, user.id, user.name, userId]);

  function offboardingUser(tasks) {
    var found = false;
    tasks.forEach((task) => {
      if (task.isOffboarding === true) {
        found = true
      }
    });
    if (found === true) {
      return true
    } else {
      return false
    }
  }


  const checkHandler = (isChecked, id) => {
    dispatch(completeTask(id, { isChecked }));
    if (isChecked === true) {
      checklistDifference += 1;
    } else {
      checklistDifference -= 1;
    }
  };

  const renderText = (text) => {
    if (text === "" || text === null) {
      return "";
    }
    const URL_REGEX =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    text = text.split(" ").map((part) =>
      URL_REGEX.test(part) ? (
        <a key={part} target="_blank" rel="noreferrer" href={part}>
          {part}
        </a>
      ) : (
        " " + part + " "
      )
    );
    return text;
  };

  const infoHandler = (task) => {
    setInfo(task);
  };

  function amountComplete(tasks) {
    let tasksDone = 0;
    tasks.forEach((task) => {
      if (task.isChecked === true) {
        tasksDone += 1;
      }
    });
    return tasksDone;
  }

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Onboarding
        </Link>{" "}
        / {user.name}
      </h5>
      <Row>
        <Col md={4}>
          {loadingDetails ? (
            <Loader />
          ) : errorDetails ? (
            <Message variant="danger">{errorDetails}</Message>
          ) : (
            <div>
              <h2 className="mb-0 pb-0">{user.name}</h2>
              <h5 className="mt-2 pt-0">{user.email}</h5>
              {info !== "" ? (
                <div>
                  <h4 className="mt-4 pt-3">More Information</h4>
                  <h5>Task:</h5>
                  <p>{info.customMessage}</p>
                  <h5>Details:</h5>
                  <p style={{ overflowWrap: "break-word" }}>
                    {renderText(info.moreInfo)}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </Col>
        <Col md={1}></Col>
        <Col md={7}>
          {tasks && (
            <div>
              <Row>
                <Col md={6}><h2 className="text-left">
                  {amountComplete(tasks) +
                    checklistDifference +
                    "/" +
                    tasks.length}{" "}
                  Tasks Complete.
                </h2></Col>
                <Col md={6} className="d-flex justify-content-end align-items-center">
                  {offboardingUser(tasks) && <Form.Check
                    className="mt-2"
                    type="switch"
                    id="custom-switch1"
                    label={offboarding ? "Offboarding" : "Onboarding"}
                    defaultChecked={offboarding}
                    onChange={(e) => setOffboarding(e.target.checked)}
                  />}
                  
                </Col>
              </Row>

              <ProgressBar
                className="mb-4"
                animated
                now={
                  ((amountComplete(tasks) + checklistDifference) /
                    tasks.length) *
                  100
                }
              />
            </div>
          )}
          {errorTask && <Message variant="danger">{errorTask}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Table hover responsive className="table-sm">
              <thead>
                <tr>
                  <th>Todo</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {tasks
                  .sort(function (a, b) {
                    return a.empType - b.empType || a.weight - b.weight;
                  })
                  .filter(function (a) { return a.isOffboarding === offboarding })
                  .map((task) => (
                    <tr key={task._id}>
                      <td onClick={(e) => infoHandler(task)}>
                        {task.customMessage}
                      </td>
                      <td className="d-flex justify-content-end align-slef-center pe-3">
                        <Form.Check
                          type="checkbox"
                          defaultChecked={task.isChecked}
                          onChange={(e) =>
                            checkHandler(e.target.checked, task._id)
                          }
                        ></Form.Check>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          <div className="text-center">
            <LinkContainer to="/app/onboarding">
              <Button className="my-3">Go Back</Button>
            </LinkContainer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OnboardingUserScreen;

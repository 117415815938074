import React from 'react'

function MoodBarView({ selectedFace=null, setLevel }) {
    return (
        <div className="m-0 p-0 d-flex justify-content-center">
            <div className="mx-3" style={{ backgroundColor: "#20374C", borderRadius: "15px", maxWidth: "175px"}}>
                <svg id="mood-svg" width="100%" height="100%" viewBox="0 0 121 121" className='p-3' fill='black'>
                    <path fillRule="nonzero" clipRule="nonzero" fill={selectedFace === 1 ? "darkCyan" : "transparent"} d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M54.6808 63.7749C44.0912 66.4085 36.0028 75.1441 31.7632 81.9095L40.2368 87.2196C43.8305 81.485 50.0921 75.2206 57.0942 73.4792C60.4244 72.651 64.0361 72.8045 67.9424 74.6648C71.935 76.5661 76.5242 80.394 81.391 87.4135L89.609 81.7157C84.0758 73.7351 78.2566 68.5005 72.2419 65.6362C66.141 62.7309 60.1464 62.4156 54.6808 63.7749Z" />
                    <path d="M51 45.5C51 49.6421 47.6421 53 43.5 53C39.3579 53 36 49.6421 36 45.5C36 41.3579 39.3579 38 43.5 38C47.6421 38 51 41.3579 51 45.5Z" />
                    <path d="M88 45.5C88 49.6421 84.6421 53 80.5 53C76.3579 53 73 49.6421 73 45.5C73 41.3579 76.3579 38 80.5 38C84.6421 38 88 41.3579 88 45.5Z" />
                </svg>
            </div>
            <div className="mx-3" style={{ backgroundColor: "#20374C", borderRadius: "15px", maxWidth: "175px" }}>
                <svg id="mood-svg" width="100%" height="100%" viewBox="0 0 121 121" className='p-3'>
                    <path fillRule="nonzero" clipRule="nonzero" fill={selectedFace === 2 ? "darkCyan" : "transparent"} d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M86.1848 78.3576L45.1848 88.3576L42.8152 78.6424L83.8152 68.6424L86.1848 78.3576Z" />
                    <path d="M51 45.5C51 49.6421 47.6421 53 43.5 53C39.3579 53 36 49.6421 36 45.5C36 41.3579 39.3579 38 43.5 38C47.6421 38 51 41.3579 51 45.5Z" />
                    <path d="M88 45.5C88 49.6421 84.6421 53 80.5 53C76.3579 53 73 49.6421 73 45.5C73 41.3579 76.3579 38 80.5 38C84.6421 38 88 41.3579 88 45.5Z" />
                </svg>
            </div>
            <div className="mx-3" style={{ backgroundColor: "#20374C", borderRadius: "15px", maxWidth: "175px" }}>
                <svg id="mood-svg" width="100%" height="100%" viewBox="0 0 121 121" className='p-3'>
                    <path fillRule="nonzero" clipRule="nonzero" fill={selectedFace === 3 ? "darkCyan" : "transparent"} d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M84 85.5H39.5V75.5H84V85.5Z" />
                    <path d="M51 45.5C51 49.6421 47.6421 53 43.5 53C39.3579 53 36 49.6421 36 45.5C36 41.3579 39.3579 38 43.5 38C47.6421 38 51 41.3579 51 45.5Z" />
                    <path d="M88 45.5C88 49.6421 84.6421 53 80.5 53C76.3579 53 73 49.6421 73 45.5C73 41.3579 76.3579 38 80.5 38C84.6421 38 88 41.3579 88 45.5Z" />
                </svg>
            </div>
            <div className="mx-3" style={{ backgroundColor: "#20374C", borderRadius: "15px", maxWidth: "175px" }}>
                <svg id="mood-svg" width="100%" height="100%" viewBox="0 0 121 121" className='p-3'>
                    <path fillRule="nonzero" clipRule="nonzero" fill={selectedFace === 4 ? "darkCyan" : "transparent"} d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M40.8512 83.4738C37.2111 80.6642 33.9195 76.7995 33.0883 72.4356L42.9117 70.5645C43.0805 71.4506 44.1639 73.3984 46.9613 75.5575C49.5955 77.5907 53.1448 79.3196 56.9933 80.0997C60.8165 80.8746 64.7583 80.6832 68.3797 79.1363C71.9523 77.6102 75.5907 74.6061 78.6171 69.0937L87.3829 73.9063C83.4093 81.1439 78.1727 85.8273 72.3078 88.3325C66.4917 90.8169 60.4336 91.0004 55.0067 89.9004C49.6053 88.8055 44.6546 86.4093 40.8512 83.4738Z" />
                    <path d="M50 45.5C50 49.6421 46.6421 53 42.5 53C38.3579 53 35 49.6421 35 45.5C35 41.3579 38.3579 38 42.5 38C46.6421 38 50 41.3579 50 45.5Z" />
                    <path d="M87 45.5C87 49.6421 83.6421 53 79.5 53C75.3579 53 72 49.6421 72 45.5C72 41.3579 75.3579 38 79.5 38C83.6421 38 87 41.3579 87 45.5Z" />
                </svg>
            </div>
            <div className="mx-3" style={{ backgroundColor: "#20374C", borderRadius: "15px", maxWidth: "175px" }}>
                <svg id="mood-svg" width="100%" height="100%" viewBox="0 0 121 121" className='p-3'>
                    <path fillRule="nonzero" clipRule="nonzero" fill={selectedFace === 5 ? "darkCyan" : "transparent"} d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <g filter="url(#filter0_d_2_101)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M35.8597 45.0185C34.6021 46.8375 34.0722 49.312 33.9985 51.5967L28.0016 51.4033C28.0946 48.5213 28.7539 44.7458 30.9243 41.6065C33.218 38.2888 36.9889 36 42.5 36C45.4795 36 47.9754 36.7006 49.9796 37.978C51.9806 39.2533 53.3173 40.9926 54.1571 42.7961C55.7653 46.2498 55.6747 50.2039 54.8151 52.5371L49.185 50.4629C49.4921 49.6294 49.6514 47.3336 48.7179 45.3289C48.2869 44.4032 47.6548 43.6113 46.7548 43.0377C45.858 42.4661 44.5206 42 42.5 42C38.8897 42 36.994 43.3778 35.8597 45.0185Z" />
                    </g>
                    <g filter="url(#filter1_d_2_101)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M72.8597 45.0185C71.6021 46.8375 71.0722 49.312 70.9985 51.5967L65.0016 51.4033C65.0946 48.5213 65.7539 44.7458 67.9243 41.6065C70.218 38.2888 73.9889 36 79.5 36C82.4795 36 84.9754 36.7006 86.9796 37.978C88.9806 39.2533 90.3173 40.9926 91.1571 42.7961C92.7653 46.2498 92.6747 50.2039 91.8151 52.5371L86.185 50.4629C86.4921 49.6294 86.6514 47.3336 85.7179 45.3289C85.2869 44.4032 84.6548 43.6113 83.7548 43.0377C82.858 42.4661 81.5206 42 79.5 42C75.8897 42 73.994 43.3778 72.8597 45.0185Z" />
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M60.5 111C88.3904 111 111 88.3904 111 60.5C111 32.6096 88.3904 10 60.5 10C32.6096 10 10 32.6096 10 60.5C10 88.3904 32.6096 111 60.5 111ZM60.5 121C93.9132 121 121 93.9132 121 60.5C121 27.0868 93.9132 0 60.5 0C27.0868 0 0 27.0868 0 60.5C0 93.9132 27.0868 121 60.5 121Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M54.6808 88.7897C44.0912 86.1561 36.0028 77.4204 31.7632 70.6551L40.2368 65.3449C43.8305 71.0796 50.0921 77.3439 57.0942 79.0853C60.4244 79.9135 64.0361 79.7601 67.9424 77.8998C71.935 75.9984 76.5242 72.1706 81.391 65.1511L89.609 70.8489C84.0758 78.8294 78.2566 84.064 72.2419 86.9283C66.141 89.8337 60.1464 90.149 54.6808 88.7897Z" />
                    <defs>
                        <filter id="filter0_d_2_101" x="24.0016" y="36" width="35.4224" height="24.5371" filterUnits="userSpaceOnUse">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_101" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_101" result="shape" />
                        </filter>
                        <filter id="filter1_d_2_101" x="61.0016" y="36" width="35.4224" height="24.5371" filterUnits="userSpaceOnUse">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_101" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_101" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default MoodBarView
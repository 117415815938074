export const MOOD_DATES_REQUEST = 'MOOD_DATES_REQUEST'
export const MOOD_DATES_SUCCESS = 'MOOD_DATES_SUCCESS'
export const MOOD_DATES_FAIL = 'MOOD_DATES_FAIL'
export const MOOD_DATES_RESET = 'MOOD_DATES_RESET'

export const MOOD_SELECT_DATES_REQUEST = 'MOOD_SELECT_DATES_REQUEST'
export const MOOD_SELECT_DATES_SUCCESS = 'MOOD_SELECT_DATES_SUCCESS'
export const MOOD_SELECT_DATES_FAIL = 'MOOD_SELECT_DATES_FAIL'
export const MOOD_SELECT_DATES_RESET = 'MOOD_SELECT_DATES_RESET'

export const MOOD_POST_REPORT_REQUEST = 'MOOD_POST_REPORT_REQUEST'
export const MOOD_POST_REPORT_SUCCESS = 'MOOD_POST_REPORT_SUCCESS'
export const MOOD_POST_REPORT_FAIL = 'MOOD_POST_REPORT_FAIL'
export const MOOD_POST_REPORT_RESET = 'MOOD_POST_REPORT_RESET'

export const MOOD_GET_REPORTS_REQUEST = 'MOOD_GET_REPORTS_REQUEST'
export const MOOD_GET_REPORTS_SUCCESS = 'MOOD_GET_REPORTS_SUCCESS'
export const MOOD_GET_REPORTS_FAIL = 'MOOD_GET_REPORTS_FAIL'
export const MOOD_GET_REPORTS_RESET = 'MOOD_GET_REPORTS_RESET'

export const MOOD_ADMIN_REPORTS_REQUEST = 'MOOD_ADMIN_REPORTS_REQUEST'
export const MOOD_ADMIN_REPORTS_SUCCESS = 'MOOD_ADMIN_REPORTS_SUCCESS'
export const MOOD_ADMIN_REPORTS_FAIL = 'MOOD_ADMIN_REPORTS_FAIL'
export const MOOD_ADMIN_REPORTS_RESET = 'MOOD_ADMIN_REPORTS_RESET'

import {
  BREAK_INFO_GET_REQUEST,
  BREAK_INFO_GET_SUCCESS,
  BREAK_INFO_GET_FAIL,
  BREAK_INFO_GET_RESET,
  BREAK_INFO_UPDATE_REQUEST,
  BREAK_INFO_UPDATE_SUCCESS,
  BREAK_INFO_UPDATE_FAIL,
  BREAK_INFO_UPDATE_RESET,
  BREAK_INFO_GET_ALL_REQUEST,
  BREAK_INFO_GET_ALL_SUCCESS,
  BREAK_INFO_GET_ALL_FAIL,
  BREAK_INFO_GET_ALL_RESET,
  BREAK_INFO_CREATE_REQUEST,
  BREAK_INFO_CREATE_SUCCESS,
  BREAK_INFO_CREATE_FAIL,
  BREAK_INFO_CREATE_RESET,
  BREAK_INFO_DELETE_REQUEST,
  BREAK_INFO_DELETE_SUCCESS,
  BREAK_INFO_DELETE_FAIL,
  BREAK_INFO_UPDATE_OTHER_REQUEST,
  BREAK_INFO_UPDATE_OTHER_SUCCESS,
  BREAK_INFO_UPDATE_OTHER_FAIL,
  BREAK_INFO_UPDATE_OTHER_RESET,
  BREAK_INFO_GET_OTHER_REQUEST,
  BREAK_INFO_GET_OTHER_SUCCESS,
  BREAK_INFO_GET_OTHER_FAIL,
  BREAK_INFO_GET_OTHER_RESET,
  BREAK_SOCKET_ALL_REQUEST,
  BREAK_SOCKET_ALL_SUCCESS,
  BREAK_SOCKET_ALL_FAIL,
  BREAK_SOCKET_ALL_RESET,
  BREAK_SOCKET_ALL_UPDATE,
  BREAK_TYPE_GET_REQUEST,
  BREAK_TYPE_GET_SUCCESS,
  BREAK_TYPE_GET_FAIL,
  BREAK_TYPE_GET_RESET,
} from "../constants/onBreakConstants";

export const breakInfoDetailsReducer = (state = { breakInfo: {} }, action) => {
  switch (action.type) {
    case BREAK_INFO_GET_REQUEST:
      return { ...state, loading: true };

    case BREAK_INFO_GET_SUCCESS:
      return { loading: false, breakInfo: action.payload };

    case BREAK_INFO_GET_FAIL:
      return { loading: false, error: action.payload };

    case BREAK_INFO_GET_RESET:
      return { breakInfo: {} };

    default:
      return state;
  }
};

export const breakInfoOthersDetailsReducer = (
  state = { breakInfo: {} },
  action
) => {
  switch (action.type) {
    case BREAK_INFO_GET_OTHER_REQUEST:
      return { ...state, loading: true };

    case BREAK_INFO_GET_OTHER_SUCCESS:
      return { loading: false, breakInfo: action.payload };

    case BREAK_INFO_GET_OTHER_FAIL:
      return { loading: false, error: action.payload };

    case BREAK_INFO_GET_OTHER_RESET:
      return { breakInfo: {} };

    default:
      return state;
  }
};

export const breakInfoUpdateReducer = (state = { breakInfo: {} }, action) => {
  switch (action.type) {
    case BREAK_INFO_UPDATE_REQUEST:
      return { loading: true };
    case BREAK_INFO_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case BREAK_INFO_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_INFO_UPDATE_RESET:
      return { breakInfo: {} };

    default:
      return state;
  }
};

export const breakInfoUpdateOtherReducer = (
  state = { breakInfo: {} },
  action
) => {
  switch (action.type) {
    case BREAK_INFO_UPDATE_OTHER_REQUEST:
      return { loading: true };
    case BREAK_INFO_UPDATE_OTHER_SUCCESS:
      return { loading: false, success: true };
    case BREAK_INFO_UPDATE_OTHER_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_INFO_UPDATE_OTHER_RESET:
      return { breakInfo: {} };

    default:
      return state;
  }
};

export const breakInfoDetailsListReducer = (
  state = { breakInfos: [] },
  action
) => {
  switch (action.type) {
    case BREAK_INFO_GET_ALL_REQUEST:
      return { loading: true };
    case BREAK_INFO_GET_ALL_SUCCESS:
      return { loading: false, breakInfos: action.payload };
    case BREAK_INFO_GET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_INFO_GET_ALL_RESET:
      return { breakInfos: [] };

    default:
      return state;
  }
};

export const breakListSocketReducer = (
  state = { breakInfos: [] },
  action
) => {
  switch (action.type) {
    case BREAK_SOCKET_ALL_REQUEST:
      return { loading: true };
    case BREAK_SOCKET_ALL_SUCCESS:
      return { loading: false, breakInfos: action.payload };
    case BREAK_SOCKET_ALL_UPDATE:
      return {
        ...state, 
        breakInfos: state.breakInfos.map(thisBreak => {
            if (thisBreak.user === action.payload.user) {
               return action.payload;
            }

            return thisBreak;
        }),
    };
    case BREAK_SOCKET_ALL_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_SOCKET_ALL_RESET:
      return { breakInfos: [] };

    default:
      return state;
  }
};

export const breakInfoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BREAK_INFO_DELETE_REQUEST:
      return { loading: true };
    case BREAK_INFO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BREAK_INFO_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const breakInfoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BREAK_INFO_CREATE_REQUEST:
      return { loading: true };
    case BREAK_INFO_CREATE_SUCCESS:
      return { loading: false, success: true, breakInfo: action.payload };
    case BREAK_INFO_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_INFO_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const breakTypesReducer = (
  state = { breakTypes: [] },
  action
) => {
  switch (action.type) {
    case BREAK_TYPE_GET_REQUEST:
      return { loading: true };
    case BREAK_TYPE_GET_SUCCESS:
      return { loading: false, breakTypes: action.payload };
    case BREAK_TYPE_GET_FAIL:
      return { loading: false, error: action.payload };
    case BREAK_TYPE_GET_RESET:
      return { breakTypes: [] };

    default:
      return state;
  }
};
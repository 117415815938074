import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listEmployeeTypes, registerOther } from "../actions/checklistActions";

function NewUserScreen() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [cepEmployee, setCepEmployee] = useState(false);
  const [dept, setDept] = useState("");
  const [empTypeMsg, setEmpTypeMsg] = useState("");

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading } = userRegister;

  const newUser = useSelector((state) => state.newUser);
  const {
    success,
    message: newMessage,
    error: newError,
    loading: newLoading,
  } = newUser;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  //This seems to not be firing until the page is refreshed, both pulling up the page and clicking the button do not fire useEffect
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    } else {
      dispatch(listEmployeeTypes());
      if (success) {
        navigate("/app/onboarding");
      }
      dispatch({ type: "CHECKLIST_NEW_USER_RESET" });
    }    
  }, [dispatch, navigate, success, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    setEmpTypeMsg(null);
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
    } else {
      if (dept !== "") {
        dispatch(
          registerOther({ name, email, password, isAdmin, cepEmployee, dept })
        );
      } else {
        setEmpTypeMsg("Select an employee type!");
      }
    }
  };

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Onboarding
        </Link>{" "}
        / New-User
      </h5>{" "}
      <Row>
        <Col md={6} sm={12} xs={12}>
          <h2>Create New User</h2>
          {message && <Message variant="danger">{message}</Message>}
          {newMessage && <Message variant="danger">{newMessage}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          {newError && <Message variant="danger">{newError}</Message>}
          {loading && <Loader />}
          {newLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group className="mt-2" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mt-2" controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mt-2" controlId="password">
                <Form.Label>Temporary Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mt-2" controlId="passwordConfirm">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mt-4 pt-3" controlId="isadmin">
                <Form.Check
                  inline
                  type="checkbox"
                  label="Is Admin"
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                ></Form.Check>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Conequip Employee"
                  id="cepEmployee"
                  checked={cepEmployee}
                  onChange={(e) => setCepEmployee(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <Form.Label className="mt-2">Employee Type</Form.Label>
              <Form.Control
                as="select"
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                required
                style={{ width: "45%", minWidth: "150px" }}
              >
                <option>Select...</option>
                {empTypes &&
                  empTypes
                    .sort((a, b) => a.empType.localeCompare(b.empType))
                    .filter((a) => a.empType !== "Everyone")
                    .map((empType) => (
                      <option key={empType._id} value={empType._id}>
                        {empType.empType}
                      </option>
                    ))}
              </Form.Control>
              {empTypeMsg && (
                <div className="my-4">
                  <Message variant="danger">{empTypeMsg}</Message>
                </div>
              )}
              <Button type="submit" variant="primary" className="mt-3">
                Register User
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default NewUserScreen;

import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Table, Tooltip, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { getEtlActionsFail, updateEtlAction } from '../actions/etlActions';
import Loader from './Loader';
import Message from './Message';

function EtlFailed() {

  const dispatch = useDispatch();

  const etlActionsFailList = useSelector((state) => state.etlActionsFailList);
  const { etlActionsFail, loading, error } = etlActionsFailList;

  const etlActionUpdate = useSelector((state) => state.etlActionUpdate);
  const { success } = etlActionUpdate;

  const [isShowAll, setIsShowAll] = useState(false);

  useEffect(() => {
    dispatch(getEtlActionsFail())
  }, [dispatch, success])


  const convertTime = (e) => {
    if (e === "" || e === null) {
      return "";
    }
    e = new Date(e);
    return e.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  };

  const resolveError = (e) => {
    dispatch(updateEtlAction({ isFixed: "True" }, e))
  }

  return (
    <div>
      {
        loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) :
          (
            isShowAll ? (
              <div>
                <Row>
                  <Col md={6}>
                    <h3>All failed ETL actions:</h3>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end align-items-center">
                    {
                      etlActionsFail && <Form.Check
                        className="mt-2"
                        type="switch"
                        id="custom-switch1"
                        label={"Show All (include amended errors)"}
                        defaultChecked={isShowAll}
                        onChange={(e) => setIsShowAll(e.target.checked)}
                      />
                    }

                  </Col>
                </Row>
                <Table striped bordered hover responsive className="table-sm">
                  <thead>
                    <tr>
                      <th
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </th>
                      <th>
                        Data Source
                      </th>
                      <th>
                        Table Name
                      </th>
                      <th>
                        Task Name
                      </th>
                      <th>
                        Run Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {etlActionsFail &&
                      etlActionsFail
                        .map((etlAction) => (
                          <tr key={etlAction._id}>
                            {etlAction.isSuccessful ? (
                              <td style={{ color: "green", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            ) : etlAction.isFixed ? (
                              <td style={{ color: "#F09000", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            )
                              : (
                                <td style={{ color: "red", textAlign: "center" }}>
                                  <i className="fas fa-circle"></i>
                                </td>
                              )}

                            <td>{etlAction.dataSource}</td>
                            <td>{etlAction.tableName}</td>
                            <td>{etlAction.taskName}</td>
                            {
                              etlAction.createdAt === "" || etlAction.createdAt === null ?
                                <td></td> :
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 650, hide: 200 }}
                                  style={{ display: "none" }}
                                  overlay={<Tooltip id="button-tooltip">
                                    {etlAction.createdAt.split(" ")[0]}
                                  </Tooltip>}
                                >
                                  <td>{convertTime(etlAction.createdAt)}</td>
                                </OverlayTrigger>
                            }
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div>
                <Row>
                  <Col md={6}>
                    <h3>Unfixed ETL errors:</h3>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end align-items-center">
                    {
                      etlActionsFail && <Form.Check
                        className="mt-2"
                        type="switch"
                        id="custom-switch1"
                        label={"Show All (include amended errors)"}
                        defaultChecked={isShowAll}
                        onChange={(e) => setIsShowAll(e.target.checked)}
                      />
                    }

                  </Col>
                </Row>
                <Table striped bordered hover responsive className="table-sm">
                  <thead>
                    <tr>
                      <th
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </th>
                      <th>
                        Data Source
                      </th>
                      <th>
                        Table Name
                      </th>
                      <th>
                        Task Name
                      </th>
                      <th>
                        Run Time
                      </th>
                      <th width="115px" className='text-center'>
                        Error Resolved
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {etlActionsFail &&
                      etlActionsFail
                        .filter((a) => a.isFixed !== true)
                        .map((etlAction) => (
                          <tr key={etlAction._id}>
                            {etlAction.isSuccessful ? (
                              <td style={{ color: "green", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            ) : (
                              <td style={{ color: "red", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            )}

                            <td>{etlAction.dataSource}</td>
                            <td>{etlAction.tableName}</td>
                            <td>{etlAction.taskName}</td>
                            {
                              etlAction.createdAt === "" || etlAction.createdAt === null ?
                                <td></td> :
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 650, hide: 200 }}
                                  style={{ display: "none" }}
                                  overlay={<Tooltip id="button-tooltip">
                                    {etlAction.createdAt.split(" ")[0]}
                                  </Tooltip>}
                                >
                                  <td>{convertTime(etlAction.createdAt)}</td>
                                </OverlayTrigger>
                            }
                            <td className='text-center'>
                              <Button onClick={() => resolveError(etlAction._id)} variant="dark" className="px-2 py-0 m-0">
                                <i
                                  style={{ fontSize: "12px" }}
                                  className="fas fa-check"
                                ></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            )
          )
      }
    </div>
  )
}

export default EtlFailed
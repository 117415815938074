import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/userActions";

function Header() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar className="p-0" bg="dark" variant="dark" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="d-flex align-items-center" id="nav-brand-container">
              <svg
                className="pt-2"
                height="46"
                viewBox="0 0 900 440"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Component 1">
                  <g id="Cross Group">
                    <path
                      id="Vector"
                      d="M380.85 119.955H409.13V92.3351H431.48V119.955H459.84V142.375H431.48V207.915H409.13V142.375H380.85V119.955Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_2"
                      d="M464.61 145.315L490.25 152.105L496.88 127.065L517.14 132.415L510.51 157.465L536.21 164.255L530.83 184.575L505.14 177.775L489.42 237.185L469.16 231.825L484.88 172.425L459.24 165.635L464.61 145.315Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_3"
                      d="M376.07 145.315L350.43 152.105L343.81 127.065L323.55 132.415L330.17 157.465L304.47 164.255L309.85 184.575L335.55 177.775L351.26 237.185L371.52 231.825L355.81 172.425L381.45 165.635L376.07 145.315Z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <g id="charlie">
                    <path
                      id="Vector_4"
                      d="M626.665 153.675L601.345 147.085C598.855 136.135 594.965 126.965 590.005 119.125L603.195 96.4451C584.795 68.6751 562.005 46.0451 533.835 29.7151L509.575 42.3751C500.415 36.2751 490.685 31.9751 480.305 29.7151L474.235 5.71509C438.885 -1.84491 406.145 -1.96491 376.125 5.71509L368.475 31.2951C357.915 32.4751 347.935 36.2751 338.405 42.1051L315.195 29.4451C285.265 47.8051 262.295 70.0751 246.095 96.1751L259.545 118.855C253.855 128.795 249.605 138.405 247.145 147.605L221.035 154.195C212.795 185.315 213.185 217.075 221.035 249.405L247.405 256.265C248.765 267.125 257.815 281.045 265.075 288.115L270.085 271.565L306 275.5C292.69 255.04 288.815 226.695 288.815 200.655C288.815 127.675 349.385 68.5051 424.115 68.5051C498.845 68.5051 559.415 127.665 559.415 200.655C559.415 226.355 556 255.21 543 275.5L577.875 270.505L590.535 283.695C595.815 275.345 599.385 266.275 601.345 256.535L626.665 249.415C636.375 217.785 637.085 185.885 626.665 153.675Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_5"
                      d="M626.665 251.44L601.345 258.03C598.855 268.98 594.965 278.15 590.005 285.99L603.195 308.67C584.795 336.44 562.005 359.07 533.835 375.4L509.575 362.74C500.415 368.84 490.685 373.14 480.305 375.4L474.235 399.4C438.885 406.96 406.145 407.08 376.125 399.4L368.475 373.82C357.915 372.64 347.935 368.84 338.405 363.01L315.195 375.67C285.265 357.31 262.295 335.04 246.095 308.94L259.545 286.26C253.855 276.32 249.605 266.71 247.145 257.51L221.035 250.92C212.795 219.8 213.185 188.04 221.035 155.71L247.405 148.85C248.765 137.99 257.815 124.07 265.075 117L270.085 133.55L305.5 125.5C292.19 145.96 288.815 178.42 288.815 204.46C288.815 277.44 349.385 336.61 424.115 336.61C498.845 336.61 559.415 277.45 559.415 204.46C559.415 178.76 554 150.29 541 130L577.875 134.61L590.535 121.42C595.815 129.77 599.385 138.84 601.345 148.58L626.665 155.7C636.375 187.33 637.085 219.23 626.665 251.44Z"
                      fill="#FFFFFF"
                    />
                  </g>
                  <path
                    id="Cover"
                    d="M248.5 262.5L259 276L263.5 262.5L348.5 264.5L358.5 276L372 238L502.5 239L491 278.5L511.5 264.5H574L586 278.5L592 264.5H841L872 420L-1 437.5L9.5 238H152.5L170 262.5L165.5 276L184.5 262.5H248.5Z"
                    fill="#20374C"
                  />
                  <g id="Conequip Title">
                    <path
                      id="Vector_6"
                      d="M143.12 331.245L116.8 349.955H40.9L25.69 331.235L46.02 262.805L72.34 244.085H148.23L163.44 262.795L157.15 283.745H115.33L118.55 273.075H89.23L73.73 324.995H103.12L107.51 310.305H149.33L143.12 331.245ZM87.11 344.425L77.82 333.025C75.82 335.045 74.82 337.195 74.82 339.485C74.82 340.895 75.26 342.125 76.14 343.175C77.46 344.805 79.38 345.615 81.92 345.615C83.63 345.605 85.36 345.215 87.11 344.425ZM90.12 342.435C92.65 340.235 93.91 337.805 93.91 335.125C93.91 333.725 93.5 332.495 92.67 331.435C91.36 329.815 89.43 328.995 86.9 328.995C84.76 328.995 82.66 329.585 80.62 330.765L90.12 342.435ZM109.53 264.935L100.19 253.505C98.24 255.565 97.27 257.745 97.27 260.025C97.27 261.435 97.69 262.665 98.52 263.715C99.84 265.345 101.77 266.155 104.32 266.155C106.08 266.155 107.82 265.755 109.53 264.935ZM112.48 262.995C115.06 260.785 116.36 258.335 116.36 255.645C116.36 254.235 115.92 252.995 115.04 251.935C113.72 250.305 111.8 249.485 109.26 249.485C107.11 249.485 105.04 250.105 103.05 251.325L112.48 262.995Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_7"
                      d="M241.24 335.715L221.28 349.955H163.59L152.04 335.715L167.47 283.695L187.5 269.515H245.19L256.67 283.695L241.24 335.715ZM210.16 327.625L221.49 289.675H199.19L187.86 327.625H210.16ZM197.37 344.165L190.35 335.465C188.84 337.005 188.08 338.655 188.08 340.405C188.08 341.505 188.4 342.445 189.03 343.235C190.05 344.465 191.54 345.075 193.49 345.075C194.81 345.085 196.1 344.775 197.37 344.165ZM199.68 342.665C201.65 341.035 202.63 339.195 202.63 337.125C202.63 336.075 202.29 335.125 201.61 334.285C200.63 333.055 199.17 332.435 197.22 332.435C195.61 332.435 194.02 332.895 192.46 333.825L199.68 342.665ZM214.25 284.615L207.25 275.905C205.73 277.445 204.97 279.095 204.97 280.845C204.97 281.945 205.29 282.885 205.92 283.675C206.94 284.905 208.4 285.525 210.3 285.525C211.62 285.525 212.93 285.225 214.25 284.615ZM216.6 283.155C218.55 281.475 219.52 279.595 219.52 277.515C219.52 276.455 219.18 275.505 218.5 274.665C217.52 273.425 216.06 272.805 214.11 272.805C212.5 272.805 210.92 273.285 209.36 274.255L216.6 283.155Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_8"
                      d="M336.14 349.955H301.7L319.61 290.335H297.24L279.55 349.955H246.94L270.85 269.515H344.33L355.81 283.695L336.14 349.955ZM276.48 318.465L269.46 309.765C267.95 311.345 267.19 312.995 267.19 314.705C267.19 315.805 267.51 316.745 268.14 317.535C269.16 318.765 270.65 319.375 272.6 319.375C273.92 319.385 275.21 319.085 276.48 318.465ZM278.83 317.005C280.77 315.325 281.74 313.445 281.74 311.375C281.74 310.315 281.4 309.385 280.72 308.595C279.74 307.315 278.28 306.675 276.33 306.675C274.72 306.675 273.13 307.165 271.57 308.135L278.83 317.005ZM331.53 318.075L324.51 309.445C323.01 310.985 322.26 312.625 322.26 314.385C322.26 315.435 322.58 316.365 323.21 317.155C324.23 318.385 325.69 318.995 327.59 318.995C328.9 318.995 330.21 318.685 331.53 318.075ZM333.88 316.625C335.78 314.945 336.73 313.085 336.73 311.055C336.73 309.955 336.41 309.005 335.78 308.205C334.8 306.965 333.34 306.345 331.39 306.345C329.78 306.345 328.2 306.805 326.64 307.725L333.88 316.625Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_9"
                      d="M463.51 349.955H346.09L377.53 244.085H494.95L486.98 270.835H414.45L410.5 284.075H483.1L475.35 310.035H402.82L398.87 323.275H471.4L463.51 349.955ZM376.28 343.305L366.99 331.905C364.99 333.925 363.99 336.075 363.99 338.365C363.99 339.815 364.43 341.065 365.31 342.115C366.63 343.695 368.55 344.485 371.09 344.485C372.85 344.495 374.58 344.095 376.28 343.305ZM379.29 341.315C381.87 339.165 383.15 336.755 383.15 334.075C383.15 332.625 382.71 331.375 381.84 330.325C380.53 328.705 378.6 327.885 376.08 327.885C373.94 327.885 371.87 328.475 369.88 329.645L379.29 341.315ZM398.21 265.965L388.94 254.485C386.94 256.505 385.93 258.685 385.93 261.015C385.93 262.425 386.37 263.655 387.24 264.705C388.55 266.335 390.48 267.145 393.01 267.145C394.72 267.145 396.45 266.755 398.21 265.965ZM401.21 263.985C403.74 261.775 405.01 259.325 405.01 256.635C405.01 255.225 404.59 253.985 403.77 252.925C402.45 251.295 400.53 250.475 397.99 250.475C395.84 250.475 393.75 251.095 391.7 252.315L401.21 263.985Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_10"
                      d="M568.07 335.715L548.12 349.955H524.87L520.49 364.645H509.28L513.66 349.955H490.41L478.86 335.715L494.29 283.695L514.32 269.515H572.01L583.49 283.695L568.07 335.715ZM537 327.625L548.32 289.675H526.02L514.69 327.625H520.32L524.7 312.935H535.83L531.45 327.635L537 327.625ZM524.19 344.165L517.17 335.465C515.66 337.005 514.9 338.655 514.9 340.405C514.9 341.505 515.22 342.445 515.85 343.235C516.87 344.465 518.36 345.075 520.31 345.075C521.63 345.085 522.93 344.775 524.19 344.165ZM526.51 342.665C528.47 341.035 529.46 339.195 529.46 337.125C529.46 336.075 529.12 335.125 528.43 334.285C527.45 333.055 525.99 332.435 524.04 332.435C522.43 332.435 520.84 332.895 519.28 333.825L526.51 342.665ZM541.07 284.615L534.07 275.905C532.55 277.445 531.79 279.095 531.79 280.845C531.79 281.945 532.11 282.885 532.74 283.675C533.76 284.905 535.22 285.525 537.12 285.525C538.44 285.525 539.76 285.225 541.07 284.615ZM543.42 283.155C545.37 281.475 546.34 279.595 546.34 277.515C546.34 276.455 546 275.505 545.32 274.665C544.34 273.425 542.88 272.805 540.93 272.805C539.32 272.805 537.74 273.285 536.18 274.255L543.42 283.155Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_11"
                      d="M667.65 335.715L647.62 349.955H589.93L577.94 335.845L597.68 269.515H630.73L613.26 328.345H635.56L653.03 269.515H687.32L667.65 335.715ZM622.97 344.165L615.88 335.465C614.37 337.005 613.61 338.655 613.61 340.405C613.61 341.505 613.95 342.445 614.63 343.235C615.6 344.465 617.07 345.075 619.02 345.075C620.34 345.085 621.66 344.775 622.97 344.165ZM625.26 342.635C627.19 341.015 628.16 339.175 628.16 337.105C628.16 336.055 627.84 335.115 627.21 334.275C626.19 333.045 624.7 332.435 622.75 332.435C621.14 332.435 619.58 332.895 618.07 333.815L625.26 342.635Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_12"
                      d="M706.4 349.955H673.35L697.26 269.515H730.31L706.4 349.955ZM695.14 344.555L688.12 335.855C686.61 337.395 685.85 339.035 685.85 340.795C685.85 341.845 686.19 342.795 686.87 343.625C687.84 344.855 689.31 345.465 691.26 345.465C692.58 345.475 693.87 345.175 695.14 344.555ZM697.45 343.055C699.41 341.385 700.4 339.535 700.4 337.515C700.4 336.415 700.08 335.465 699.45 334.675C698.43 333.445 696.94 332.825 694.99 332.825C693.38 332.825 691.79 333.285 690.23 334.215L697.45 343.055ZM712.41 286.485L705.35 277.835C703.86 279.375 703.11 281.015 703.11 282.765C703.11 283.815 703.45 284.735 704.13 285.525C705.1 286.755 706.55 287.365 708.49 287.365C709.79 287.375 711.1 287.075 712.41 286.485ZM714.73 284.995C716.68 283.315 717.65 281.455 717.65 279.425C717.65 278.325 717.33 277.375 716.7 276.575C715.68 275.335 714.19 274.715 712.24 274.715C710.63 274.715 709.07 275.175 707.56 276.095L714.73 284.995Z"
                      fill="#FFFFFF"
                    />
                    <path
                      id="Vector_13"
                      d="M822.94 295.515L802.91 309.765H762.48L750.56 349.955H717.51L741.42 269.515H814.9L826.38 283.705L822.94 295.515ZM740.32 344.095L733.3 335.395C731.79 336.935 731.03 338.575 731.03 340.335C731.03 341.385 731.35 342.335 731.98 343.165C733 344.395 734.47 345.005 736.37 345.005C737.69 345.015 739.01 344.715 740.32 344.095ZM742.64 342.595C744.6 340.925 745.59 339.075 745.59 337.055C745.59 335.955 745.25 335.005 744.56 334.215C743.58 332.985 742.11 332.365 740.15 332.365C738.53 332.365 736.94 332.815 735.38 333.715L742.64 342.595ZM757.63 285.735L750.56 277.025C749.1 278.565 748.37 280.205 748.37 281.965C748.37 283.065 748.69 284.005 749.32 284.795C750.29 286.025 751.75 286.635 753.7 286.635C755 286.645 756.32 286.345 757.63 285.735ZM759.92 284.205C761.87 282.585 762.84 280.735 762.84 278.675C762.84 277.625 762.52 276.675 761.89 275.845C760.87 274.615 759.4 274.005 757.5 274.005C755.84 274.005 754.26 274.465 752.75 275.385L759.92 284.205ZM787.19 300.485L793.11 280.325H770.81L764.89 300.485H787.19Z"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </svg>
              <p className="m-0 px-3 pt-1 navbar-title">Dash Bored</p>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title="Admin" id="adminmenu" className="px-1">
                  <LinkContainer to="/frontend-admin/userlist">
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/frontend-admin/applicationlist">
                    <NavDropdown.Item>Applications</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              <Container>
              <LinkContainer to="/versions">
              <Nav.Link className="nav-icon">
              <i role="button" className="fa-solid fa-code-branch"></i>
                </Nav.Link>
              </LinkContainer>
              </Container>

              <LinkContainer to="/profile">
                <Nav.Link className="nav-icon">
                  <i className="fa-solid fa-user"></i>
                </Nav.Link>
              </LinkContainer>

              <Container onClick={logoutHandler} className="w-auto">
                <Nav.Link className="nav-icon">
                  <i className="fa-solid fa-right-from-bracket"></i>
                </Nav.Link>
              </Container>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;

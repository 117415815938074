import axios from "axios";
import {
  CHECKLIST_DEPT_GET_ALL_REQUEST,
  CHECKLIST_DEPT_GET_ALL_SUCCESS,
  CHECKLIST_DEPT_GET_ALL_FAIL,
  CHECKLIST_DEPT_POST_REQUEST,
  CHECKLIST_DEPT_POST_SUCCESS,
  CHECKLIST_DEPT_POST_FAIL,
  CHECKLIST_DEPT_DELETE_REQUEST,
  CHECKLIST_DEPT_DELETE_SUCCESS,
  CHECKLIST_DEPT_DELETE_FAIL,
  CHECKLIST_DEPT_PUT_REQUEST,
  CHECKLIST_DEPT_PUT_SUCCESS,
  CHECKLIST_DEPT_PUT_FAIL,
  CHECKLIST_DEPT_GET_REQUEST,
  CHECKLIST_DEPT_GET_SUCCESS,
  CHECKLIST_DEPT_GET_FAIL,
  CHECKLIST_TODO_GET_ALL_REQUEST,
  CHECKLIST_TODO_GET_ALL_SUCCESS,
  CHECKLIST_TODO_GET_ALL_FAIL,
  CHECKLIST_TODO_POST_REQUEST,
  CHECKLIST_TODO_POST_SUCCESS,
  CHECKLIST_TODO_POST_FAIL,
  CHECKLIST_TODO_DELETE_REQUEST,
  CHECKLIST_TODO_DELETE_SUCCESS,
  CHECKLIST_TODO_DELETE_FAIL,
  CHECKLIST_TODO_PUT_REQUEST,
  CHECKLIST_TODO_PUT_SUCCESS,
  CHECKLIST_TODO_PUT_FAIL,
  CHECKLIST_TODO_GET_REQUEST,
  CHECKLIST_TODO_GET_SUCCESS,
  CHECKLIST_TODO_GET_FAIL,
  CHECKLIST_NEW_USER_REQUEST,
  CHECKLIST_NEW_USER_SUCCESS,
  CHECKLIST_NEW_USER_FAIL,
  CHECKLIST_GET_USERS_REQUEST,
  CHECKLIST_GET_USERS_SUCCESS,
  CHECKLIST_GET_USERS_FAIL,
  CHECKLIST_GET_USER_TASKS_REQUEST,
  CHECKLIST_GET_USER_TASKS_SUCCESS,
  CHECKLIST_GET_USER_TASKS_FAIL,
  CHECKLIST_COMPLETE_TASK_REQUEST,
  CHECKLIST_COMPLETE_TASK_SUCCESS,
  CHECKLIST_COMPLETE_TASK_FAIL,
  CHECKLIST_COMPLETE_ALL_TASKS_REQUEST,
  CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS,
  CHECKLIST_COMPLETE_ALL_TASKS_FAIL,
  CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST,
  CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS,
  CHECKLIST_TASK_WEIGHT_UPDATE_FAIL,
  CHECKLIST_OFFBOARD_REQUEST,
  CHECKLIST_OFFBOARD_SUCCESS,
  CHECKLIST_OFFBOARD_FAIL,
} from "../constants/checklistConstants";

export const listEmployeeTypes = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_DEPT_GET_ALL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/checklist/employeetype/`, config);

    dispatch({
      type: CHECKLIST_DEPT_GET_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_DEPT_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const createEmployeeType = (newType) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DEPT_POST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/checklist/employeetype/`,
      newType,
      config
    );

    dispatch({
      type: CHECKLIST_DEPT_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_DEPT_POST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const deleteEmpType = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DEPT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/checklist/employeetype/${id}/`, config);

    dispatch({
      type: CHECKLIST_DEPT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_DEPT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const putEmpType = (deptType, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DEPT_PUT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/checklist/employeetype/${id}/`,
      deptType,
      config
    );

    dispatch({
      type: CHECKLIST_DEPT_PUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_DEPT_PUT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const getOneEmpType = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_DEPT_GET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/checklist/employeetype/${id}/`,
      config
    );

    dispatch({
      type: CHECKLIST_DEPT_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_DEPT_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const listChecklistTodos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_TODO_GET_ALL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/checklist/employeetodo/`, config);

    dispatch({
      type: CHECKLIST_TODO_GET_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_TODO_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const createChecklistTodo = (newTodo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_TODO_POST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/checklist/employeetodo/`,
      newTodo,
      config
    );

    dispatch({
      type: CHECKLIST_TODO_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_TODO_POST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const deleteChecklistTodo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_TODO_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/checklist/employeetodo/${id}/`, config);

    dispatch({
      type: CHECKLIST_TODO_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_TODO_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const putChecklistTodo =
  (id, checklistTodo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECKLIST_TODO_PUT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/checklist/employeetodo/${id}/`,
        checklistTodo,
        config
      );

      dispatch({
        type: CHECKLIST_TODO_PUT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHECKLIST_TODO_PUT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };


export const getOneChecklistTodo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_TODO_GET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/checklist/employeetodo/${id}/`,
      config
    );

    dispatch({
      type: CHECKLIST_TODO_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_TODO_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const registerOther =
  (registerOtherInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECKLIST_NEW_USER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data: newUserData } = await axios.post(
        `/api/users/registerother/`,
        registerOtherInfo,
        config
      );
      await axios.post(
        `/api/users/perms/create/${newUserData._id}/`,
        registerOtherInfo,
        config
      );
      await axios.post(
        `/api/checklist/newuser/${newUserData._id}/`,
        registerOtherInfo,
        config
      );

      dispatch({
        type: CHECKLIST_NEW_USER_SUCCESS,
        payload: newUserData,
      });
    } catch (error) {
      dispatch({
        type: CHECKLIST_NEW_USER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };


  export const offboardOther =
  (offboardId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECKLIST_OFFBOARD_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };  

      await axios.post(
        `/api/checklist/olduser/${offboardId}/`,
        offboardId,
        config
      );

      dispatch({
        type: CHECKLIST_OFFBOARD_SUCCESS,
        payload: offboardId,
      });
    } catch (error) {
      dispatch({
        type: CHECKLIST_OFFBOARD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };


export const getIncompleteUsersReq = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_GET_USERS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/checklist/incompleteusers/`, config);

    dispatch({
      type: CHECKLIST_GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_GET_USERS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const getUsersTasks = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_GET_USER_TASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/checklist/usertasks/${id}/`, config);

    dispatch({
      type: CHECKLIST_GET_USER_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_GET_USER_TASKS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const completeTask = (id, checkData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKLIST_COMPLETE_TASK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/checklist/todo/check/${id}/`,
      checkData,
      config
    );

    dispatch({
      type: CHECKLIST_COMPLETE_TASK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_COMPLETE_TASK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const completeAllTasks = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_COMPLETE_ALL_TASKS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/checklist/usertasks/${id}/`,
      {},
      config
    );

    dispatch({
      type: CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_COMPLETE_ALL_TASKS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const updateWeights = (newWeights) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/checklist/employeetodos/`,
      newWeights,
      config
    );

    dispatch({
      type: CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKLIST_TASK_WEIGHT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

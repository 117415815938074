import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { putEmpType, getOneEmpType } from "../actions/checklistActions";
import { CHECKLIST_DEPT_PUT_RESET } from "../constants/checklistConstants";

function DeptEditScreen() {
  const { id: empTypeId } = useParams();
  const [newEmpType, setNewEmpType] = useState();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const empTypeGet = useSelector((state) => state.empTypeGet);
  const { empType, error, loading } = empTypeGet;

  const empTypePut = useSelector((state) => state.empTypePut);
  const { error: errorPut, success: successPut } = empTypePut;

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.isAdmin !== true) {
      navigate("/");
    } else {
      dispatch(getOneEmpType(empTypeId));
      if (successPut) {
        navigate("/app/onboarding/departments");
        dispatch({ type: CHECKLIST_DEPT_PUT_RESET });
      }
    }
  }, [dispatch, navigate, userInfo.isAdmin, successPut, empTypeId]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(putEmpType({ empType: newEmpType }, empTypeId));
  };

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Onboarding
        </Link>{" "}
        /{" "}
        <Link
          className="productTitle text-white"
          to="/app/onboarding/departments"
        >
          Departments
        </Link>{" "}
        / Edit
      </h5>
      <Row>
        <Col md={5}>
          <h2>Edit {empType && empType.empType}</h2>

          {errorPut && <Message variant="danger">{errorPut}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group className="mt-2" controlId="empType">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Department"
                  className="mb-3"
                >
                  <Form.Control
                    type="empType"
                    placeholder="Department"
                    onChange={(e) => setNewEmpType(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Button
                type="submit"
                size="md"
                variant="primary"
                className="mt-1"
              >
                Update
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default DeptEditScreen;

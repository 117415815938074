import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Table } from 'react-bootstrap'
import { getUserDetails, listUsers } from "../actions/userActions";
import Loader from "./Loader";
import { getMoodDates, getMoodReports } from "../actions/moodActions";
import MoodBarView from "./MoodBarView";

function MoodDrillDown({ dropdownItem = null, byUser = null }) {

    const dispatch = useDispatch();

    // Get the current date
    let today = new Date();

    // Set the time to midnight (start of the day)
    today.setHours(0, 0, 0, 0);

    // Get the day of the week (0 is Sunday, 1 is Monday, etc.)
    let dayOfWeek = today.getDay();

    // Calculate the date of the previous Monday
    let lastWeekStart = new Date(today.getTime());
    // (today.getDate() - 8 - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)) is monday of last week.. -1 is quick fix at end is to make it sunday
    lastWeekStart.setDate((today.getDate() - 8 - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)) - 1);

    // Calculate the date of the next Saturday
    let thisWeekEnd = new Date(today.getTime());
    thisWeekEnd.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 6));


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const userList = useSelector((state) => state.userList);
    const { users, loading } = userList;

    const moodReportList = useSelector((state) => state.moodReportList);
    const { moodReports, loading: loadingReports } = moodReportList;

    const userDetails = useSelector((state) => state.userDetails);
    const { user, loading: loadingUser } = userDetails;

    const [selectedUser, setSelectedUser] = useState()
    const [selectedDate, setSelectedDate] = useState()
    const [selectedReport, setSelectedReport] = useState()
    const [employeeOfTheMonth, setEmployeeOfTheMonth] = useState()

    const moodSelectDates = useSelector((state) => state.moodSelectDates);
    const { moodDates, loading: loadingDates } = moodSelectDates;

    const [myFilter, setMyFilter] = useState("name");
    const [filterDirection, setFilterDirection] = useState(true);

    const filterHandler = (filterName) => {
        if (filterName === myFilter) {
          setFilterDirection(!filterDirection);
        } else {
          setMyFilter(filterName);
          setFilterDirection(true);
        }
      };

    useEffect(() => {
        setSelectedUser()
        setSelectedDate()
        setSelectedReport()
        setEmployeeOfTheMonth()
        if (userInfo.permissions.mood_admin || userInfo.isAdmin || userInfo.permissions.isCepManager || userInfo.permissions.isCepDeptManager) {
            if (byUser) {
                if (dropdownItem === "All Reports") {
                    dispatch(listUsers("moodAdmin"))
                } else if (dropdownItem === "My Reports") {
                    if (userInfo.permissions.isCepDeptManager){
                        dispatch(listUsers("deptManager"))
                    } else {
                        dispatch(listUsers("normalManager"))
                    }
                }
            } else if (!byUser) {
                dispatch(getMoodDates(false))
            }
        } //eslint-disable-next-line
    }, [dropdownItem, byUser])

    useEffect(() => {
        if (selectedUser) {
            dispatch(getMoodReports(selectedUser, "byUser"));
            dispatch(getUserDetails(selectedUser));
        } else if (selectedDate) {
            dispatch(getMoodReports(selectedDate, "byDate"));
        } //eslint-disable-next-line
    }, [selectedUser, selectedDate])

    const backButton = (e) => {
        setEmployeeOfTheMonth()
        if ((selectedUser || selectedDate) && !selectedReport) {
            setSelectedUser(null)
            setSelectedDate()
        } else {
            setSelectedReport(null)
        }
    }

    function topEmployee(e) {
        var counts = {}
        for (var num of e) {
            counts[num.nominatedEmployeeName] = counts[num.nominatedEmployeeName] ? counts[num.nominatedEmployeeName] + 1 : 1;
        }
        var myObj = []
        Object.entries(counts).forEach(([key, value]) => {
            if (key !== null && key !== '') {
                var person = { name: key, totalVotes: value };
                myObj.push(person)
            }
        })
        return myObj
    }

    function formatDate(e) {
        const date = new Date(e);
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthString = monthNames[month];
        const formattedDate = `${monthString} ${day}, ${year}`;

        return (formattedDate);
    }

    const EmployeeOfTheMonthHandler = (e) => {
        setEmployeeOfTheMonth(e);
    };

    return (
        <div>
            {
                loading ?
                    <Loader /> :
                    selectedReport ? (
                        <div>
                            <h4 className="text-center">{selectedReport.name} - {formatDate(selectedReport.createdAt)}</h4>
                            <hr />
                            <Row className="text-center">
                                <h3>Morale Level</h3>
                                <MoodBarView selectedFace={selectedReport.moraleLevel} />
                                <p className="px-3 py-2">{selectedReport.moraleComment}</p>
                                <h3>Stress Level</h3>
                                <MoodBarView selectedFace={selectedReport.stressLevel} />
                                <p className="px-3 py-2">{selectedReport.stressComment}</p>
                                <h3>Workload Level</h3>
                                <MoodBarView selectedFace={selectedReport.workloadLevel} />
                                <p className="px-3 py-2">{selectedReport.workloadComment}</p>
                                <h3 className="px-3">High Point</h3>
                                <p className="px-3 py-2">{selectedReport.highComment}</p>
                                <h3 className="px-3">Low Point</h3>
                                <p className="px-3 py-2">{selectedReport.lowComment}</p>
                                {
                                    (selectedReport.extraComment !== "" && selectedReport.extraComment !== null) &&
                                    <div>
                                        <h3 className="px-3">Extra Comment</h3>
                                        <p className="px-3 py-2">{selectedReport.extraComment}</p>
                                    </div>
                                }
                                {
                                    (selectedReport.nominatedEmployeeName !== "" && selectedReport.nominatedEmployeeName !== null) &&
                                    <div>
                                        <h3 className="px-3">Nominated Employee</h3>
                                        <p className="px-3 py-2">{selectedReport.nominatedEmployeeName}</p>
                                    </div>
                                }
                                {
                                    (selectedReport.nominatedText !== "" && selectedReport.nominatedText !== null) &&
                                    <div>
                                        <h3 className="px-3">Comment about the nominated employee.</h3>
                                        <p className="px-3 py-2">{selectedReport.nominatedText}</p>
                                    </div>
                                }
                            </Row>
                            <div className="col-md-12 text-center">
                                <Button onClick={backButton} className="pe-auto w-25 btn-secondary text-center"> Go Back</Button>
                            </div>
                        </div>
                    ) : (
                        (selectedUser || selectedDate) ? (
                            <div>
                                {(loadingReports || loadingUser) ? <Loader /> :
                                    <div>
                                        {selectedUser && <h4>{user.name}</h4>}
                                        {selectedDate && <h4>Mood Report #{selectedDate}</h4>}
                                        <p className="text-justify">Red and green dots represent whether or not there is a response in that optional field.  <br />Scale 1-5 (1=worst, 5=best)</p>
                                        <Table striped bordered hover responsive className="table-sm">
                                            <thead>
                                                <tr>
                                                    <th role="button" onClick={() => filterHandler("name")}>
                                                        Name
                                                    </th>
                                                    <th role="button" onClick={() => filterHandler("createdAt")}>
                                                        Date
                                                    </th>
                                                    <th role="button" onClick={() => filterHandler("moraleLevel")}>
                                                        Morale
                                                    </th>
                                                    <th role="button" onClick={() => filterHandler("stressLevel")}>
                                                        Stress
                                                    </th>
                                                    <th role="button" onClick={() => filterHandler("workloadLevel")}>
                                                        Workload
                                                    </th>
                                                    <th role="button" onClick={() => filterHandler("moraleComment")}>
                                                        Morale
                                                    </th>
                                                    <th>
                                                        Stress
                                                    </th>
                                                    <th>
                                                        Workload
                                                    </th>
                                                    <th>
                                                        Extra
                                                    </th>
                                                    <th style={{ width: "120px", whiteSpace: "nowrap" }}></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {moodReports
                                                    .filter((a) => a.manager.user === user.id || dropdownItem === "All Reports" || (a.manager.empType == userInfo.permissions.empType && userInfo.permissions.isCepDeptManager))
                                                    .sort(function (a, b) {
                                                        if (myFilter === "moraleLevel" || myFilter === "stressLevel" ||myFilter === "workloadLevel") {
                                                          if (filterDirection === true) {
                                                            return a[myFilter] - b[myFilter];
                                                          } else {
                                                            return b[myFilter] - a[myFilter];
                                                          }
                                                        } else {
                                                          if (filterDirection === true) {
                                                            return a[myFilter]
                                                              .toString()
                                                              .localeCompare(b[myFilter]);
                                                          } else {
                                                            return b[myFilter]
                                                              .toString()
                                                              .localeCompare(a[myFilter]);
                                                          }
                                                        }
                                                      })
                                                    .map((moodReport) => (
                                                        <tr key={moodReport._id}>
                                                            <td>{moodReport.name}</td>
                                                            <td>{moodReport.createdAt.substring(0, moodReport.createdAt.indexOf('T'))}</td>
                                                            <td>{moodReport.moraleLevel}</td>
                                                            <td>{moodReport.stressLevel}</td>
                                                            <td>{moodReport.workloadLevel}</td>
                                                            {(moodReport.moraleComment === "" || moodReport.moraleComment === null) ? (
                                                                <td style={{ color: "red", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            ) : (
                                                                <td style={{ color: "green", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            )}
                                                            {(moodReport.stressComment === "" || moodReport.stressComment === null) ? (
                                                                <td style={{ color: "red", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            ) : (
                                                                <td style={{ color: "green", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            )}
                                                            {(moodReport.workloadComment === "" || moodReport.workloadComment === null) ? (
                                                                <td style={{ color: "red", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            ) : (
                                                                <td style={{ color: "green", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            )}
                                                            {(moodReport.extraComment === "" || moodReport.extraComment === null) ? (
                                                                <td style={{ color: "red", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            ) : (
                                                                <td style={{ color: "green", textAlign: "center" }}>
                                                                    <i className="fas fa-circle"></i>
                                                                </td>
                                                            )}
                                                            <td className="d-flex justify-content-around">
                                                                <Button
                                                                    variant="secondary"
                                                                    className="btn-sm px-2 py-0 m-0"
                                                                    onClick={(e) => (setSelectedReport(moodReport))}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col md={5}>
                                                <h4>Employee of the month votes:</h4>
                                                <p className="fst-italic">Click on the employee's name to reveal comments about them!</p>

                                                <Table striped bordered hover responsive className="table-sm w-25">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: "200px", whiteSpace: "nowrap" }}>
                                                                Name
                                                            </th>
                                                            <th style={{ minWidth: "100px", whiteSpace: "nowrap" }}>
                                                                Votes
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            topEmployee(moodReports)
                                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                                .sort((a, b) => b.totalVotes - a.totalVotes)
                                                                .map((person) => (
                                                                    <tr style={{ cursor: "pointer" }} onClick={(e) => EmployeeOfTheMonthHandler(person)}>
                                                                        <td>{person.name}</td>
                                                                        <td>{person.totalVotes}</td>
                                                                    </tr>
                                                                )
                                                                )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col md={7}>
                                            { employeeOfTheMonth && (
                                                <div>
                                                <h4>Comments about {employeeOfTheMonth.name}:</h4>
                                                {
                                                    moodReports
                                                    .filter((moodReport) => moodReport.nominatedText !== null && moodReport.nominatedText != "")
                                                    .filter((moodReport) => moodReport.nominatedEmployeeName === employeeOfTheMonth.name)
                                                    .map((moodReport) => <p>"{moodReport.nominatedText}"</p>)
                                                }
                                                </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="col-md-12 text-center">
                                            <Button onClick={backButton} className="pe-auto w-25 btn-secondary text-center"> Go Back</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        ) : byUser ?
                            (
                                <Table striped bordered hover responsive className="table-sm">
                                    <thead>
                                        <tr>
                                            <th role="button">
                                                Name
                                            </th>
                                            <th style={{ width: "120px", whiteSpace: "nowrap" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {users
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((user) => (
                                                <tr key={user.id}>
                                                    <td>{user.name}</td>
                                                    <td className="d-flex justify-content-around">
                                                        <Button
                                                            variant="secondary"
                                                            className="btn-sm px-2 py-0 m-0"
                                                            onClick={(e) => (setSelectedUser(user._id))}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            ) : loadingDates ? <Loader /> : (
                                <Table striped bordered hover responsive className="table-sm">
                                    <thead>
                                        <tr>
                                            <th role="button">
                                                Date Range
                                            </th>
                                            <th style={{ width: "120px", whiteSpace: "nowrap" }}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {/* (moodDate >= lastWeekStart && moodDate <= thisWeekEnd) */}
                                        {moodDates
                                            .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))
                                            .map((moodDate) => (
                                                <tr key={moodDate._id}>
                                                    {(Date.parse(moodDate.startDate) >= lastWeekStart && Date.parse(moodDate.startDate) <= thisWeekEnd) ? <td className="fw-bold fst-italic px-3">{moodDate.startDate} to {moodDate.endDate}</td> : <td>{moodDate.startDate} to {moodDate.endDate}</td>}
                                                    <td className="d-flex justify-content-around">
                                                        <Button
                                                            variant="secondary"
                                                            className="btn-sm px-2 py-0 m-0"
                                                            onClick={(e) => (setSelectedDate(moodDate._id))}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            ))}
        </div>
    )
}

export default MoodDrillDown
import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getUserDetails,
  setMySettings,
  updateUserProfile,
} from "../actions/userActions";
import { Link, useNavigate } from "react-router-dom";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { listEmployeeTypes } from "../actions/checklistActions";

function ProfileScreen({ history }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [dept, setDept] = useState("");
  const [empTypeMsg, setEmpTypeMsg] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  const navigate = useNavigate();

  //This seems to not be firing until the page is refreshed, both pulling up the page and clicking the button do not fire useEffect
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (
        !user ||
        !user.name ||
        userInfo._id !== user._id ||
        success ||
        userInfo._id !== user._id
      ) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listEmployeeTypes());
        setDept(userInfo.permissions.empType);
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, navigate, history, userInfo, user, success, userInfo.permissions.empType]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name: name,
          email: email,
          password: password,
        })
      );
      setMessage("");
    }
  };

  const submitExtraHandler = (e) => {
    e.preventDefault();
    if (dept !== "" && dept !== undefined) {
        dispatch(setMySettings({ empType: dept }));
        setEmpTypeMsg("")
      } else {
        setEmpTypeMsg("Select an employee type!");
      }
  };

  return (
    <Row>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/">
          Home
        </Link>{" "}
        / Profile
      </h5>
      <Col md={4}>
        <h2>{userDetails.user.name}</h2>
        <h4 className="mt-3">Edit Profile</h4>
        {message && <Message variant="danger">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              required
              type="name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="passwordConfirm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="my-3">
            Update
          </Button>
        </Form>
      </Col>
      <Col md={1}></Col>
      <Col md={7}>
        <h2>Permissions/Settings</h2>
        <h4 className="mt-3">Edit Settings</h4>
        {userInfo.permissions.conequip_employee === true ? (
          <div>
            <Form.Label>Department</Form.Label>
            <Form.Control
              as="select"
              value={dept}
              onChange={(e) => setDept(e.target.value)}
              required
              style={{ width: "45%", minWidth: "150px" }}
            >
              <option>
                {userInfo.permissions.empType != null
                  ? userInfo.permissions.empType
                  : "Select..."}
              </option>
              {empTypes &&
                empTypes
                  .sort((a, b) => a.empType.localeCompare(b.empType))
                  .filter((a) => a.empType !== "Everyone")
                  .map((empType) => (
                    <option key={empType._id} value={empType._id}>
                      {empType.empType}
                    </option>
                  ))}
            </Form.Control>
            {empTypeMsg && (
              <div className="my-4">
                <Message variant="danger">{empTypeMsg}</Message>
              </div>
            )}
            <Button
              onClick={(e) => submitExtraHandler(e)}
              type="submit"
              variant="primary"
              className="my-3"
            >
              Update
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </Col>
    </Row>
  );
}

export default ProfileScreen;

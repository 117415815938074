import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Accordion } from "react-bootstrap";
import { listVersions } from "../actions/versionActions";

function VersionsScreen() {
  const dispatch = useDispatch();

  var parse = require('html-react-parser');

  const versionList = useSelector((state) => state.versionList);
  const { versions } = versionList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(listVersions());
  }, [dispatch]);

  const convertTime = (e) => {
    if (e === "" || e === null) {
      return "";
    }
    e = new Date(e);
    return e.toLocaleString([], {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  return (
    <div>
      <Row className="my-2">
        <h2>Versions</h2>
        <Accordion>
          {versions &&
            versions
            .sort((a, b) => b._id - a._id)
            .map((version, index) => (
              <Accordion.Item eventKey={version._id} key={version._id}>
                <Accordion.Header>
                  {version.title} - {convertTime(version.createdAt)}
                </Accordion.Header>
                <Accordion.Body>{parse(version.text)}{userInfo.isAdmin && <span><Link style={{textDecoration: "none"}} to={`/version/${version._id}/edit`}>Edit</Link></span>}</Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </Row>
    </div>
  );
}

export default VersionsScreen;

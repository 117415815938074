import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listUsers, deleteUser } from "../actions/userActions";

function UserListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [myFilter, setMyFilter] = useState("id");
  const [filterDirection, setFilterDirection] = useState(true);
  const [activeFilter, setActiveFilter] = useState("");

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this user?  If you want deactivate the user please navigate to the 'Edit User' page.")) {
      dispatch(deleteUser(id));
    }
  };

  const filterHandler = (filterName) => {
    if (filterName === myFilter) {
      setFilterDirection(!filterDirection);
    } else {
      setMyFilter(filterName);
      setFilterDirection(true);
    }
  };

  return (
    <div>
      <h5 className="my-4">Users</h5>
      <Row>
        <Col md={8}>
          <Row>
            <Col>
              <h2>All Users</h2>
            </Col>
            <Col className="text-end">
              <LinkContainer to="/app/onboarding/new-user">
                <Button className="my-3">
                  <i className="fas fa-plus"></i> New User
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Table striped bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th
                    style={{ width: "65px", whiteSpace: "nowrap" }}
                    role="button"
                    onClick={() => filterHandler("id")}
                    className="text-center"
                  >
                    ID
                  </th>
                  <th
                    style={{ width: "65px", whiteSpace: "nowrap" }}
                    role="button"
                    onClick={() => filterHandler("isAdmin")}
                    className="text-center"
                  >
                    ADMIN
                  </th>
                  <th role="button" onClick={() => filterHandler("name")}>
                    NAME
                  </th>
                  <th role="button" onClick={() => filterHandler("email")}>
                    EMAIL
                  </th>
                  <th style={{ width: "120px", whiteSpace: "nowrap" }}></th>
                </tr>
              </thead>

              <tbody>
                {users
                  .sort(function (a, b) {
                    if (myFilter === "id") {
                      if (filterDirection === true) {
                        return a[myFilter] - b[myFilter] || a.id - b.id;
                      } else {
                        return b[myFilter] - a[myFilter] || a.id - b.id;
                      }
                    } else {
                      if (filterDirection === true) {
                        return ("" + a[myFilter])
                          .toString()
                          .localeCompare(b[myFilter]);
                      } else {
                        return ("" + b[myFilter])
                          .toString()
                          .localeCompare(a[myFilter]);
                      }
                    }
                  })
                  .filter((a) => a.deactivated !== activeFilter)
                  .filter((a) => a.name.toLowerCase().includes(searchName.toLowerCase()))
                  .map((user) => (
                    <tr key={user._id}>
                      <td className="text-center">{user._id}</td>
                      <td className="text-center">
                        {user.isAdmin ? (
                          <i
                            className="fas fa-check"
                            style={{ color: "green" }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-check"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td className="d-flex justify-content-around">
                        <LinkContainer
                          to={`/frontend-admin/user/${user._id}/edit`}
                        >
                          <Button
                            variant="secondary"
                            className="btn-sm px-2 py-0 m-0"
                          >
                            <i className="fas fa-edit"></i>
                          </Button>
                        </LinkContainer>
                        <Button
                          variant="danger"
                          className="btn-sm px-2 py-0 m-0"
                          onClick={() => deleteHandler(user._id)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                        <LinkContainer to={`/app/onboarding/${user._id}`}>
                          <Button
                            variant="secondary"
                            className="btn-sm px-2 py-0 m-0"
                          >
                            <i className="fa-solid fa-list"></i>
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
        <Col md={1}></Col>
        <Col md={3}>
          <h2>Filters</h2>
          <h5>Search by Name:</h5>
          <Form.Group controlId="name">
            <Form.Control
              required
              type="name"
              placeholder="Enter Name"
              onChange={(e) => setSearchName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <h5 className="mt-3">
            Active/Deactivated:
          </h5>
          <Form>
          <div key={`inline-radio`} className="mb-3">
          <Form.Check
            defaultChecked
            inline
            label="All"
            name="breakType"
            type={"radio"}
            id={`Lunch`}
            onChange={(e) => setActiveFilter("")}
          />
          <Form.Check
            inline
            label="Active"
            name="breakType"
            type={"radio"}
            id={`Break`}
            onChange={(e) => setActiveFilter(true)}
          />
          <Form.Check
            inline
            label="Deactivated"
            name="breakType"
            type={"radio"}
            id={`Custom`}
            onChange={(e) => setActiveFilter(false)}
          />
        </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default UserListScreen;

export const BREAK_INFO_GET_REQUEST = 'BREAK_INFO_GET_REQUEST'
export const BREAK_INFO_GET_SUCCESS = 'BREAK_INFO_GET_SUCCESS'
export const BREAK_INFO_GET_FAIL = 'BREAK_INFO_GET_FAIL'
export const BREAK_INFO_GET_RESET = 'BREAK_INFO_GET_RESET'
export const BREAK_INFO_GET_OTHER_REQUEST = 'BREAK_INFO_GET_OTHER_REQUEST'
export const BREAK_INFO_GET_OTHER_SUCCESS = 'BREAK_INFO_GET_OTHER_SUCCESS'
export const BREAK_INFO_GET_OTHER_FAIL = 'BREAK_INFO_GET_OTHER_FAIL'
export const BREAK_INFO_GET_OTHER_RESET = 'BREAK_INFO_GET_OTHER_RESET'
export const BREAK_INFO_UPDATE_REQUEST = 'BREAK_INFO_UPDATE_REQUEST'
export const BREAK_INFO_UPDATE_SUCCESS = 'BREAK_INFO_UPDATE_SUCCESS'
export const BREAK_INFO_UPDATE_FAIL = 'BREAK_INFO_UPDATE_FAIL'
export const BREAK_INFO_UPDATE_RESET = 'BREAK_INFO_UPDATE_RESET'
export const BREAK_INFO_UPDATE_OTHER_REQUEST = 'BREAK_INFO_UPDATE_OTHER_REQUEST'
export const BREAK_INFO_UPDATE_OTHER_SUCCESS = 'BREAK_INFO_UPDATE_OTHER_SUCCESS'
export const BREAK_INFO_UPDATE_OTHER_FAIL = 'BREAK_INFO_UPDATE_OTHER_FAIL'
export const BREAK_INFO_UPDATE_OTHER_RESET = 'BREAK_INFO_UPDATE_OTHER_RESET'
export const BREAK_INFO_GET_ALL_REQUEST = 'BREAK_INFO_GET_ALL_REQUEST'
export const BREAK_INFO_GET_ALL_SUCCESS = 'BREAK_INFO_GET_ALL_SUCCESS'
export const BREAK_INFO_GET_ALL_FAIL = 'BREAK_INFO_GET_ALL_FAIL'
export const BREAK_INFO_GET_ALL_RESET = 'BREAK_INFO_GET_ALL_RESET'
export const BREAK_INFO_CREATE_REQUEST = 'BREAK_INFO_CREATE_REQUEST'
export const BREAK_INFO_CREATE_SUCCESS = 'BREAK_INFO_CREATE_SUCCESS'
export const BREAK_INFO_CREATE_FAIL = 'BREAK_INFO_CREATE_FAIL'
export const BREAK_INFO_CREATE_RESET = 'BREAK_INFO_CREATE_RESET'
export const BREAK_INFO_DELETE_REQUEST = 'BREAK_INFO_DELETE_REQUEST'
export const BREAK_INFO_DELETE_SUCCESS = 'BREAK_INFO_DELETE_SUCCESS'
export const BREAK_INFO_DELETE_FAIL = 'BREAK_INFO_DELETE_FAIL'
export const BREAK_INFO_DELETE_RESET = 'BREAK_INFO_DELETE_RESET'
export const BREAK_SOCKET_ALL_REQUEST = 'BREAK_SOCKET_ALL_REQUEST'
export const BREAK_SOCKET_ALL_SUCCESS = 'BREAK_SOCKET_ALL_SUCCESS'
export const BREAK_SOCKET_ALL_FAIL = 'BREAK_SOCKET_ALL_FAIL'
export const BREAK_SOCKET_ALL_RESET = 'BREAK_SOCKET_ALL_RESET'
export const BREAK_SOCKET_ALL_UPDATE = 'BREAK_SOCKET_ALL_UPDATE'
export const BREAK_TYPE_GET_REQUEST = 'BREAK_TYPE_GET_REQUEST'
export const BREAK_TYPE_GET_SUCCESS = 'BREAK_TYPE_GET_SUCCESS'
export const BREAK_TYPE_GET_FAIL = 'BREAK_TYPE_GET_FAIL'
export const BREAK_TYPE_GET_RESET = 'BREAK_TYPE_GET_RESET'
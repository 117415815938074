import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getEtlTables } from '../actions/etlActions';

function EtlTables({data}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEtlTables())
  }, [dispatch])
  

  return (
    <div>
        <h2>{data.name} Overview</h2>
        <h3>{data.description}</h3>
        <h4>{data.totalTables.length}</h4>
    </div>
  )
}

export default EtlTables
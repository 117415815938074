export const CHECKLIST_DEPT_GET_ALL_REQUEST = 'CHECKLIST_DEPT_GET_ALL_REQUEST'
export const CHECKLIST_DEPT_GET_ALL_SUCCESS = 'CHECKLIST_DEPT_GET_ALL_SUCCESS'
export const CHECKLIST_DEPT_GET_ALL_FAIL = 'CHECKLIST_DEPT_GET_ALL_FAIL'
export const CHECKLIST_DEPT_GET_ALL_RESET = 'CHECKLIST_DEPT_GET_ALL_RESET'

export const CHECKLIST_DEPT_POST_REQUEST = 'CHECKLIST_DEPT_POST_REQUEST'
export const CHECKLIST_DEPT_POST_SUCCESS = 'CHECKLIST_DEPT_POST_SUCCESS'
export const CHECKLIST_DEPT_POST_FAIL = 'CHECKLIST_DEPT_POST_FAIL'
export const CHECKLIST_DEPT_POST_RESET = 'CHECKLIST_DEPT_POST_RESET'

export const CHECKLIST_DEPT_DELETE_REQUEST = 'CHECKLIST_DEPT_DELETE_REQUEST'
export const CHECKLIST_DEPT_DELETE_SUCCESS = 'CHECKLIST_DEPT_DELETE_SUCCESS'
export const CHECKLIST_DEPT_DELETE_FAIL = 'CHECKLIST_DEPT_DELETE_FAIL'
export const CHECKLIST_DEPT_DELETE_RESET = 'CHECKLIST_DEPT_DELETE_RESET'

export const CHECKLIST_DEPT_PUT_REQUEST = 'CHECKLIST_DEPT_PUT_REQUEST'
export const CHECKLIST_DEPT_PUT_SUCCESS = 'CHECKLIST_DEPT_PUT_SUCCESS'
export const CHECKLIST_DEPT_PUT_FAIL = 'CHECKLIST_DEPT_PUT_FAIL'
export const CHECKLIST_DEPT_PUT_RESET = 'CHECKLIST_DEPT_PUT_RESET'

export const CHECKLIST_DEPT_GET_REQUEST = 'CHECKLIST_DEPT_GET_REQUEST'
export const CHECKLIST_DEPT_GET_SUCCESS = 'CHECKLIST_DEPT_GET_SUCCESS'
export const CHECKLIST_DEPT_GET_FAIL = 'CHECKLIST_DEPT_GET_FAIL'
export const CHECKLIST_DEPT_GET_RESET = 'CHECKLIST_DEPT_GET_RESET'

export const CHECKLIST_TODO_GET_ALL_REQUEST = 'CHECKLIST_TODO_GET_ALL_REQUEST'
export const CHECKLIST_TODO_GET_ALL_SUCCESS = 'CHECKLIST_TODO_GET_ALL_SUCCESS'
export const CHECKLIST_TODO_GET_ALL_FAIL = 'CHECKLIST_TODO_GET_ALL_FAIL'
export const CHECKLIST_TODO_GET_ALL_RESET = 'CHECKLIST_TODO_GET_ALL_RESET'

export const CHECKLIST_TODO_POST_REQUEST = 'CHECKLIST_TODO_POST_REQUEST'
export const CHECKLIST_TODO_POST_SUCCESS = 'CHECKLIST_TODO_POST_SUCCESS'
export const CHECKLIST_TODO_POST_FAIL = 'CHECKLIST_TODO_POST_FAIL'
export const CHECKLIST_TODO_POST_RESET = 'CHECKLIST_TODO_POST_RESET'

export const CHECKLIST_TODO_DELETE_REQUEST = 'CHECKLIST_TODO_DELETE_REQUEST'
export const CHECKLIST_TODO_DELETE_SUCCESS = 'CHECKLIST_TODO_DELETE_SUCCESS'
export const CHECKLIST_TODO_DELETE_FAIL = 'CHECKLIST_TODO_DELETE_FAIL'
export const CHECKLIST_TODO_DELETE_RESET = 'CHECKLIST_TODO_DELETE_RESET'

export const CHECKLIST_TODO_PUT_REQUEST = 'CHECKLIST_TODO_PUT_REQUEST'
export const CHECKLIST_TODO_PUT_SUCCESS = 'CHECKLIST_TODO_PUT_SUCCESS'
export const CHECKLIST_TODO_PUT_FAIL = 'CHECKLIST_TODO_PUT_FAIL'
export const CHECKLIST_TODO_PUT_RESET = 'CHECKLIST_TODO_PUT_RESET'

export const CHECKLIST_TODO_GET_REQUEST = 'CHECKLIST_TODO_GET_REQUEST'
export const CHECKLIST_TODO_GET_SUCCESS = 'CHECKLIST_TODO_GET_SUCCESS'
export const CHECKLIST_TODO_GET_FAIL = 'CHECKLIST_TODO_GET_FAIL'
export const CHECKLIST_TODO_GET_RESET = 'CHECKLIST_TODO_GET_RESET'

export const CHECKLIST_NEW_USER_REQUEST = 'CHECKLIST_NEW_USER_REQUEST'
export const CHECKLIST_NEW_USER_SUCCESS = 'CHECKLIST_NEW_USER_SUCCESS'
export const CHECKLIST_NEW_USER_FAIL = 'CHECKLIST_NEW_USER_FAIL'
export const CHECKLIST_NEW_USER_RESET = 'CHECKLIST_NEW_USER_RESET'

export const CHECKLIST_GET_USERS_REQUEST = 'CHECKLIST_GET_USERS_REQUEST'
export const CHECKLIST_GET_USERS_SUCCESS = 'CHECKLIST_GET_USERS_SUCCESS'
export const CHECKLIST_GET_USERS_FAIL = 'CHECKLIST_GET_USERS_FAIL'
export const CHECKLIST_GET_USERS_RESET = 'CHECKLIST_GET_USERS_RESET'

export const CHECKLIST_GET_USER_TASKS_REQUEST = 'CHECKLIST_GET_USER_TASKS_REQUEST'
export const CHECKLIST_GET_USER_TASKS_SUCCESS = 'CHECKLIST_GET_USER_TASKS_SUCCESS'
export const CHECKLIST_GET_USER_TASKS_FAIL = 'CHECKLIST_GET_USER_TASKS_FAIL'
export const CHECKLIST_GET_USER_TASKS_RESET = 'CHECKLIST_GET_USER_TASKS_RESET'

export const CHECKLIST_COMPLETE_TASK_REQUEST = 'CHECKLIST_COMPLETE_TASK_REQUEST'
export const CHECKLIST_COMPLETE_TASK_SUCCESS = 'CHECKLIST_COMPLETE_TASK_SUCCESS'
export const CHECKLIST_COMPLETE_TASK_FAIL = 'CHECKLIST_COMPLETE_TASK_FAIL'
export const CHECKLIST_COMPLETE_TASK_RESET = 'CHECKLIST_COMPLETE_TASK_RESET'

export const CHECKLIST_COMPLETE_ALL_TASKS_REQUEST = 'CHECKLIST_COMPLETE_ALL_TASKS_REQUEST'
export const CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS = 'CHECKLIST_COMPLETE_ALL_TASKS_SUCCESS'
export const CHECKLIST_COMPLETE_ALL_TASKS_FAIL = 'CHECKLIST_COMPLETE_ALL_TASKS_FAIL'
export const CHECKLIST_COMPLETE_ALL_TASKS_RESET = 'CHECKLIST_COMPLETE_ALL_TASKS_RESET'

export const CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST = 'CHECKLIST_TASK_WEIGHT_UPDATE_REQUEST'
export const CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS = 'CHECKLIST_TASK_WEIGHT_UPDATE_SUCCESS'
export const CHECKLIST_TASK_WEIGHT_UPDATE_FAIL = 'CHECKLIST_TASK_WEIGHT_UPDATE_FAIL'
export const CHECKLIST_TASK_WEIGHT_UPDATE_RESET = 'CHECKLIST_TASK_WEIGHT_UPDATE_RESET'

export const CHECKLIST_OFFBOARD_REQUEST = 'CHECKLIST_OFFBOARD_REQUEST'
export const CHECKLIST_OFFBOARD_SUCCESS = 'CHECKLIST_OFFBOARD_SUCCESS'
export const CHECKLIST_OFFBOARD_FAIL = 'CHECKLIST_OFFBOARD_FAIL'
export const CHECKLIST_OFFBOARD_RESET = 'CHECKLIST_OFFBOARD_RESET'

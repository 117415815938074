import axios from "axios";
import {
  APPLICATION_LIST_REQUEST,
  APPLICATION_LIST_SUCCESS,
  APPLICATION_LIST_FAIL,
  APPLICATION_DETAILS_REQUEST,
  APPLICATION_DETAILS_SUCCESS,
  APPLICATION_DETAILS_FAIL,
  APPLICATION_DELETE_REQUEST,
  APPLICATION_DELETE_SUCCESS,
  APPLICATION_DELETE_FAIL,
  APPLICATION_CREATE_REQUEST,
  APPLICATION_CREATE_SUCCESS,
  APPLICATION_CREATE_FAIL,
  APPLICATION_UPDATE_REQUEST,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_UPDATE_FAIL,
} from "../constants/applicationConstants";

export const listApplications =
  (keyword = "", page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: APPLICATION_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/applications/?keyword=${keyword}&page=${page}`,
        config
      );

      dispatch({
        type: APPLICATION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPLICATION_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listApplicationDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: APPLICATION_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/applications/${id}`);

    dispatch({
      type: APPLICATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteApplication = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLICATION_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/applications/delete/${id}/`, config);

    dispatch({
      type: APPLICATION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createApplication = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLICATION_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/applications/create/`, {}, config);

    dispatch({
      type: APPLICATION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateApplication =
  (application) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPLICATION_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/applications/update/${application._id}/`,
        application,
        config
      );

      dispatch({
        type: APPLICATION_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: APPLICATION_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPLICATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

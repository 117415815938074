import React, { useEffect, useState } from "react";
import Select from 'react-select';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createMoodPost, getMoodDates } from "../actions/moodActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import MoodBar from "../components/MoodBar";
import MoodDrillDown from "../components/MoodDrillDown";
import { listUsers } from "../actions/userActions";

function MoodReportScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const checkAllTasks = useSelector((state) => state.checkAllTasks);
  const { success: successAll } = checkAllTasks;

  const moodDatesList = useSelector((state) => state.moodDatesList);
  const { moodDates, loading } = moodDatesList;

  const userList = useSelector((state) => state.userList);
  const { users: electUsers } = userList;

  const [morale, setMorale] = useState()
  const [moraleComments, setMoraleComments] = useState()
  const [stress, setStress] = useState()
  const [stressComments, setStressComments] = useState()
  const [workload, setWorkload] = useState()
  const [workloadComments, setWorkloadComments] = useState()
  const [highComments, setHighComments] = useState()
  const [lowComments, setLowComments] = useState()
  const [extraComments, setExtraComments] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [dropDownTitle, setDropDownTitle] = useState("Select Date Range ");
  const [timePeriod, setTimePeriod] = useState();
  const [isSumbit, setIsSubmit] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Home");
  const [byUser, setByUser] = useState(true);
  const [electedUser, setElectedUser] = useState();
  const [electedUserText, setElectedUserText] = useState();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.permissions.access_mood && !userInfo.isAdmin) {
      navigate("/");
    } else {
      dispatch(getMoodDates())
      dispatch(listUsers("cepUsers"))
    }
  }, [dispatch, navigate, userInfo, successAll]);

  const submitHandler = (e) => {
    e.preventDefault();
    setErrorMessage()
    let missingValues = []
    if (!morale || !stress || !workload || !highComments || !lowComments || dropDownTitle === "Select Date Range " || electedUser.id === userInfo.id) {
      if (!morale) {
        missingValues.push("Morale")
      }
      if (!stress) {
        missingValues.push("Stress")
      }
      if (!workload) {
        missingValues.push("Workload")
      }
      if (!highComments) {
        missingValues.push("Weekly High")
      }
      if (!lowComments) {
        missingValues.push("Weekly Low")
      }
      if (dropDownTitle === "Select Date Range ") {
        missingValues.push("Date Range")
      }
      var myErrorMessage = "Required fields missing: " + missingValues.join(', ') + "."
      if (electedUser.id === userInfo.id) {
        myErrorMessage = " Spread the love, vote for someone else."
      }
      setErrorMessage(myErrorMessage)
    } else {
      var myElectedUser = null
      if (electedUser) {
        if (electedUser.id !== userInfo.id) {
            myElectedUser = electedUser["id"]
        }
      } 
      dispatch(createMoodPost({
        timePeriod: timePeriod._id,
        moraleLevel: morale,
        moraleComment: moraleComments,
        stressLevel: stress,
        stressComment: stressComments,
        workloadLevel: workload,
        workloadComment: workloadComments,
        highComment: highComments,
        lowComment: lowComments,
        extraComment: extraComments,
        nominatedEmployee: myElectedUser,
        nominatedText: electedUserText
      }))
      setIsSubmit(true)
    }
  };

  const backHandler = (e) => {
    e.preventDefault();
    setErrorMessage()
    setDropDownTitle("Select Date Range ")
    setMorale()
    setMoraleComments()
    setStress()
    setStressComments()
    setWorkload()
    setWorkloadComments()
    setHighComments()
    setLowComments()
    setExtraComments()
    setErrorMessage()
    setDropDownTitle("Select Date Range ");
    setTimePeriod();
    setIsSubmit(false);
    setSelectedMenuItem("Home");
    setByUser(true);
  };

  return (
    <div>
      <h5 className="my-4">Mood Report</h5>
      {
        (userInfo.permissions.mood_admin || userInfo.permissions.isCepManager || userInfo.permissions.isCepDeptManager) &&
        <Row>
          <Col>
            <ul className="d-flex mx-0 px-0">
              <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("Home")}>
                {selectedMenuItem === "Home" ?
                  (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>Home</p>) :
                  (<p>Home</p>)}
              </li>
              {(userInfo.permissions.isCepManager || userInfo.permissions.isCepDeptManager) &&
                <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("My Reports")}>
                  {selectedMenuItem === "My Reports" ?
                    (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>My Reports</p>) :
                    (<p>My Reports</p>)}
                </li>
              } {(userInfo.permissions.mood_admin) &&
                <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("All Reports")}>
                  {selectedMenuItem === "All Reports" ?
                    (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>All Reports</p>) :
                    (<p>All Reports</p>)}
                </li>
              }
            </ul>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            {selectedMenuItem !== "Home" &&
              <Form.Check
                className="mt-2"
                type="switch"
                id="byUserSwitch"
                label={byUser ? "By User" : "By Report"}
                defaultChecked={byUser}
                onChange={(e) => setByUser(e.target.checked)}
              />
            }
          </Col>
        </Row>
      }
      {loading ?
        <Loader /> :
        isSumbit ? (
          <Row className="text-center">
            <h2>Thanks for the feedback, {userInfo.name}.</h2>
            <h5>We appreciate the time you take to let us know how you are feeling.</h5>
          </Row>
        ) : selectedMenuItem === "Home" ? (
          moodDates.length > 0 ? (
            dropDownTitle !== "Select Date Range " ? (
              <Row className="text-center">
                <h6 className="text-start my-0 pb-2" style={{ cursor: "pointer" }} onClick={backHandler}><u><em>Back to Dates</em></u></h6>
                <h2>Welcome Back, {userInfo.name}.</h2>
                <h5>Let your leader know where you're winning and struggling in less than 10 minutes.</h5>
                <div style={{ maxWidth: "1000px", margin: "auto" }}>
                  <h3 className="mt-4">How was your morale?</h3>
                  <MoodBar selectedFace={morale} setLevel={setMorale} />
                  {morale &&
                    <div className="form-floating p-0 my-3">
                      <textarea onChange={(e) => setMoraleComments(e.target.value)} style={{ height: "85px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                      <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">Would you like to add any comments about why you rated your morale this way? * Optional</label>
                    </div>
                  }
                </div>
                <div style={{ maxWidth: "1000px", margin: "auto" }} className="text-center">
                  <h3 className="mt-4">How was your stress?</h3>
                  <MoodBar selectedFace={stress} setLevel={setStress} />
                  {stress &&
                    <div className="form-floating p-0 my-3">
                      <textarea onChange={(e) => setStressComments(e.target.value)} style={{ height: "85px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                      <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">Would you like to add any comments about why you rated your stress this way? * Optional</label>
                    </div>
                  }
                </div>
                <div className="d-flex justify-content-center flex-column" style={{ maxWidth: "1000px", margin: "auto" }}>
                  <h3 className="mt-4">How was your workload?</h3>
                  <MoodBar selectedFace={workload} setLevel={setWorkload} />
                  {workload &&
                    <div className="form-floating p-0 my-3">
                      <textarea onChange={(e) => setWorkloadComments(e.target.value)} style={{ height: "85px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                      <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">Would you like to add any comments about why you rated your workload this way? * Optional</label>
                    </div>
                  }
                </div>
                <h3 className="mt-3">What was your high?</h3>
                <div className="form-floating p-0 my-3">
                  <textarea onChange={(e) => setHighComments(e.target.value)} style={{ height: "85px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                  <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">What was your personal or professional high? What's the one thing you accomplished at work?</label>
                </div>
                <h3 className="mt-3">What was your low?</h3>
                <div className="form-floating p-0 my-3">
                  <textarea onChange={(e) => setLowComments(e.target.value)} style={{ height: "85px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                  <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">What was your personal or professional low?</label>
                </div>
                <h3 className="mt-3">Anything Else?</h3>
                <div className="form-floating p-0 my-3">
                  <textarea onChange={(e) => setExtraComments(e.target.value)} style={{ height: "100px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                  <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">Is there anything else you would like to share with your leader? * Optional</label>
                </div>

                <div className="p-2 w-25">

                </div>
                {timePeriod.isEmployeeOfTheMonthReport && <div>
                  <h3 className="mt-3">Who has been the best representation of ConEquip's core values?</h3>
                  <div className="d-flex justify-content-center align-items-center">
                    <Select onChange={setElectedUser} className="my-2 w-50 text-secondary text-start" options={electUsers} />
                  </div>
                  {electedUser &&
                    <div>
                      <h3 className="mt-3">Why did you select this person?</h3>
                      <div className="form-floating p-0 my-3">
                        <textarea onChange={(e) => setElectedUserText(e.target.value)} style={{ height: "100px", borderRadius: "5px" }} className="form-control floatingTextarea px-3 py-4 mt-1" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                        <label style={{ fontSize: "15px" }} className="p-3" htmlFor="floatingTextarea">Is there anything else you would like to share about this person? * Optional</label>
                      </div>
                    </div>
                  }
                </div>
                }
                {errorMessage &&
                  <Message variant="danger">{errorMessage}</Message>
                }
                <div>
                  <Button onClick={submitHandler} className="p-2 w-25 my-3">Send Report</Button>
                </div>
              </Row>
            ) : <div className="text-center">
              <h3 className="my-4">Select Date Range</h3>
              <Dropdown className="my-3 mb-4">
                <Dropdown.Toggle className="py-2 px-3">{dropDownTitle}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {moodDates &&
                    moodDates
                      .sort((a, b) => a.startDate.localeCompare(b.startDate))
                      .map((moodDate) => (
                        <Dropdown.Item
                          key={moodDate._id}

                          onClick={function (e) {
                            setDropDownTitle(moodDate.startDate + " to " + moodDate.endDate)
                            setTimePeriod(moodDate)
                          }}
                        >
                          {moodDate.startDate + " to " + moodDate.endDate}
                        </Dropdown.Item>
                      ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <Row className="text-center">
              <h2>Welcome Back {userInfo.name}.</h2>
              <h5>You're all set, thanks for keeping your leaders updated on how you feel.</h5>
            </Row>
          )) :
          selectedMenuItem === "My Reports" || selectedMenuItem === "All Reports" ? (
            <Row>
              <Col>
                <MoodDrillDown dropdownItem={selectedMenuItem} byUser={byUser} />
              </Col>
            </Row>
          ) : (
            <div>Please select a valid menu item.</div>
          )}
    </div>
  );
}

export default MoodReportScreen;

import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EtlDataSources from "../components/EtlDataSources";
import EtlFailed from "../components/EtlFailed";
import EtlOverview from "../components/EtlOverview";

function EtlScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [selectedMenuItem, setSelectedMenuItem] = useState("Overview");

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.permissions.access_etl && !userInfo.isAdmin) {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div>
      <h5 className="my-4">ETL Viewer</h5>
      <Row>
        <ul className="d-flex">
          <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("Overview")}>
            {selectedMenuItem === "Overview" ?
              (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>Overview</p>) :
              (<p>Overview</p>)}
          </li>
          
          <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("Drill Down")}>
              {selectedMenuItem === "Drill Down" ?
                (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>Drill Down (Don't use yet)</p>) :
                (<p>Drill Down (Don't use yet)</p>)}
          </li>

          <li className="pe-3" style={{ listStyleType: "none", cursor: "pointer" }} onClick={() => setSelectedMenuItem("Failed Steps")}>
            {selectedMenuItem === "Failed Steps" ?
              (<p style={{ color: "#FFFFFF", textDecoration: "underline" }}>Failed Steps</p>) :
              (<p>Failed Steps</p>)}
          </li>
          
        </ul>
      </Row>
      {
        selectedMenuItem === "Failed Steps" ?
          <EtlFailed /> :
          selectedMenuItem === "Drill Down" ?
            <EtlDataSources /> :
            <EtlOverview />
      }
    </div>
  );
}

export default EtlScreen;

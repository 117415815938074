import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Editor } from '@tinymce/tinymce-react';
import { getVersion, putVersion } from "../actions/versionActions";
import { VERSION_GET_RESET } from '../constants/versionConstants';

function VersionEditScreen() {
    const editorRef = useRef(null);
    const [title, setTitle] = useState("");
    const dispatch = useDispatch();
    const { id: versionId } = useParams();
    const navigate = useNavigate();

    const getVersionId = useSelector((state) => state.getVersionId);
    const { version } = getVersionId;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

   const submitHandler = () => {
     if (editorRef.current) {
       dispatch(putVersion(versionId, {title, text: editorRef.current.getContent()}))
       dispatch({type: VERSION_GET_RESET})
       navigate('/versions')
     }
   };

   useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    } else {
     if(version._id !== versionId) {
      dispatch(getVersion(versionId))
     }
    }
   }, [dispatch, navigate, userInfo, version.title, versionId, version._id])
   

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onboarding">
          Home
        </Link>
        {" / "}
        <Link className="productTitle text-white" to="/versions">
          Versions
        </Link>{" "}
        / {version.title}
      </h5>
      <Row>
        <Col lg={8} md={12} sm={12}>
          <Form.Group className="mb-3 mt-2" controlId="Title">
            <Form.Label style={{ fontSize: "16px" }}>Title:</Form.Label>
            <Form.Control
              defaultValue={version.title}
              type="Title"
              placeholder="Enter New Title"
              onChange={(e) => setTitle(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <div>
            <p className="m-0 pb-1">Details: </p>
            <Editor
              apiKey="xbp1d31s3ol9pmgxsz2zd1uu3ctmmhd901fe1orfhuqp4ruu"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={version.text}
              init={{
                height: 300,
                menubar: true,
                plugins: ["lists"],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help | ",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>

          <Button
            onClick={(e) => submitHandler()}
            type="submit"
            variant="primary"
            className="my-4"
          >
            Update
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default VersionEditScreen
import {
  APPLICATION_LIST_REQUEST,
  APPLICATION_LIST_SUCCESS,
  APPLICATION_LIST_FAIL,
  APPLICATION_DETAILS_REQUEST,
  APPLICATION_DETAILS_SUCCESS,
  APPLICATION_DETAILS_FAIL,
  APPLICATION_DELETE_REQUEST,
  APPLICATION_DELETE_SUCCESS,
  APPLICATION_DELETE_FAIL,
  APPLICATION_CREATE_REQUEST,
  APPLICATION_CREATE_SUCCESS,
  APPLICATION_CREATE_FAIL,
  APPLICATION_CREATE_RESET,
  APPLICATION_UPDATE_REQUEST,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_UPDATE_FAIL,
  APPLICATION_UPDATE_RESET,
} from "../constants/applicationConstants";

export const applicationListReducer = (
  state = { applications: [] },
  action
) => {
  switch (action.type) {
    case APPLICATION_LIST_REQUEST:
      return { loading: true, applications: [] };
    case APPLICATION_LIST_SUCCESS:
      return {
        loading: false,
        applications: action.payload.applications,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case APPLICATION_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const applicationDetailsReducer = (
  state = { application: {} },
  action
) => {
  switch (action.type) {
    case APPLICATION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case APPLICATION_DETAILS_SUCCESS:
      return { loading: false, application: action.payload };
    case APPLICATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const applicationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_DELETE_REQUEST:
      return { loading: true };
    case APPLICATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case APPLICATION_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const applicationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLICATION_CREATE_REQUEST:
      return { loading: true };
    case APPLICATION_CREATE_SUCCESS:
      return { loading: false, success: true, application: action.payload };
    case APPLICATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case APPLICATION_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const applicationUpdateReducer = (
  state = { application: {} },
  action
) => {
  switch (action.type) {
    case APPLICATION_UPDATE_REQUEST:
      return { loading: true };
    case APPLICATION_UPDATE_SUCCESS:
      return { loading: false, success: true, application: action.payload };
    case APPLICATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case APPLICATION_UPDATE_RESET:
      return { application: {} };

    default:
      return state;
  }
};

import axios from "axios";
import { 
    VERSION_LIST_REQUEST,
    VERSION_LIST_SUCCESS,
    VERSION_LIST_FAIL,
    VERSION_GET_REQUEST,
    VERSION_GET_SUCCESS,
    VERSION_GET_FAIL,
    VERSION_PUT_SUCCESS,
    VERSION_PUT_FAIL,
    VERSION_PUT_REQUEST,
 } from '../constants/versionConstants'

 export const listVersions = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: VERSION_LIST_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/versions/all/`, config);
  
      dispatch({
        type: VERSION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VERSION_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  export const getVersion = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VERSION_GET_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/versions/${id}/`, config);
  
      dispatch({
        type: VERSION_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VERSION_GET_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  
  export const putVersion = (id, versionInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VERSION_PUT_REQUEST,
      });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`/api/versions/${id}/`, versionInfo, config);
  
      dispatch({
        type: VERSION_PUT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VERSION_PUT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
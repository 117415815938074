export const APPLICATION_LIST_REQUEST = "APPLICATION_LIST_REQUEST";
export const APPLICATION_LIST_SUCCESS = "APPLICATION_LIST_SUCCESS";
export const APPLICATION_LIST_FAIL = "APPLICATION_LIST_FAIL";

export const APPLICATION_DETAILS_REQUEST = "APPLICATION_DETAILS_REQUEST";
export const APPLICATION_DETAILS_SUCCESS = "APPLICATION_DETAILS_SUCCESS";
export const APPLICATION_DETAILS_FAIL = "APPLICATION_DETAILS_FAIL";

export const APPLICATION_DELETE_REQUEST = "APPLICATION_DELETE_REQUEST";
export const APPLICATION_DELETE_SUCCESS = "APPLICATION_DELETE_SUCCESS";
export const APPLICATION_DELETE_FAIL = "APPLICATION_DELETE_FAIL";

export const APPLICATION_CREATE_REQUEST = "APPLICATION_CREATE_REQUEST";
export const APPLICATION_CREATE_SUCCESS = "APPLICATION_CREATE_SUCCESS";
export const APPLICATION_CREATE_FAIL = "APPLICATION_CREATE_FAIL";
export const APPLICATION_CREATE_RESET = "APPLICATION_CREATE_RESET";

export const APPLICATION_UPDATE_REQUEST = "APPLICATION_UPDATE_REQUEST";
export const APPLICATION_UPDATE_SUCCESS = "APPLICATION_UPDATE_SUCCESS";
export const APPLICATION_UPDATE_FAIL = "APPLICATION_UPDATE_FAIL";
export const APPLICATION_UPDATE_RESET = "APPLICATION_UPDATE_RESET";

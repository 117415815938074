import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Table,
  Button,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  completeAllTasks,
  getIncompleteUsersReq,
} from "../actions/checklistActions";

function OnboardingScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [myFilter, setMyFilter] = useState("_id");
  const [filterDirection, setFilterDirection] = useState(true);

  const getIncompleteUsers = useSelector((state) => state.getIncompleteUsers);
  const { loading, error, incompleteUsers } = getIncompleteUsers;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const checkAllTasks = useSelector((state) => state.checkAllTasks);
  const { success: successAll } = checkAllTasks;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    } else {
      dispatch(getIncompleteUsersReq());
    }
  }, [dispatch, navigate, userInfo, successAll]);

  const completeHandler = (id) => {
    if (window.confirm("Are you sure you want to complete all tasks?")) {
      dispatch(completeAllTasks(id));
    }
  };

  const filterHandler = (filterName) => {
    if (filterName === myFilter) {
      setFilterDirection(!filterDirection);
    } else {
      setMyFilter(filterName);
      setFilterDirection(true);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Complete all tasks for user.
    </Tooltip>
  );

  return (
    <div>
      <h5 className="my-4">Onboarding</h5>
      <Row>
        <Col lg={4} md={12}>
          <h2>Advanced Options</h2>

          <div className="d-flex my-4">
            <h6 className="me-auto">Want to edit deptartment list?</h6>
            <Link to="/app/onboarding/departments">
              <Button>View Depts</Button>
            </Link>
          </div>

          <div className="d-flex my-4">
            <h6 className="me-auto">Want to edit todos list?</h6>
            <Link to="/app/onboarding/todos">
              <Button>View Todos</Button>
            </Link>
          </div>
        </Col>
        <Col lg={1} md={0}></Col>
        <Col lg={7} md={12}>
          <Row className="align-items-center">
            <Col>
              <h2>Incomplete Users</h2>
            </Col>
            <Col className="text-end">
              <LinkContainer to="/app/onboarding/old-user">
                <Button className="mx-1">
                  <i className="fas fa-minus"></i> Old User
                </Button>
              </LinkContainer>
              <LinkContainer to="/app/onboarding/new-user">
                <Button className="my-3">
                  <i className="fas fa-plus"></i> New User
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <div>
              <Table hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th role="button" onClick={() => filterHandler("_id")}>
                      ID
                    </th>
                    <th role="button" onClick={() => filterHandler("name")}>
                      Name
                    </th>
                    <th role="button" onClick={() => filterHandler("email")}>
                      Email
                    </th>
                    <th role="button" onClick={() => filterHandler("empType")}>
                      Department
                    </th>
                    <th width="10%"></th>
                  </tr>
                </thead>

                <tbody>
                  {incompleteUsers
                    .sort(function (a, b) {
                      if (myFilter === "_id") {
                        if (filterDirection === true) {
                          return a[myFilter] - b[myFilter] || a.id - b.id;
                        } else {
                          return b[myFilter] - a[myFilter] || a.id - b.id;
                        }
                      } else {
                        if (filterDirection === true) {
                          return a[myFilter]
                            .toString()
                            .localeCompare(b[myFilter]);
                        } else {
                          return b[myFilter]
                            .toString()
                            .localeCompare(a[myFilter]);
                        }
                      }
                    })
                    // .sort((a, b) => a[myFilter].toString().localeCompare(b[myFilter]))
                    .map((incompleteUser) => (
                      <tr key={incompleteUser._id}>
                        <td>{incompleteUser._id}</td>
                        <td>{incompleteUser.name}</td>
                        <td>{incompleteUser.email}</td>
                        <td>{incompleteUser.empType}</td>
                        <td className="d-flex justify-content-end">
                          <LinkContainer
                            to={`/app/onboarding/${incompleteUser._id}`}
                          >
                            <Button variant="secondary" className="btn-sm">
                              <i className="fas fa-eye"></i>
                            </Button>
                          </LinkContainer>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 650, hide: 200 }}
                            overlay={renderTooltip}
                          >
                            <Button
                              variant="primary"
                              className="btn-sm"
                              onClick={() =>
                                completeHandler(incompleteUser._id)
                              }
                            >
                              <i className="fas fa-check"></i>
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default OnboardingScreen;

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/Header";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ApplicationListScreen from "./screens/ApplicationListScreen";
import ApplicationEditScreen from "./screens/ApplicationEditScreen";
import EtlScreen from "./screens/EtlScreen";
import VidScreen from "./screens/VidScreen";
import OnBreakScreen from "./screens/OnBreakScreen";
import ReportScreen from "./screens/ReportScreen";
import DefaultAppScreen from "./screens/DefaultAppScreen";
import OnboardingScreen from "./screens/OnboardingScreen";
import NewUserScreen from "./screens/NewUserScreen";
import OldUserScreen from "./screens/OldUserScreen";
import OnboardingTodoScreen from "./screens/OnboardingTodoScreen";
import DeptListScreen from "./screens/DeptListScreen";
import DeptEditScreen from "./screens/DeptEditScreen";
import ChecklistTodoEditScreen from "./screens/ChecklistTodoEditScreen"
import OnboardingUserScreen from "./screens/OnboardingUserScreen";
import NoScreen from "./screens/NoScreen";
import OnBreakEditScreen from "./screens/OnBreakEditScreen";
import VersionsScreen from "./screens/VersionsScreen";
import VersionEditScreen from "./screens/VersionEditScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import MoodReportScreen from "./screens/MoodReportScreen";

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {}, [userInfo]);

  return (
    <div>
      {!userInfo ? (
        <BrowserRouter>
          <main className="row align-items-center mx-0 my-0 px-0 py-0">
            <div className="col">
              <Container>
                <Routes>
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="/login" element={<LoginScreen />} />
                  <Route path="/register" element={<RegisterScreen />} />
                  <Route path="*" element={<LoginScreen />} />
                </Routes>
              </Container>
            </div>
          </main>
        </BrowserRouter>
      ) : !userInfo.permissions.changed_password ? (
        <BrowserRouter>
          <main className="row align-items-center mx-0 my-0 px-0 py-0">
            <div className="col">
              <Container>
                <Routes>
                  <Route path="*" element={<ChangePasswordScreen />} />
                </Routes>
              </Container>
            </div>
          </main>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Header />
          <main className="py-3">
            <Container>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />

                <Route path="/versions" element={<VersionsScreen />} />
                <Route path="/version/:id/edit" element={<VersionEditScreen />} />
                <Route path="/app/etl" element={<EtlScreen />} />
                <Route path="/app/onboarding" element={<OnboardingScreen />} />
                <Route
                  path="/app/onboarding/new-user"
                  element={<NewUserScreen />}
                />
                <Route
                  path="/app/onboarding/old-user"
                  element={<OldUserScreen />}
                />
                <Route
                  path="/app/onboarding/todos"
                  element={<OnboardingTodoScreen />}
                />
                <Route
                  path="/app/onboarding/todos/:id/edit"
                  element={<ChecklistTodoEditScreen />}
                />
                <Route
                  path="/app/onboarding/departments"
                  element={<DeptListScreen />}
                />
                <Route
                  path="/app/onboarding/departments/:id/edit"
                  element={<DeptEditScreen />}
                />
                <Route
                  path="/app/onboarding/:id"
                  element={<OnboardingUserScreen />}
                />
                <Route path="/app/vid" element={<VidScreen />} />
                <Route path="/app/onbreak" element={<OnBreakScreen />} />
                <Route path="/app/onbreak/:id/edit" element={<OnBreakEditScreen />} />
                <Route path="/app/mood-report" element={<MoodReportScreen />} />
                <Route path="/app/reporting" element={<ReportScreen />} />
                <Route path="/app/*" element={<DefaultAppScreen />} />

                <Route path="/frontend-admin/userlist" element={<UserListScreen />} />
                <Route
                  path="/frontend-admin/user/:id/edit"
                  element={<UserEditScreen />}
                />

                <Route
                  path="/frontend-admin/applicationlist"
                  element={<ApplicationListScreen />}
                />
                <Route
                  path="/frontend-admin/application/:id/edit"
                  element={<ApplicationEditScreen />}
                />
                <Route path="/login" element={<HomeScreen />} />
                <Route path="/*" element={<NoScreen />} />
              </Routes>
            </Container>
          </main>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;

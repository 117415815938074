import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  applicationListReducer,
  applicationDetailsReducer,
  applicationDeleteReducer,
  applicationCreateReducer,
  applicationUpdateReducer,
} from "./reducers/applicationReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userPermissionsCreateReducer,
  userGetPermissionsReducer,
  userUpdatePermissionsReducer,
  userUpdateMyPermissionsReducer,
  managerListReducer,
} from "./reducers/userReducers";

import { 
  empTypeGetAllReducer,
  empTypeGetReducer,
  empTypePostReducer,
  empTypePutReducer,
  empTypeDeleteReducer,
  todoGetAllReducer,
  todoGetReducer,
  todoPostReducer,
  todoPutReducer,
  todoDeleteReducer,
  newUserReducer,
  getIncompleteUsersReducer,
  getUserTasksReducer,
  checkTaskReducer,
  checkAllTasksReducer,
  updateTaskWeightReducer,
  offboardReducer,
 } from './reducers/checklistReducers'

import { 
  breakInfoDetailsReducer,
  breakInfoUpdateReducer,
  breakInfoDetailsListReducer,
  breakInfoUpdateOtherReducer,
  breakInfoOthersDetailsReducer,
  breakListSocketReducer,
  breakTypesReducer,
 } from './reducers/onBreakReducers'

 import { 
   versionListReducer,
   getVersionReducer,
   versionPutReducer,
 } from './reducers/versionReducers'
import { etlActionsFailListReducer, etlActionsListReducer, etlActionsSuccessListReducer, etlActionUpdateReducer, etlDataSourcesListReducer, etlStepsListReducer, etlTablesListReducer } from "./reducers/etlReducers";
import { moodAdminReportsReducer, moodDatesReducer, moodReportsReducer, moodSelectDatesReducer, moodUpdateReducer } from "./reducers/moodReducers";

const reducer = combineReducers({
  applicationList: applicationListReducer,
  applicationDetails: applicationDetailsReducer,
  applicationDelete: applicationDeleteReducer,
  applicationCreate: applicationCreateReducer,
  applicationUpdate: applicationUpdateReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  managerList: managerListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userPermissionsCreate: userPermissionsCreateReducer,
  userGetPermissions: userGetPermissionsReducer,
  userUpdatePermissions: userUpdatePermissionsReducer,
  userUpdateMyPermissions: userUpdateMyPermissionsReducer,

  breakInfoDetails: breakInfoDetailsReducer,
  breakInfoOthersDetails: breakInfoOthersDetailsReducer,
  breakInfoUpdate: breakInfoUpdateReducer,
  breakInfoUpdateOther: breakInfoUpdateOtherReducer,
  breakInfoDetailsList: breakInfoDetailsListReducer,
  allBreakTypes: breakTypesReducer,
  breakListSocket: breakListSocketReducer,

  empTypeGetAll: empTypeGetAllReducer,
  empTypeGet: empTypeGetReducer,
  empTypePost: empTypePostReducer,
  empTypePut: empTypePutReducer,
  empTypeDelete: empTypeDeleteReducer,
  
  todoGetAll: todoGetAllReducer,
  todoGet: todoGetReducer,
  todoPost: todoPostReducer,
  todoPut: todoPutReducer,
  todoDelete: todoDeleteReducer,
  offboard: offboardReducer,

  newUser: newUserReducer,
  getIncompleteUsers: getIncompleteUsersReducer,
  getUserTasks: getUserTasksReducer,
  checkTask: checkTaskReducer,
  checkAllTasks: checkAllTasksReducer,
  updateTaskWeight: updateTaskWeightReducer,

  versionList: versionListReducer,
  getVersionId: getVersionReducer,
  versionPutId: versionPutReducer,

  etlDataSourcesList: etlDataSourcesListReducer,
  etlTablesList: etlTablesListReducer,
  etlStepsList: etlStepsListReducer,
  etlActionsList: etlActionsListReducer,
  etlActionsSuccessList: etlActionsSuccessListReducer,
  etlActionsFailList: etlActionsFailListReducer,
  etlActionUpdate: etlActionUpdateReducer,
  
  moodDatesList: moodDatesReducer,
  moodSelectDates: moodSelectDatesReducer,
  moodUpdate: moodUpdateReducer,
  moodReportList: moodReportsReducer,
  moodAdminReportUserList: moodAdminReportsReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

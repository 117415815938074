import axios from "axios";
import { ETL_ACTIONS_FAIL, ETL_ACTIONS_FAIL_FAIL, ETL_ACTIONS_FAIL_REQUEST, ETL_ACTIONS_FAIL_SUCCESS, ETL_ACTIONS_REQUEST, ETL_ACTIONS_SUCCESS, ETL_ACTIONS_SUCCESS_FAIL, ETL_ACTIONS_SUCCESS_REQUEST, ETL_ACTIONS_SUCCESS_SUCCESS, ETL_ACTIONS_UPDATE_FAIL, ETL_ACTIONS_UPDATE_REQUEST, ETL_ACTIONS_UPDATE_SUCCESS, ETL_DATA_SOURCES_FAIL, ETL_DATA_SOURCES_REQUEST, ETL_DATA_SOURCES_SUCCESS, ETL_STEPS_FAIL, ETL_STEPS_REQUEST, ETL_STEPS_SUCCESS, ETL_TABLES_FAIL, ETL_TABLES_REQUEST, ETL_TABLES_SUCCESS } from "../constants/etlConstants";


export const getEtlDataSources = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ETL_DATA_SOURCES_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/etl/datasource/`, config);
  
      dispatch({
        type: ETL_DATA_SOURCES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ETL_DATA_SOURCES_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  

export const getEtlTables = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ETL_TABLES_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/etl/table/`, config);
  
      dispatch({
        type: ETL_TABLES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ETL_TABLES_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  

export const getEtlSteps = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ETL_STEPS_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/etl/step/`, config);
  
      dispatch({
        type: ETL_STEPS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ETL_STEPS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  

export const getEtlActions = () => async (dispatch, getState) => {
    try {
      dispatch({ type: ETL_ACTIONS_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/etl/action/`, config);
  
      dispatch({
        type: ETL_ACTIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ETL_ACTIONS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };


export const getEtlActionsFail = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ETL_ACTIONS_FAIL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/etl/actionfail/`, config);

    dispatch({
      type: ETL_ACTIONS_FAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ETL_ACTIONS_FAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const getEtlActionsSuccess = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ETL_ACTIONS_SUCCESS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/etl/actionsuccess/`, config);

    dispatch({
      type: ETL_ACTIONS_SUCCESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ETL_ACTIONS_SUCCESS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateEtlAction =
  (etlAction, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ETL_ACTIONS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/etl/action/${id}/`,
        etlAction,
        config
      );

      dispatch({
        type: ETL_ACTIONS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ETL_ACTIONS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import {
  listApplications,
  deleteApplication,
  createApplication,
} from "../actions/applicationActions";
import { APPLICATION_CREATE_RESET } from "../constants/applicationConstants";

function ApplicationListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let keyword = searchParams.get("keyword");
  let mypage = searchParams.get("page");

  const applicationList = useSelector((state) => state.applicationList);
  const { loading, error, applications, pages, page } = applicationList;

  const applicationDelete = useSelector((state) => state.applicationDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = applicationDelete;

  const applicationCreate = useSelector((state) => state.applicationCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    application: createdApplication,
  } = applicationCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: APPLICATION_CREATE_RESET });

    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      navigate(`/frontend-admin/application/${createdApplication._id}/edit`);
    } else {
      dispatch(listApplications(keyword, mypage));
    }
  }, [
    dispatch,
    navigate,
    mypage,
    userInfo,
    successDelete,
    successCreate,
    createdApplication,
    keyword,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this application?")) {
      dispatch(deleteApplication(id));
    }
  };

  const createApplicationHandler = (application) => {
    dispatch(createApplication());
  };

  return (
    <div>
      <h5 className="my-4">Applications</h5>
      <Row className="align-items-center">
        <Col>
          <h2>Our Apps</h2>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={createApplicationHandler}>
            <i className="fas fa-plus"></i> Create Application
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>CATEGORY</th>
                <th style={{ width: "80px", whiteSpace: "nowrap" }}></th>
              </tr>
            </thead>

            <tbody>
              {applications.map((application) => (
                <tr key={application._id}>
                  <td>{application._id}</td>
                  <td>{application.name}</td>
                  <td>{application.category}</td>

                  <td className="d-flex justify-content-around">
                    <LinkContainer
                      to={`/frontend-admin/application/${application._id}/edit`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>

                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(application._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate page={page} pages={pages} isAdmin={true} />
        </div>
      )}
    </div>
  );
}

export default ApplicationListScreen;

import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  getUserDetails,
  updateUser,
  getUserPermissions,
  userUpdatePermissions,
  listManagers,
} from "../actions/userActions";
import {
  USER_UPDATE_RESET,
  USER_UPDATE_PERMISSIONS_RESET,
  USER_GET_PERMISSIONS_RESET,
  USER_DETAILS_RESET,
} from "../constants/userConstants";
import { createBreakInfo, deleteBreakInfo } from "../actions/onBreakActions";
import { listEmployeeTypes } from "../actions/checklistActions";

function UserEditScreen() {
  const { id: userId } = useParams();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isCEP, setIsCEP] = useState();
  const [isCepManager, setIsCepManager] = useState();
  const [isCepDeptManager, setIsCepDeptManager] = useState();
  const [access_onbreak, setAccessOnBreak] = useState();
  const [onbreak_admin, setOnBreakAdmin] = useState();
  const [access_mood, setAccessMood] = useState();
  const [mood_admin, setMoodAdmin] = useState();
  const [access_etl, setAccessEtl] = useState();
  const [access_reporting, setAccessReporting] = useState();
  const [dept, setDept] = useState("");
  const [cepManager, setCepManager] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userGetPermissions = useSelector((state) => state.userGetPermissions);
  const {
    loading: loadingPermissions,
    error: errorPermissions,
    permissionInfo,
  } = userGetPermissions;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    error: errorPerms,
    loading: loadingPerms,
    success: successPerms,
  } = userUpdate;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  const managerList = useSelector((state) => state.managerList);
  const { cepManagers } = managerList;

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (!userInfo.isAdmin) {
      navigate("/");
    } else {
      if (successPerms) {
        navigate("/frontend-admin/userlist");
        dispatch({ type: USER_UPDATE_RESET });
        dispatch({ type: USER_DETAILS_RESET });
        dispatch({ type: USER_GET_PERMISSIONS_RESET });
        dispatch({ type: USER_UPDATE_PERMISSIONS_RESET });
      } else {
        dispatch(getUserPermissions(userId));
        dispatch(getUserDetails(userId));
        dispatch(listEmployeeTypes());
        dispatch(listManagers())
      }
    }
  }, [dispatch, navigate, userInfo, userId, successPerms]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Removes all permissions, admin status, and dept type for selected user.
    </Tooltip>
  );


  const deactivateHandler = (e) => {
    e.preventDefault();
    dispatch(
      userUpdatePermissions(
        {
          access_onbreak: false,
          onbreak_admin: false,
          access_mood: false,
          access_etl: false,
          access_reporting: false,
          isCEP: false,
          isAdmin: false,
          mood_admin: false,
          isCepManager: false,
          isCepDeptManager: false,
          dept: "deactivated",
        },
        userId
      )
    );
    dispatch(updateUser({ _id: userId, isAdmin: false }));
    dispatch(getUserDetails(userId));
    dispatch(deleteBreakInfo(userId));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
    } else {
      dispatch(updateUser({ _id: userId, name, email, isAdmin, password }));
      dispatch(
        userUpdatePermissions(
          {
            access_onbreak,
            onbreak_admin,
            access_mood,
            mood_admin,
            access_etl,
            access_reporting,
            isCEP,
            isCepManager,
            isCepDeptManager,
            cepManager,
            dept,
          },
          userId
        )
      );
      setMessage("");
      if (access_onbreak === true) {
        dispatch(createBreakInfo(userId));
      } else if (access_onbreak === false) {
        dispatch(deleteBreakInfo(userId));
      }
    }
  };

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/frontend-admin/userlist">
          Users
        </Link>{" "}
        / Edit
      </h5>
      <Row>
        <h2>{userDetails.user.name}</h2>
      </Row>
      <Row>
        <Col md={3}>
          <h4>Edit Profile Info</h4>
          {loadingPerms && <Loader />}
          {errorPerms && <Message variant="danger">{errorPerms}</Message>}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <Form.Group className="mt-2" controlId="name">
                <FloatingLabel
                  controlId="floatingInputName"
                  label="Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="name"
                    placeholder="Name"
                    autoComplete="off"
                    defaultValue={userDetails.user.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mt-2" controlId="email">
                <FloatingLabel
                  controlId="floatingInputEmail"
                  label="Email address"
                  className="mb-3"
                >
                  {" "}
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    defaultValue={userDetails.user.email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mt-2" controlId="email">
                <FloatingLabel
                  controlId="floatingInputPassword"
                  label="New Password"
                  className="mb-3"
                >
                  {" "}
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mt-2" controlId="email">
                <FloatingLabel
                  controlId="floatingInputConfirmPassword"
                  label="Confirm Password"
                  className="mb-3"
                >
                  {" "}
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    autoComplete="off"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="isadmin">
                <Form.Check
                  type="checkbox"
                  label="Is Admin"
                  defaultChecked={user.isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <h4 className="mt-3">Conequip Perms:</h4>
              <Form.Group controlId="isCepEmployee">
                <Form.Check
                  type="checkbox"
                  label="Conequip Employee"
                  defaultChecked={permissionInfo.conequip_employee}
                  onChange={(e) => setIsCEP(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <Form.Group controlId="isCepManager">
                <Form.Check
                  type="checkbox"
                  label="Conequip Manager"
                  defaultChecked={permissionInfo.isCepManager}
                  onChange={(e) => setIsCepManager(e.target.checked)}
                ></Form.Check>
              </Form.Group>
              <Form.Group controlId="isCepDeptManager">
                <Form.Check
                  type="checkbox"
                  label="Conequip Department Manager"
                  defaultChecked={permissionInfo.isCepDeptManager}
                  onChange={(e) => setIsCepDeptManager(e.target.checked)}
                ></Form.Check>
              </Form.Group>
            </Form>
          )}
        </Col>

        <Col md={9}>
          <Row>
            <Col md={8}>
              <h4>Edit User Permissions</h4>
            </Col>
            <Col md={4}>
              <h4>Other</h4>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              {loadingPermissions ? (
                <Loader />
              ) : errorPermissions ? (
                <Message variant="danger">{errorPermissions}</Message>
              ) : (
                <Form className="mt-2">
                  <Form.Check
                    type="switch"
                    id="access-onbreak-switch"
                    label="Access On-Break"
                    defaultChecked={permissionInfo.access_onbreak}
                    onChange={(e) => setAccessOnBreak(e.target.checked)}
                  />
                  <Form.Check
                    type="switch"
                    id="access-etl-switch"
                    label="Access ETL Viewer"
                    defaultChecked={permissionInfo.access_etl}
                    onChange={(e) => setAccessEtl(e.target.checked)}
                  />
                  <Form.Check
                    type="switch"
                    id="access-mood-report-switch"
                    label="Access Mood Report"
                    defaultChecked={permissionInfo.access_mood}
                    onChange={(e) => setAccessMood(e.target.checked)}
                  />
                  <Form.Check
                    type="switch"
                    id="access-reporting-switch"
                    label="Access Reporting"
                    defaultChecked={permissionInfo.access_reporting}
                    onChange={(e) => setAccessReporting(e.target.checked)}
                  />
                </Form>
              )}
            </Col>
            <Col md={4}>
              <Form className="mt-2">
                <Form.Check
                  type="switch"
                  id="admin-onbreak-switch"
                  label="Admin On-Break"
                  defaultChecked={permissionInfo.onbreak_admin}
                  onChange={(e) => setOnBreakAdmin(e.target.checked)}
                />
                <Form.Check
                  type="switch"
                  id="admin-mood-switch"
                  label="Admin Mood Report"
                  defaultChecked={permissionInfo.mood_admin}
                  onChange={(e) => setMoodAdmin(e.target.checked)}
                />
              </Form>
            </Col>
            <Col md={4}>
              <Form.Label className="mt-2">Department</Form.Label>
              <Form.Control
                as="select"
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                required
                style={{ width: "100%", minWidth: "150px" }}
              >
                <option>
                  {permissionInfo.empType != null
                    ? permissionInfo.empType
                    : "Select..."}
                </option>
                {empTypes &&
                  empTypes
                    .sort((a, b) => a.empType.localeCompare(b.empType))
                    .filter((a) => a.empType !== "Everyone")
                    .map((empType) => (
                      <option key={empType._id} value={empType._id}>
                        {empType.empType}
                      </option>
                    ))}
              </Form.Control>

              <Form.Label className="mt-2">Manager</Form.Label>
              <Form.Control
                as="select"
                value={cepManager}
                onChange={(e) => setCepManager(e.target.value)}
                required
                style={{ width: "100%", minWidth: "150px" }}
              >
                <option>
                  {permissionInfo.myManager != null
                    ? permissionInfo.myManager
                    : "Select..."}
                </option>
                {cepManagers &&
                  cepManagers
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((a) => a.name !== "Everyone")
                    .map((cepMan) => (
                      <option key={cepMan.user} value={cepMan.user}>
                        {cepMan.name}
                      </option>
                    ))}
              </Form.Control>

            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {message && <Message variant="danger">{message}</Message>}
        <Col md={12} className="mt-1 d-flex justify-content-center">
          <Button
            size="md"
            onClick={submitHandler}
            variant="primary"
            className="mt-1 mx-2"
            style={{ width: "100px" }}
          >
            Update
          </Button>

          <OverlayTrigger
            placement="right"
            delay={{ show: 650, hide: 200 }}
            overlay={renderTooltip}
          >
            <Button
              size="md"
              onClick={deactivateHandler}
              variant="danger"
              className="mt-1 mx-2"
              style={{ width: "100px" }}
            >
              Deactivate
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </div>
  );
}

export default UserEditScreen;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { listUsers } from "../actions/userActions";
import { offboardOther } from "../actions/checklistActions";
import { deleteBreakInfo } from "../actions/onBreakActions";

function OldUserScreen() {
    const [selectedUser, setSelectedUser] = useState();
    const [custError, setCustError] = useState();

    const dispatch = useDispatch();

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading } = userRegister;

    const userList = useSelector((state) => state.userList);
    const { users, loading:newLoading } = userList;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const navigate = useNavigate();

    //This seems to not be firing until the page is refreshed, both pulling up the page and clicking the button do not fire useEffect
    useEffect(() => {
        if (!userInfo) {
            navigate("/");
        } else if (!userInfo.isAdmin) {
            navigate("/");
        } else {
            dispatch(listUsers());
            dispatch({ type: "CHECKLIST_NEW_USER_RESET" });
        }
    }, [dispatch, navigate, userInfo]);

  const submitHandler = (e) => {
      e.preventDefault()
        if(selectedUser) {
         dispatch(offboardOther(selectedUser))
         dispatch(deleteBreakInfo(selectedUser));
         dispatch({ type: "CHECKLIST_NEW_USER_RESET" });
         navigate('/app/onboarding')
        } else {
            setCustError("Please select an Employee")
        }
    }

    return (
        <div>
            <h5 className="my-4">
                <Link className="productTitle text-white" to="/app/onboarding">
                    Onboarding
                </Link>{" "}
                / Old-User
            </h5>{" "}
            <Row>
                <Col md={6} sm={12} xs={12}>
                    <h2>Remove Old User</h2>
                    {error && <Message variant="danger">{error}</Message>}
                    {custError && <Message variant="danger">{custError}</Message>}
                    {loading && <Loader />}
                    {newLoading ? (
                        <Loader />
                    ) : (
                        <Form>
                            <Form.Label>Employee Name</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                                required
                                style={{ width: "45%", minWidth: "150px" }}
                            >
                                <option>Select...</option>
                                {users &&
                                    users
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .filter((a) => a.isOffboarding !== true)
                                        .map((user) => (
                                            <option key={user.id} value={user.id}>
                                                {user.name}
                                            </option>
                                        ))}
                            </Form.Control>
                            <Button type="submit" variant="primary" className="mt-3" onClick={submitHandler}>
                                Offboard User
                            </Button>
                        </Form>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default OldUserScreen;

import React from "react";
import { Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function NoScreen() {
  return (
    <div style={{ marginTop: "20%" }} className="text-center">
      <h1>Lost your way?</h1>
      <p>
        Sorry, we can't find your page. You'll find more to explore on the home
        page.
      </p>
      <LinkContainer to="/">
        <Button className="my-3">DashBored Home</Button>
      </LinkContainer>
    </div>
  );
}

export default NoScreen;

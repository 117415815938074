import { MOOD_ADMIN_REPORTS_FAIL, MOOD_ADMIN_REPORTS_REQUEST, MOOD_ADMIN_REPORTS_RESET, MOOD_ADMIN_REPORTS_SUCCESS, MOOD_DATES_FAIL, MOOD_DATES_REQUEST, MOOD_DATES_RESET, MOOD_DATES_SUCCESS, MOOD_GET_REPORTS_FAIL, MOOD_GET_REPORTS_REQUEST, MOOD_GET_REPORTS_RESET, MOOD_GET_REPORTS_SUCCESS, MOOD_POST_REPORT_FAIL, MOOD_POST_REPORT_REQUEST, MOOD_POST_REPORT_RESET, MOOD_POST_REPORT_SUCCESS, MOOD_SELECT_DATES_FAIL, MOOD_SELECT_DATES_REQUEST, MOOD_SELECT_DATES_RESET, MOOD_SELECT_DATES_SUCCESS } from "../constants/moodConstants";

export const moodDatesReducer = (
    state = { moodDates: [] },
    action
  ) => {
    switch (action.type) {
      case MOOD_DATES_REQUEST:
        return { loading: true };
      case MOOD_DATES_SUCCESS:
        return { loading: false, moodDates: action.payload };
      case MOOD_DATES_FAIL:
        return { loading: false, error: action.payload };
      case MOOD_DATES_RESET:
        return { moodDates: [] };
  
      default:
        return state;
    }
  };

  export const moodSelectDatesReducer = (
    state = { moodDates: [] },
    action
  ) => {
    switch (action.type) {
      case MOOD_SELECT_DATES_REQUEST:
        return { loading: true };
      case MOOD_SELECT_DATES_SUCCESS:
        return { loading: false, moodDates: action.payload };
      case MOOD_SELECT_DATES_FAIL:
        return { loading: false, error: action.payload };
      case MOOD_SELECT_DATES_RESET:
        return { moodDates: [] };
  
      default:
        return state;
    }
  };

  export const moodUpdateReducer = (state = { moodPost: {} }, action) => {
    switch (action.type) {
      case MOOD_POST_REPORT_REQUEST:
        return { loading: true };
      case MOOD_POST_REPORT_SUCCESS:
        return { loading: false, success: true };
      case MOOD_POST_REPORT_FAIL:
        return { loading: false, error: action.payload };
      case MOOD_POST_REPORT_RESET:
        return { moodPost: {} };
  
      default:
        return state;
    }
  };

  export const moodReportsReducer = (
    state = { moodReports: [] },
    action
  ) => {
    switch (action.type) {
      case MOOD_GET_REPORTS_REQUEST:
        return { loading: true };
      case MOOD_GET_REPORTS_SUCCESS:
        return { loading: false, moodReports: action.payload };
      case MOOD_GET_REPORTS_FAIL:
        return { loading: false, error: action.payload };
      case MOOD_GET_REPORTS_RESET:
        return { moodReports: [] };
  
      default:
        return state;
    }
  };


  export const moodAdminReportsReducer = (
    state = { moodReports: [] },
    action
  ) => {
    switch (action.type) {
      case MOOD_ADMIN_REPORTS_REQUEST:
        return { loading: true };
      case MOOD_ADMIN_REPORTS_SUCCESS:
        return { loading: false, moodReports: action.payload };
      case MOOD_ADMIN_REPORTS_FAIL:
        return { loading: false, error: action.payload };
      case MOOD_ADMIN_REPORTS_RESET:
        return { moodReports: [] };
  
      default:
        return state;
    }
  };

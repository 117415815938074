import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Row, Col, Form, Button, FloatingLabel, Table, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listEmployeeTypes } from "../actions/checklistActions";
import {
  getBreakInfoDetails,
  createBreakData,
  listUsersBreakSocket,
  updateUserBreakSocket,
  updateBreakInfoDetails,
  listBreakTypes
} from "../actions/onBreakActions";

var loc = window.location;
var host = loc.host;
var wsStart = "ws://";
if (loc.protocol === "https:") {
  wsStart = "wss://";
}
if (loc.host === "localhost:3000") {
  host = "localhost:8000";
}
else {
  host = host + ":8001"
}

function ToDoScreen() {
  const dispatch = useDispatch();
  const endpoint = wsStart + host;

  const [breakType, setBreakType] = useState("Lunch");
  const [customMessage, setCustomMessage] = useState("");
  const [dropDownTitle, setDropDownTitle] = useState("Everybody");
  const [otherDropDownTitle, setOtherDropDownTitle] = useState("Select Away Type ");
  const [myFilter, setMyFilter] = useState("userName");
  const [filterDirection, setFilterDirection] = useState(true);
  const [socket, setSocket] = useState();

  const breakInfoDetails = useSelector((state) => state.breakInfoDetails);
  const { loading: loadingInfoDetails, error: loadingInfoError, breakInfo } = breakInfoDetails;

  const breakInfoUpdate = useSelector((state) => state.breakInfoUpdate);
  const { success } = breakInfoUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const empTypeGetAll = useSelector((state) => state.empTypeGetAll);
  const { empTypes } = empTypeGetAll;

  const allBreakTypes = useSelector((state) => state.allBreakTypes);
  const { breakTypes } = allBreakTypes;

  const breakListSocket = useSelector((state) => state.breakListSocket);
  const { breakInfos, loading } = breakListSocket;

  useEffect(() => {
    dispatch(listEmployeeTypes());
    dispatch(listBreakTypes());
    const socket = new ReconnectingWebSocket(endpoint);
    setSocket(socket)
    socket.onmessage = function (e) {
      if (e && e.data) {
        var socketData = JSON.parse(e.data);
        if (Array.isArray(socketData)) {
          dispatch(listUsersBreakSocket(socketData))
        } else {
          if (socketData.userId === userInfo.id) {
            dispatch(getBreakInfoDetails());
          }
          dispatch(updateUserBreakSocket({
            "breakType": socketData.breakType,
            "clockOutTime": socketData.timeLeft,
            "customMessage": socketData.customMessage,
            "empType": socketData.empType,
            "status": socketData.status,
            "user": socketData.userId,
            "userName": socketData.userName
          }))
        }
      }
    };
    return () => {
      socket.close();
    }; //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setBreakType("Lunch");
    setCustomMessage("");
    if (
      userInfo.permissions.onbreak_admin ||
      userInfo.permissions.access_onbreak
    ) {
      dispatch(getBreakInfoDetails());
    }
    // eslint-disable-next-line
  }, [success]);

  const convertTime = (e) => {
    if (e === "" || e === null) {
      return "";
    }
    e = new Date(e);
    return e.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  };


  const filterHandler = (filterName) => {
    if (filterName === myFilter) {
      setFilterDirection(!filterDirection);
    } else {
      setMyFilter(filterName);
      setFilterDirection(true);
    }
  };

  const tempOutSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateBreakInfoDetails({
        status: false,
        breakType: breakType,
        customMessage: customMessage,
      })
    );
    var sendMe = {
      userId: userInfo._id,
      status: false,
      breakType: breakType,
      customMessage: customMessage,
      timeLeft: new Date().toLocaleString(),
      empType: userInfo.permissions.empType,
      userName: userInfo.name
    };
    socket.send(JSON.stringify(sendMe));
    setOtherDropDownTitle("Select Away Type ")
  };

  const backInSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(updateBreakInfoDetails({ status: "True" }));
    dispatch(
      createBreakData({
        breakType: breakInfo.breakType,
        customMessage: breakInfo.customMessage,
        clockOutTime: breakInfo.clockOutTime,
      })
    );
    var sendMe = {
      userId: userInfo._id,
      status: true,
      customMessage: null,
      breakType: "",
      timeLeft: null,
      empType: userInfo.permissions.empType,
      userName: userInfo.name
    };
    socket.send(JSON.stringify(sendMe));
  };

  function showInOtherTypes(value) {
    if (value.breakType === "Break" || value.breakType === "Lunch") {
      return false
    } else {
      if (value.breakType === breakType) {
        return false
      }
      return value
    }
  }

  return (
    <div>
      <h5 className="my-4">On Break App</h5>
      {userInfo.permissions.onbreak_admin ? (
        <Row>
          <Col md={3}>
            {loadingInfoDetails ? (
              <Loader />
            ) : loadingInfoError ? (
              <Message variant="danger">{loadingInfoError}</Message>
            ) : !breakInfo.status ? (
              <div>
                <h2>Current Status</h2>
                <p>
                  Away type: <br />
                  {breakInfo.breakType}
                </p>
                {breakInfo.customMessage && (
                  <p style={{ overflowWrap: "break-word" }}>
                    Message: <br />
                    {breakInfo.customMessage}
                  </p>
                )}
                <p>
                  Clock out time: <br />
                  {convertTime(breakInfo.clockOutTime)}
                </p>
                <Button
                  size="md"
                  onClick={backInSubmitHandler}
                  variant="primary"
                  className="mt-2"
                >
                  I'm Back
                </Button>
              </div>
            ) : (
              <div className="pb-4">
                <h2>Change Status</h2>
                <Form onSubmit={tempOutSubmitHandler}>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      defaultChecked
                      inline
                      label="Lunch"
                      name="breakType"
                      type={"radio"}
                      id={`Lunch`}
                      onChange={function (e) {
                        setBreakType("Lunch");
                        setOtherDropDownTitle("Select Away Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Break"
                      name="breakType"
                      type={"radio"}
                      id={`Break`}
                      onChange={function (e) {
                        setBreakType("Break");
                        setOtherDropDownTitle("Select Away Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Other"
                      name="breakType"
                      type={"radio"}
                      id={`Other`}
                      onChange={(e) => setBreakType("Other")}
                    />
                  </div>
                  <Form.Group className="mt-2" controlId="customMessage">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Custom Message"
                      className="mb-3"
                    >
                      {" "}
                      <Form.Control
                        type="text"
                        placeholder="Custom Message"
                        maxLength="200"
                        onChange={(e) => setCustomMessage(e.target.value)}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  {breakType !== "Lunch" && breakType !== "Break" && <div>
                    <div className="mb-3">
                      <Dropdown>
                        {breakType === "Other" ?
                          <Dropdown.Toggle style={{ width: "100%", textAlign: "left", backgroundColor: "white", color: "#869096" }}>{otherDropDownTitle}</Dropdown.Toggle>
                          : <Dropdown.Toggle id="otherDropDown" style={{ width: "100%", textAlign: "left", backgroundColor: "white", color: "black" }}>{otherDropDownTitle}</Dropdown.Toggle>
                        }
                        <Dropdown.Menu>
                          {breakTypes &&
                            breakTypes
                              .sort((a, b) => a.breakType.localeCompare(b.breakType))
                              .filter(showInOtherTypes)
                              .map((breakType) => (
                                <Dropdown.Item
                                  id="YE"
                                  key={breakType._id}
                                  onClick={function (e) {
                                    setOtherDropDownTitle(breakType.breakType);
                                    setBreakType(breakType.breakType)
                                  }}
                                >
                                  {breakType.breakType}
                                </Dropdown.Item>
                              ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>}
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Form>
              </div>
            )}
          </Col>

          <Col md={9}>
            <div>
              <div>
                <Row>
                  <Col xs={6}>
                    <h2>Employee List</h2>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex justify-content-end align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle>{dropDownTitle}</Dropdown.Toggle>
                        <Dropdown.Menu>
                          {empTypes &&
                            empTypes
                              .sort((a, b) => a.empType.localeCompare(b.empType))
                              .map((empType) => (
                                <Dropdown.Item
                                  key={empType._id}
                                  onClick={(e) => setDropDownTitle(empType.empType)}
                                >
                                  {empType.empType}
                                </Dropdown.Item>
                              ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                {loading ? (
                  <Loader />
                ) : (
                  <Table striped bordered hover responsive className="table-sm">
                    <thead>
                      <tr>
                        <th
                          role="button"
                          onClick={() => filterHandler("status")}
                          width="12%"
                          style={{ textAlign: "center" }}
                        >
                          Status
                        </th>
                        <th
                          role="button"
                          onClick={() => filterHandler("userName")}
                          width="20%"
                        >
                          Employee
                        </th>
                        <th
                          role="button"
                          onClick={() => filterHandler("breakType")}
                          width="16%"
                        >
                          Away Type
                        </th>
                        <th
                          role="button"
                          onClick={() => filterHandler("clockOutTime")}
                          width="16%"
                        >
                          Time Left
                        </th>
                        <th style={{ maxWidth: "400px" }}>Message</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {breakInfos &&
                        breakInfos
                          .sort(function (a, b) {
                            if (myFilter === "_id") {
                              if (filterDirection === true) {
                                return a[myFilter] - b[myFilter] || a.id - b.id;
                              } else {
                                return b[myFilter] - a[myFilter] || a.id - b.id;
                              }
                            } else {
                              if (filterDirection === true) {
                                return ("" + a[myFilter])
                                  .toString()
                                  .localeCompare(b[myFilter]);
                              } else {
                                return ("" + b[myFilter])
                                  .toString()
                                  .localeCompare(a[myFilter]);
                              }
                            }
                          })
                          .filter(function (a) {
                            if (dropDownTitle === "Everybody") {
                              return true;
                            } else {
                              return a.empType === dropDownTitle;
                            }
                          })
                          .map((oneBreak) => (
                            <tr key={oneBreak.user}>
                              {oneBreak.status ? (
                                <td style={{ color: "green", textAlign: "center" }}>
                                  <i className="fas fa-circle"></i>
                                </td>
                              ) : (
                                <td style={{ color: "red", textAlign: "center" }}>
                                  <i className="fas fa-circle"></i>
                                </td>
                              )}

                              <td>{oneBreak.userName}</td>
                              <td>{oneBreak.breakType}</td>
                              {
                                oneBreak.clockOutTime === "" || oneBreak.clockOutTime === null ?
                                  <td></td> :
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 650, hide: 200 }}
                                    style={{ display: "none" }}
                                    overlay={<Tooltip id="button-tooltip">
                                      {oneBreak.clockOutTime.split(" ")[0]}
                                    </Tooltip>}
                                  >
                                    <td>{convertTime(oneBreak.clockOutTime)}</td>
                                  </OverlayTrigger>
                              }
                              <td style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "400px" }}>{oneBreak.customMessage}</td>
                              <td>
                                <LinkContainer to={`/app/onbreak/${oneBreak.user}/edit`}>
                                  <Button variant="dark" className="px-2 py-0 m-0">
                                    <i
                                      style={{ fontSize: "12px" }}
                                      className="fas fa-edit"
                                    ></i>
                                  </Button>
                                </LinkContainer>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={3}>
            {loadingInfoDetails ? (
              <Loader />
            ) : loadingInfoError ? (
              <Message variant="danger">{loadingInfoError}</Message>
            ) : !breakInfo.status ? (
              <div>
                <h2>Current Status</h2>
                <p>
                  Away type: <br />
                  {breakInfo.breakType}
                </p>
                {breakInfo.customMessage && (
                  <p style={{ overflowWrap: "break-word" }}>
                    Message: <br />
                    {breakInfo.customMessage}
                  </p>
                )}
                <p>
                  Clock out time: <br />
                  {convertTime(breakInfo.clockOutTime)}
                </p>
                <Button
                  size="md"
                  onClick={backInSubmitHandler}
                  variant="primary"
                  className="mt-2"
                >
                  I'm Back
                </Button>
              </div>
            ) : (
              <div className="pb-4">
                <h2>Change Status</h2>
                <Form onSubmit={tempOutSubmitHandler}>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      defaultChecked
                      inline
                      label="Lunch"
                      name="breakType"
                      type={"radio"}
                      id={`Lunch`}
                      onChange={function (e) {
                        setBreakType("Lunch");
                        setOtherDropDownTitle("Select Away Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Break"
                      name="breakType"
                      type={"radio"}
                      id={`Break`}
                      onChange={function (e) {
                        setBreakType("Break");
                        setOtherDropDownTitle("Select Away Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Other"
                      name="breakType"
                      type={"radio"}
                      id={`Other`}
                      onChange={(e) => setBreakType("Other")}
                    />
                  </div>
                  <Form.Group className="mt-2" controlId="customMessage">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Custom Message"
                      className="mb-3"
                    >
                      {" "}
                      <Form.Control
                        type="text"
                        placeholder="Custom Message"
                        maxLength="200"
                        onChange={(e) => setCustomMessage(e.target.value)}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  {breakType !== "Lunch" && breakType !== "Break" && <div>
                    <div className="mb-3">
                      <Dropdown>
                        {breakType === "Other" ?
                          <Dropdown.Toggle style={{ width: "100%", textAlign: "left", backgroundColor: "white", color: "#869096" }}>{otherDropDownTitle}</Dropdown.Toggle>
                          : <Dropdown.Toggle id="otherDropDown" style={{ width: "100%", textAlign: "left", backgroundColor: "white", color: "black" }}>{otherDropDownTitle}</Dropdown.Toggle>
                        }
                        <Dropdown.Menu>
                          {breakTypes &&
                            breakTypes
                              .sort((a, b) => a.breakType.localeCompare(b.breakType))
                              .filter(showInOtherTypes)
                              .map((breakType) => (
                                <Dropdown.Item
                                  id="YE"
                                  key={breakType._id}
                                  onClick={function (e) {
                                    setOtherDropDownTitle(breakType.breakType);
                                    setBreakType(breakType.breakType)
                                  }}
                                >
                                  {breakType.breakType}
                                </Dropdown.Item>
                              ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>}
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Form>
              </div>
            )}
          </Col>
          <Col md={9}>
            <div>
              <Row>
                <Col xs={6}>
                  <h2>Employee List</h2>
                </Col>
                <Col xs={6} className="d-flex justify-content-end align-self-end pb-3">
                  <div className="d-flex justify-content-end">
                    <Dropdown>
                      <Dropdown.Toggle>{dropDownTitle}</Dropdown.Toggle>
                      <Dropdown.Menu>
                        {empTypes &&
                          empTypes
                            .sort((a, b) => a.empType.localeCompare(b.empType))
                            .map((empType) => (
                              <Dropdown.Item
                                key={empType._id}
                                onClick={(e) => setDropDownTitle(empType.empType)}
                              >
                                {empType.empType}
                              </Dropdown.Item>
                            ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
              {loading ? (
                <Loader />
              ) : (
                <Table striped bordered hover responsive className="table-sm">
                  <thead>
                    <tr>
                      <th
                        role="button"
                        onClick={() => filterHandler("status")}
                        width="12%"
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </th>
                      <th
                        role="button"
                        onClick={() => filterHandler("userName")}
                        width="20%"
                      >
                        Employee
                      </th>
                      <th
                        role="button"
                        onClick={() => filterHandler("breakType")}
                        width="16%"
                      >
                        Away Type
                      </th>
                      <th
                        role="button"
                        onClick={() => filterHandler("clockOutTime")}
                        width="16%"
                      >
                        Time Left
                      </th>
                      <th width="44%">Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {breakInfos &&
                      breakInfos
                        .sort(function (a, b) {
                          if (myFilter === "_id") {
                            if (filterDirection === true) {
                              return a[myFilter] - b[myFilter] || a.id - b.id;
                            } else {
                              return b[myFilter] - a[myFilter] || a.id - b.id;
                            }
                          } else {
                            if (filterDirection === true) {
                              return ("" + a[myFilter])
                                .toString()
                                .localeCompare(b[myFilter]);
                            } else {
                              return ("" + b[myFilter])
                                .toString()
                                .localeCompare(a[myFilter]);
                            }
                          }
                        })
                        .filter(function (a) {
                          if (dropDownTitle === "Everybody") {
                            return true;
                          } else {
                            return a.empType === dropDownTitle;
                          }
                        })
                        .map((oneBreak) => (
                          <tr key={oneBreak.user}>
                            {oneBreak.status ? (
                              <td style={{ color: "green", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            ) : (
                              <td style={{ color: "red", textAlign: "center" }}>
                                <i className="fas fa-circle"></i>
                              </td>
                            )}

                            <td>{oneBreak.userName}</td>
                            <td>{oneBreak.breakType}</td>
                            {
                              oneBreak.clockOutTime === "" || oneBreak.clockOutTime === null ?
                                <td></td> :
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 650, hide: 200 }}
                                  style={{ display: "none" }}
                                  overlay={<Tooltip id="button-tooltip">
                                    {oneBreak.clockOutTime.split(" ")[0]}
                                  </Tooltip>}
                                >
                                  <td>{convertTime(oneBreak.clockOutTime)}</td>
                                </OverlayTrigger>
                            }
                            <td style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "400px" }}>{oneBreak.customMessage}</td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ToDoScreen;

export const VERSION_LIST_REQUEST = 'VERSION_LIST_REQUEST'
export const VERSION_LIST_SUCCESS = 'VERSION_LIST_SUCCESS'
export const VERSION_LIST_FAIL = 'VERSION_LIST_FAIL'
export const VERSION_LIST_RESET = 'VERSION_LIST_RESET'
export const VERSION_GET_REQUEST = 'VERSION_GET_REQUEST'
export const VERSION_GET_SUCCESS = 'VERSION_GET_SUCCESS'
export const VERSION_GET_FAIL = 'VERSION_GET_FAIL'
export const VERSION_GET_RESET = 'VERSION_GET_RESET'
export const VERSION_PUT_REQUEST = 'VERSION_PUT_REQUEST'
export const VERSION_PUT_SUCCESS = 'VERSION_PUT_SUCCESS'
export const VERSION_PUT_FAIL = 'VERSION_PUT_FAIL'
export const VERSION_PUT_RESET = 'VERSION_PUT_RESET'
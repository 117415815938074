import React from "react";
import { Link } from "react-router-dom";

function Application({ application }) {
  return (
    <div className="d-flex flex-column justify-content-center text-center">
      {/* This is a check to see if the hard id is 7, the only application with this should be the wiki for now,
      if it is a wiki we use an href so the page is refreshed when the link is clicked instead of the normal one-page-application
      react flow so that it can switch from the react portion to the django templates portion(wiki) */}
      { application.hardId !== 7 && application.hardId !== 6 ?
      <Link to={`/app/${application.urlslug}`}>
        <img
          className="raise"
          style={{ width: "75px" }}
          src={application.image}
          alt={application.name}
        />
      </Link> :
      <a href={`/app/${application.urlslug}`}>
       <img
          className="raise"
         style={{ width: "75px" }}
          src={application.image}
          alt={application.name}
        />
      </a>
      }
      <p style={{ marginTop: "5px" }}>{application.name}</p>
    </div>
  );
}

export default Application;

import { ETL_ACTIONS_FAIL, ETL_ACTIONS_FAIL_FAIL, ETL_ACTIONS_FAIL_REQUEST, ETL_ACTIONS_FAIL_RESET, ETL_ACTIONS_FAIL_SUCCESS, ETL_ACTIONS_REQUEST, ETL_ACTIONS_RESET, ETL_ACTIONS_SUCCESS, ETL_ACTIONS_SUCCESS_FAIL, ETL_ACTIONS_SUCCESS_REQUEST, ETL_ACTIONS_SUCCESS_RESET, ETL_ACTIONS_SUCCESS_SUCCESS, ETL_ACTIONS_UPDATE_FAIL, ETL_ACTIONS_UPDATE_REQUEST, ETL_ACTIONS_UPDATE_RESET, ETL_ACTIONS_UPDATE_SUCCESS, ETL_DATA_SOURCES_FAIL, ETL_DATA_SOURCES_REQUEST, ETL_DATA_SOURCES_RESET, ETL_DATA_SOURCES_SUCCESS, ETL_STEPS_FAIL, ETL_STEPS_REQUEST, ETL_STEPS_RESET, ETL_STEPS_SUCCESS, ETL_TABLES_FAIL, ETL_TABLES_REQUEST, ETL_TABLES_RESET, ETL_TABLES_SUCCESS } from "../constants/etlConstants";

export const etlDataSourcesListReducer = (
    state = { etlDataSources: [] },
    action
  ) => {
    switch (action.type) {
      case ETL_DATA_SOURCES_REQUEST:
        return { loading: true };
      case ETL_DATA_SOURCES_SUCCESS:
        return { loading: false, etlDataSources: action.payload };
      case ETL_DATA_SOURCES_FAIL:
        return { loading: false, error: action.payload };
      case ETL_DATA_SOURCES_RESET:
        return { etlDataSources: [] };
  
      default:
        return state;
    }
  };
  
export const etlTablesListReducer = (
    state = { etlTables: [] },
    action
  ) => {
    switch (action.type) {
      case ETL_TABLES_REQUEST:
        return { loading: true };
      case ETL_TABLES_SUCCESS:
        return { loading: false, etlTables: action.payload };
      case ETL_TABLES_FAIL:
        return { loading: false, error: action.payload };
      case ETL_TABLES_RESET:
        return { etlTables: [] };
  
      default:
        return state;
    }
  };
  
export const etlStepsListReducer = (
    state = { etlSteps: [] },
    action
  ) => {
    switch (action.type) {
      case ETL_STEPS_REQUEST:
        return { loading: true };
      case ETL_STEPS_SUCCESS:
        return { loading: false, etlSteps: action.payload };
      case ETL_STEPS_FAIL:
        return { loading: false, error: action.payload };
      case ETL_STEPS_RESET:
        return { etlSteps: [] };
  
      default:
        return state;
    }
  };
  
export const etlActionsListReducer = (
    state = { etlActions: [] },
    action
  ) => {
    switch (action.type) {
      case ETL_ACTIONS_REQUEST:
        return { loading: true };
      case ETL_ACTIONS_SUCCESS:
        return { loading: false, etlActions: action.payload };
      case ETL_ACTIONS_FAIL:
        return { loading: false, error: action.payload };
      case ETL_ACTIONS_RESET:
        return { etlActions: [] };
  
      default:
        return state;
    }
  };
 
export const etlActionsSuccessListReducer = (
  state = { etlActionsSuccess: [] },
  action
) => {
  switch (action.type) {
    case ETL_ACTIONS_SUCCESS_REQUEST:
      return { loading: true };
    case ETL_ACTIONS_SUCCESS_SUCCESS:
      return { loading: false, etlActionsSuccess: action.payload };
    case ETL_ACTIONS_SUCCESS_FAIL:
      return { loading: false, error: action.payload };
    case ETL_ACTIONS_SUCCESS_RESET:
      return { etlActionsSuccess: [] };

    default:
      return state;
  }
};

 
export const etlActionsFailListReducer = (
  state = { etlActionsFail: [] },
  action
) => {
  switch (action.type) {
    case ETL_ACTIONS_FAIL_REQUEST:
      return { loading: true };
    case ETL_ACTIONS_FAIL_SUCCESS:
      return { loading: false, etlActionsFail: action.payload };
    case ETL_ACTIONS_FAIL_FAIL:
      return { loading: false, error: action.payload };
    case ETL_ACTIONS_FAIL_RESET:
      return { etlActionsFail: [] };

    default:
      return state;
  }
};

export const etlActionUpdateReducer = (state = { etlAction: {} }, action) => {
  switch (action.type) {
    case ETL_ACTIONS_UPDATE_REQUEST:
      return { loading: true };
    case ETL_ACTIONS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case ETL_ACTIONS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ETL_ACTIONS_UPDATE_RESET:
      return { etlAction: {} };

    default:
      return state;
  }
};
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Application from "../components/Application";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import { listApplications } from "../actions/applicationActions";
import { useSearchParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "../components/SearchBox";

function HomeScreen() {
  const dispatch = useDispatch();
  const applicationList = useSelector((state) => state.applicationList);
  const { error, loading, applications, page, pages } = applicationList;

  const [searchParams] = useSearchParams();
  let keyword = searchParams.get("keyword");
  let mypage = searchParams.get("page");

  useEffect(() => {
    dispatch(listApplications(keyword, mypage));
  }, [dispatch, keyword, mypage]);

  return (
    <div>
      <Row className="d-flex justify-content-center align-items-center mt-2">
        <Col md={8}>
          {keyword && (
            <LinkContainer to="/" style={{ cursor: "pointer" }}>
              <h2 className="text-start pointer">Our Apps</h2>
            </LinkContainer>
          )}
          {!keyword && <h2 className="text-start pointer">Our Apps</h2>}
        </Col>
        <Col md={4}>
          <SearchBox />
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Row className="mt-5">
            {applications
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((application) => (
                <Col key={application._id} xs={4} sm={4} md={3} lg={2} xl={2}>
                  <Application application={application} />
                </Col>
              ))}
            {applications.length === 0 && keyword === null && (
              <Message variant="danger">
                <h4 className="text-center">
                  You don't have access to any apps yet.
                </h4>
              </Message>
            )}
          </Row>
          <Paginate page={page} pages={pages} keyword={keyword} />
        </div>
      )}
    </div>
  );
}

export default HomeScreen;

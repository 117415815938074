import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form, FloatingLabel, Dropdown } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getUserDetails } from "../actions/userActions";
import {
  updateOtherBreakInfoDetails,
  getOthersBreakInfoDetails,
  listUsersBreakSocket,
  updateUserBreakSocket,
  listBreakTypes,
} from "../actions/onBreakActions";
import { listEmployeeTypes } from "../actions/checklistActions";
import ReconnectingWebSocket from 'reconnecting-websocket';


var loc = window.location;
var host = loc.host;
var wsStart = "ws://";
if (loc.protocol === "https:") {
  wsStart = "wss://";
}
if (loc.host === "localhost:3000") {
  host = "localhost:8000";
}
else {
  host = host + ":8001"
}


function OnBreakEditScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const [breakType, setBreakType] = useState("Lunch");
  const [customMessage, setCustomMessage] = useState("");
  const [otherDropDownTitle, setOtherDropDownTitle] = useState("Select Break Type ");
  const endpoint = wsStart + host;
  const [socket, setSocket] = useState();  

  const breakInfoDetails = useSelector((state) => state.breakInfoDetails);
  const {
    loading: loadingInfoDetails,
    error: loadingInfoError,
    breakInfo,
  } = breakInfoDetails;

  const breakInfoUpdate = useSelector((state) => state.breakInfoUpdate);
  const { success: successUpdate } = breakInfoUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  
  const allBreakTypes = useSelector((state) => state.allBreakTypes);
  const { breakTypes } = allBreakTypes;

  const convertTime = (e) => {
    if (e === "" || e === null) {
      return "";
    }
    e = new Date(e);
    return e.toLocaleString([], { hour: "2-digit", minute: "2-digit" });
  };

  useEffect(() => {
    dispatch(listEmployeeTypes());
    dispatch(listBreakTypes());
    const socket = new ReconnectingWebSocket(endpoint);
    setSocket(socket)
    socket.onmessage = function (e) {
      if(e && e.data){
        var socketData = JSON.parse(e.data);
        if(Array.isArray(socketData)) {
          dispatch(listUsersBreakSocket(socketData))
        } else {
          dispatch(updateUserBreakSocket({
            "breakType": socketData.breakType,
            "clockOutTime": socketData.timeLeft,
            "customMessage": socketData.customMessage,
            "empType": socketData.empType,
            "status": socketData.status,
            "user": socketData.userId,
            "userName": socketData.userName
          }))
        }
      }
    };
    return () => {
      socket.close();
    }; //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userInfo.id !== userId) {
      dispatch(getOthersBreakInfoDetails(userId));
      dispatch(getUserDetails(userId));
    }
  }, [dispatch, successUpdate, userInfo.id, userId]);

  const tempOutSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateOtherBreakInfoDetails(
        {
          status: false,
          breakType: breakType,
          customMessage: customMessage,
        },
        userId
      )
    );
    var sendMe = {
      userId: breakInfo.user,
      status: false,
      breakType: breakType,
      customMessage: customMessage,
      timeLeft: new Date().toLocaleString(),
      empType: breakInfo.empType,
      userName: breakInfo.userName
    };
    socket.send(JSON.stringify(sendMe));
    navigate("/app/onbreak");
  };

  const backInSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(updateOtherBreakInfoDetails({ status: true }, userId));
    var sendMe = {
      userId: breakInfo.user,
      status: true,
      customMessage: null,
      breakType: "",
      timeLeft: null,
      empType: breakInfo.empType,
      userName: breakInfo.userName
    };
    
    socket.send(JSON.stringify(sendMe));
    navigate("/app/onbreak");
  };

  function showInOtherTypes(value) {
    if(value.breakType === "Break" || value.breakType === "Lunch") {
      return false
    } else {
      if(value.breakType === breakType){
        return false
      }
      return value
    }
  }

  return (
    <div>
      <h5 className="my-4">
        <Link className="productTitle text-white" to="/app/onbreak">
          On Break
        </Link>{" "}
        / Edit
      </h5>
      <Row>
        <Col md={5}>
          {loadingInfoDetails ? (
            <Loader />
          ) : loadingInfoError ? (
            <Message variant="danger">{loadingInfoError}</Message>
          ) : !breakInfo.status ? (
            <div>
              <h2>{user.name}'s Status</h2>
              <p>
                Break type: <br />
                {breakInfo.breakType}
              </p>
              {breakInfo.customMessage && (
                <p style={{ overflowWrap: "break-word" }}>
                  Message: <br />
                  {breakInfo.customMessage}
                </p>
              )}
              <p>
                Clock out time: <br />
                {convertTime(breakInfo.clockOutTime)}
              </p>
              <Button
                size="md"
                onClick={backInSubmitHandler}
                variant="primary"
                className="mt-2"
              >
                They're Back
              </Button>
            </div>
          ) : (
            <div>
              <h2>{user.name}'s Status</h2>
              <Form onSubmit={tempOutSubmitHandler}>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      defaultChecked
                      inline
                      label="Lunch"
                      name="breakType"
                      type={"radio"}
                      id={`Lunch`}
                      onChange={function(e) {
                        setBreakType("Lunch");
                        setOtherDropDownTitle("Select Break Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Break"
                      name="breakType"
                      type={"radio"}
                      id={`Break`}
                      onChange={function(e) {
                        setBreakType("Break");
                        setOtherDropDownTitle("Select Break Type ")
                      }}
                    />
                    <Form.Check
                      inline
                      label="Other"
                      name="breakType"
                      type={"radio"}
                      id={`Other`}
                      onChange={(e) => setBreakType("Other")}
                    />
                  </div>
                  <Form.Group className="mt-2" controlId="customMessage">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Custom Message"
                      className="mb-3"
                    >
                      {" "}
                      <Form.Control
                        type="text"
                        placeholder="Custom Message"
                        maxLength="200"
                        onChange={(e) => setCustomMessage(e.target.value)}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  {breakType !== "Lunch" && breakType !== "Break" && breakType !== "" && <div>
                  <div className="mb-3">
                    <Dropdown>
                    { breakType === "Other" ?
                    <Dropdown.Toggle style={{width: "100%", textAlign: "left", backgroundColor: "white", color: "#869096"}}>{otherDropDownTitle}</Dropdown.Toggle>
                    : <Dropdown.Toggle id="otherDropDown" style={{width: "100%", textAlign: "left", backgroundColor: "white", color: "black"}}>{otherDropDownTitle}</Dropdown.Toggle>
                    }
                      <Dropdown.Menu>
                        {breakTypes &&
                          breakTypes
                            .sort((a, b) => a.breakType.localeCompare(b.breakType))
                            .filter(showInOtherTypes)
                            .map((breakType) => (
                              <Dropdown.Item
                                id="YE"
                                key={breakType._id}
                                onClick={function(e) {
                                  setOtherDropDownTitle(breakType.breakType);
                                  setBreakType(breakType.breakType)
                                }}
                              >
                                {breakType.breakType}
                              </Dropdown.Item>
                            ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                  </div>}
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Form>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default OnBreakEditScreen;

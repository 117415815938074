import React, { useEffect, useState } from 'react'
import { Button, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getEtlDataSources } from '../actions/etlActions';
import EtlTables from './EtlTables';
import Loader from './Loader';
import Message from './Message';

function EtlDataSources() {
  const dispatch = useDispatch();

  const etlDataSourcesList = useSelector((state) => state.etlDataSourcesList);
  const { etlDataSources, loading, error } = etlDataSourcesList;

  const [selectedDataSource, setSelectedDataSource] = useState();

  useEffect(() => {
    dispatch(getEtlDataSources())
  }, [dispatch])

  return (
    <div>
      <Row>
        {
          selectedDataSource ? (
            <EtlTables 
              data = {selectedDataSource}
            />
            ) : (
            loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <div>
                <h3>All Data Sources:</h3>
                <Table striped bordered hover responsive className="table-sm">
                  <thead>
                    <tr>
                      <th>
                        Data Source
                      </th>
                      <th>
                        Total Tables
                      </th>
                      <th>
                        Total Steps
                      </th>
                      <th width="115px" className='text-center'>
                        View More
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {etlDataSources &&
                      etlDataSources
                        .map((etlDataSource) => (
                          <tr key={etlDataSource._id}>
                            <td>{etlDataSource.name}</td>
                            <td>{etlDataSource.totalTables.length}</td>
                            <td>{etlDataSource.totalSteps.length}</td>
                            <td className='text-center'>
                              <Button onClick={() => setSelectedDataSource(etlDataSource)} variant="dark" className="px-2 py-0 m-0">
                                <i
                                  style={{ fontSize: "12px" }}
                                  className="fas fa-eye"
                                ></i>
                              </Button>
                            </td>
                        </tr>
                        ))}
                  </tbody>
                </Table>
              </div>
            )
          )
        }
      </Row>
    </div>
  )
}

export default EtlDataSources
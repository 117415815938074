import axios from "axios";
import {
  BREAK_INFO_GET_REQUEST,
  BREAK_INFO_GET_SUCCESS,
  BREAK_INFO_GET_FAIL,
  BREAK_INFO_UPDATE_REQUEST,
  BREAK_INFO_UPDATE_SUCCESS,
  BREAK_INFO_UPDATE_FAIL,
  BREAK_INFO_GET_ALL_SUCCESS,
  BREAK_INFO_GET_ALL_FAIL,
  BREAK_INFO_CREATE_REQUEST,
  BREAK_INFO_CREATE_SUCCESS,
  BREAK_INFO_CREATE_FAIL,
  BREAK_INFO_DELETE_REQUEST,
  BREAK_INFO_DELETE_SUCCESS,
  BREAK_INFO_DELETE_FAIL,
  BREAK_INFO_UPDATE_OTHER_REQUEST,
  BREAK_INFO_UPDATE_OTHER_SUCCESS,
  BREAK_INFO_UPDATE_OTHER_FAIL,
  BREAK_SOCKET_ALL_SUCCESS,
  BREAK_SOCKET_ALL_FAIL,
  BREAK_SOCKET_ALL_REQUEST,
  BREAK_SOCKET_ALL_UPDATE,
  BREAK_TYPE_GET_REQUEST,
  BREAK_TYPE_GET_SUCCESS,
  BREAK_TYPE_GET_FAIL,
} from "../constants/onBreakConstants";

export const getBreakInfoDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BREAK_INFO_GET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/onbreak/get/`, config);

    dispatch({
      type: BREAK_INFO_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BREAK_INFO_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getOthersBreakInfoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BREAK_INFO_GET_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/onbreak/get/${id}/`, config);

    dispatch({
      type: BREAK_INFO_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BREAK_INFO_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateBreakInfoDetails =
  (breakInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BREAK_INFO_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/onbreak/update/`,
        breakInfo,
        config
      );
      dispatch({
        type: BREAK_INFO_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BREAK_INFO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateOtherBreakInfoDetails =
  (breakInfo, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BREAK_INFO_UPDATE_OTHER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/onbreak/update/${id}/`,
        breakInfo,
        config
      );

      dispatch({
        type: BREAK_INFO_UPDATE_OTHER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BREAK_INFO_UPDATE_OTHER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listUsersBreakStatus =
  (userInfos) => async (dispatch, getState) => {
    let oldData = JSON.stringify(userInfos);
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/onbreak/get/all/`, config);
      let newData = JSON.stringify(data);

      if (newData !== oldData) {
        dispatch({
          type: BREAK_INFO_GET_ALL_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: BREAK_INFO_GET_ALL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  
  export const listUsersBreakSocket =
  (socketData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BREAK_SOCKET_ALL_REQUEST,
      });

      dispatch({
        type: BREAK_SOCKET_ALL_SUCCESS,
        payload: socketData,
      });
    } catch (error) {
      dispatch({
        type: BREAK_SOCKET_ALL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  export const updateUserBreakSocket =
  (socketData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BREAK_SOCKET_ALL_UPDATE,
        payload: socketData,
      });
    } catch (error) {
      dispatch({
        type: BREAK_SOCKET_ALL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteBreakInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BREAK_INFO_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(`/api/onbreak/delete/${id}/`, config);

    dispatch({
      type: BREAK_INFO_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BREAK_INFO_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBreakInfo = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BREAK_INFO_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/onbreak/create/${id}/`, {}, config);

    dispatch({
      type: BREAK_INFO_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BREAK_INFO_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBreakData = (breakInfo) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/onbreak/create/breakdata/`, breakInfo, config);
  } catch (error) {
    dispatch({
      // Using break info create fail instead of making a whole reducer and all the constants
      type: BREAK_INFO_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBreakTypes = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BREAK_TYPE_GET_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/onbreak/breaktype/`, config);

    dispatch({
      type: BREAK_TYPE_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BREAK_TYPE_GET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

